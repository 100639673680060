<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="kanbanlist" v-if="yemian">
        <div class="kanbanlist_box">
            <i class="el-icon-error" @click="guanbi"></i>
            <div class="kanban_listbox_tit">
                <div class="kanban_listbox_tit_left">
                    <span @click='xianshi_shezhi=true'>显示设置</span>
                    <span @click="dianji_daochu">导出</span>
                    <span v-if="biao_name!='访客记录'" @click="biaoyi_num=index" :style="biaoyi_num==index?'color:#fff;background: #9a86db;border:0.01rem solid #9a86db;':''" v-for="(i,index) in huizong_or_mingxi" :key='index'>{{i}}</span>
                    <span style="color:#fff;background: #9a86db;border:0.01rem solid #9a86db;" v-if="biao_name=='访客记录'">明细</span>
                    <p style="margin-right:0.2rem;">单位：元</p>
                </div>
                <p class="kanban_listbox_tit_right">
                    <i class="el-icon-search"></i>
                    <input type="text" v-model="sousuo_text" @keyup.enter="sousuo_1" placeholder="请输入">
                    <i @click="sousuo_text='',sousuo_1()" class="el-icon-circle-close"></i>
                </p>
            </div>
                
            <div class="kanban_listbox_table">
                <p v-if="biao_name=='访客记录'" style="text-align:center;font-size:0.16rem;">访客计划单</p>
                <p v-if="biao_name=='漏斗阶段'" style="text-align:center;font-size:0.16rem;">漏斗单</p>
                <p v-if="biao_name=='合同'" style="text-align:center;font-size:0.16rem;">合同单</p>
                <p v-if="biao_name=='发票'" style="text-align:center;font-size:0.16rem;">发票单</p>
                <p v-if="biao_name=='回款'" style="text-align:center;font-size:0.16rem;">回款单</p>
                <p v-if="biao_name=='交付单'" style="text-align:center;font-size:0.16rem;">交付单</p>
                <p v-if="biao_name=='工单'" style="text-align:center;font-size:0.16rem;">派工单</p>
                <div style="padding:0 0.3rem;">
                    <p v-if="biaoyi_num==0&&biao_name=='漏斗阶段'">
                        漏斗金额合计：{{loudou_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        漏斗折算金额合计：{{loudou_zhesuan_jine_heji}}
                    </p>
                    <p v-if="biaoyi_num==1&&biao_name=='漏斗阶段'">
                        数量合计：{{shuliang_heji}}&nbsp;&nbsp;&nbsp;&nbsp;原价合计：{{yuanjia_heji}}&nbsp;&nbsp;&nbsp;&nbsp;售价合计：{{shoujia_heji}}
                    </p>
                    <!-- biaoyi_num==0&& -->
                    <p v-if="biao_name=='合同'">
                        累计合同金额合计：{{hetong_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计发票金额合计：{{fapiao_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        合同未开发票金额合计：{{weikai_fapiao_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计回款金额合计：{{leiji_huikuan_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计应收金额合计：{{leiji_yingshou_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计交付金额合计：{{leiji_jiaofu_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计未交付金额合计：{{wei_jiaofu_jine_heji}}
                    </p>
                    <!-- <p v-if="biaoyi_num==1&&biao_name=='合同'">
                        合同金额合计：{{hetong_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        数量合计：{{shuliang_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        原价合计：{{yuanjia_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        售价合计：{{shoujia_heji}}
                    </p> -->
                    <p v-if="biao_name=='发票'">
                        <!-- 不含税金额合计：{{buhan_shui_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        税额合计：{{shuie_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        发票金额合计：{{fapiao_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp; -->
                        累计合同金额合计：{{leiji_hetong_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计发票金额合计：{{leiji_fapiao_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        合同未开发票金额合计：{{hetong_weikai_fapiao_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计回款金额合计：{{leiji_huikuan_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计应收金额合计：{{leiji_yingshou_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计交付金额合计：{{leiji_jiaofu_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计未交付金额合计：{{wei_jiaofu_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <!-- <p v-if="biaoyi_num==1&&biao_name=='发票'">
                        数量合计：{{shuliang_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        原价合计：{{yuanjia_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        售价合计：{{shoujia_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        不含税金额合计：{{buhan_shui_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        税额合计：{{shuie_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        发票金额合计：{{fapiao_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                    </p> -->
                    <p v-if="biao_name=='回款'">
                        <!-- 回款金额合计：{{huikuan_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        应收金额合计：{{yingshou_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp; -->
                        累计合同金额合计：{{leiji_hetong_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计发票金额合计：{{leiji_fapiao_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        合同未开发票金额合计：{{hetong_weikai_fapiao_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计回款金额合计：{{leiji_huikuan_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计应收金额合计：{{leiji_yingshou_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计交付金额合计：{{leiji_jiaofu_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计未交付金额合计：{{wei_jiaofu_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                    <!-- <p v-if="biaoyi_num==1&&biao_name=='回款'">
                        回款金额合计：{{huikuan_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计发票金额合计：{{leiji_fapiao_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        应收金额合计：{{yingshou_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        累计回款金额合计：{{leiji_huikuan_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        数量合计：{{shuliang_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        原价合计：{{yuanjia_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        售价合计：{{shoujia_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                    </p> -->
                    <p v-if="biaoyi_num==0&&biao_name=='交付单'">
                        交付金额合计：{{jiaofu_jine_heji}}
                    </p>
                    <p v-if="biaoyi_num==1&&biao_name=='交付单'">
                        交付金额合计：{{jiaofu_jine_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        数量合计：{{shuliang_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                        <!-- 原价合计：{{yuanjia_heji}}&nbsp;&nbsp;&nbsp;&nbsp; -->
                        售价合计：{{shoujia_heji}}&nbsp;&nbsp;&nbsp;&nbsp;
                    </p>
                </div>
                <!--  :span-method="biaoyi_num==1?hebing:wuyong()" -->
                <el-table
                    :data="dangqian_xiangqing"
                    :cell-style="liebiao"
                    :max-height="biao_name=='访客记录'?'320':'640'"
                    :header-cell-style="biaotou"
                    tooltip-effect="dark"
                    style="width: 100%">
                    <el-table-column v-for="(i,index) in xianshi_tit" :key="index"
                        :prop="i.con"
                        :label="i.name"
                        :width="i.name=='合同日期'||i.name=='发票日期'||i.name=='回款日期'?'110':''"
                        >
                        <template slot-scope="props">{{props.row[i.con]}}
                            <span v-if="props.row[i.con]==undefined||props.row[i.con].length==0">/</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="kanban_listbox_tit" v-if="biao_name=='访客记录'">
                <div class="kanban_listbox_tit_left">
                    <span @click='xianshi_shezhi2=true'>显示设置</span>
                    <span @click="dianji_daochu2">导出</span>
                    <span @click="biaoer_num=index" :style="biaoer_num==index?'color:#fff;background: #9a86db;border:0.01rem solid #9a86db;':''" v-for="(i,index) in huizong_or_mingxi" :key='index'>{{i}}</span>
                </div>
                <p class="kanban_listbox_tit_right">
                    <i class="el-icon-search"></i>
                    <input type="text" v-model="sousuo_text2" @keyup.enter="sousuo_2" placeholder="请输入">
                    <i @click="sousuo_text2='',sousuo_2()" class="el-icon-circle-close"></i>
                </p>
            </div>
            <div class="kanban_listbox_table" v-if="biao_name=='访客记录'">
                <p v-if="biao_name=='访客记录'" style="text-align:center;font-size:0.16rem;">拜访记录单</p>
                <el-table
                    :data="dangqian_xiangqing2"
                    :cell-style="liebiao"
                    :max-height="biao_name=='访客记录'?'320':'640'"
                    :header-cell-style="biaotou"
                    tooltip-effect="dark"
                    style="width: 100%">
                    <el-table-column v-for="(i,index) in xianshi2_tit" :key="index"
                        :prop="i.con"
                        :label="i.name"
                        >
                        <template slot-scope="props">{{props.row[i.con]}}
                            <span v-if="props.row[i.con]==undefined||props.row[i.con].length==0">/</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="beijing" v-if="xianshi_shezhi" @click="xianshi_shezhi=false">
            <div class="beijing_box" @click.stop>
                <p class="beijing_box_tit">显示设置</p>
                <div class="beijing_box_con">
                    <div>
                        <p class="beijing_box_con_tit">选择：</p>
                        <p class="beijing_box_con_quanbu" @click="dianji_xuanze_quanbu"><img :src="xianshi_quanbu?require('../../assets/okblue.png'):require('../../assets/noccc.png')">全部</p>
                        <ul>
                            <li v-for="(i,index) in xianshi_list" :key="index" @click="xuanze_danxuan(i)">
                                <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')">{{i.name}}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p class="beijing_box_con_tit">显示项：</p>
                        <ul>
                            <li v-for='(i,index) in xianshi_xiang_list' :key="index">
                                {{i.name}}<img class="shanchu_xianshi" @click="dianji_shanchu_xianshi(i,index)" src="../../assets/guanbi_zhu.png">
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="beijing_box_foot">
                    <span @click="dianji_lie_queding">确定</span>
                    <span @click="xianshi_shezhi=false">取消</span>
                </p>
            </div>
        </div>
        <div class="beijing" v-if="xianshi_shezhi2" @click="xianshi_shezhi2=false">
            <div class="beijing_box" @click.stop>
                <p class="beijing_box_tit">显示设置</p>
                <div class="beijing_box_con">
                    <div>
                        <p class="beijing_box_con_tit">选择：</p>
                        <p class="beijing_box_con_quanbu" @click="dianji_xuanze_quanbu2"><img :src="xianshi_quanbu2?require('../../assets/okblue.png'):require('../../assets/noccc.png')">全部</p>
                        <ul>
                            <li v-for="(i,index) in xianshi2_list" :key="index" @click="xuanze_danxuan2(i)">
                                <img class="xuanxiang_kuang" :src="i.zhi?require('../../assets/okblue.png'):require('../../assets/noccc.png')">{{i.name}}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <p class="beijing_box_con_tit">显示项：</p>
                        <ul>
                            <li v-for='(i,index) in xianshi_xiang_list2' :key="index">
                                {{i.name}}<img class="shanchu_xianshi" @click="dianji_shanchu_xianshi2(i,index)" src="../../assets/guanbi_zhu.png">
                            </li>
                        </ul>
                    </div>
                </div>
                <p class="beijing_box_foot">
                    <span @click="dianji_lie_queding2">确定</span>
                    <span @click="xianshi_shezhi2=false">取消</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import tongxun from './tongxun'
import { query_form_list_gather, query_form_list } from '../../api/api.js'
export default {
  name: 'kanbanlist',
  data () {
    return {
      yemian: false,
      biao1_tit: [],
      dangqian_xiangqing: [],
      dangqian_xiangqing2: [],
      xianshi_shezhi: false,
      xianshi_quanbu: false,
      zhanshi_list: [],
      xianshi_xiang_list: [],
      xianshi_shezhi2: false,
      xianshi_quanbu2: false,
      zhanshi_list2: [],
      xianshi_xiang_list2: [],
      dianji_chuxian_youce: false,
      biao_name: '',
      youce_xiangqing: '',
      sousuo_text: '',
      sousuo_text2: '',
      huizong_or_mingxi: ['汇总', '明细'],
      biaoyi_num: '0',
      biaoer_num: '0',
      xianshi_list: [],
      xianshi2_list: [],
      spanArr3: [],
      pos3: 0,
      indexArr3: [],
      dangqian_neirong: [], // 派工单用到了
      no_mingxi: false,
      loudou_jine_heji: '0.00',
      shuliang_heji: '0.00',
      yuanjia_heji: '0.00',
      shoujia_heji: '0.00',
      hetong_jine_heji: '0.00',
      fapiao_jine_heji: '0.00',
      loudou_zhesuan_jine_heji: '0.00',
      weikai_fapiao_jine_heji: '0.00',
      leiji_yingshou_jine_heji: '0.00',
      leiji_jiaofu_jine_heji: '0.00',
      wei_jiaofu_jine_heji: '0.00',
      leiji_hetong_jine_heji: '0.00',
      xianshi_tit: [],
      xianshi2_tit: [],
      buhan_shui_jine_heji: '0.00',
      shuie_heji: '0.00',
      fapiao_jine_heji: '0.00',
      leiji_fapiao_jine_heji: '0.00',
      hetong_weikai_fapiao_jine_heji: '0.00',
      leiji_huikuan_jine_heji: '0.00',
      huikuan_jine_heji: '0.00',
      yingshou_jine_heji: '0.00',
      jiaofu_jine_heji: '0.00',
      look_type: '',
      // url:'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/',//服务器2号机
      // url:'https://www.mlcbr.com:8443/hwb_client/V1.0.0/',
      url: 'https://cbr.mlcbr.com:8443/hwb_client/V1.0.0/'
      //  url:'http://192.168.3.5:8080/hwb_client/V1.0.0/'
    }
  },
  watch: {
    xianshi_xiang_list: {
      handler (newValue, oldValue) {
        if (this.xianshi_xiang_list.length == this.xianshi_list.length && this.xianshi_xiang_list.length != 0) {
          this.xianshi_quanbu = true
        } else {
          this.xianshi_quanbu = false
        }
      },
      deep: true
    },
    xianshi_xiang_list2: {
      handler (newValue, oldValue) {
        if (this.xianshi_xiang_list2.length == this.xianshi2_list.length && this.xianshi_xiang_list2.length != 0) {
          this.xianshi_quanbu2 = true
        } else {
          this.xianshi_quanbu2 = false
        }
      },
      deep: true
    },
    xianshi_shezhi () {
      if (!this.xianshi_quanbu) {
        this.xianshi_list.map(i => {
          this.$set(i, 'zhi', false)
        })
        this.xianshi_xiang_list = []
      }
    },
    xianshi_shezhi2 () {
      if (!this.xianshi_quanbu2) {
        this.xianshi2_list.map(i => {
          this.$set(i, 'zhi', false)
        })
        this.xianshi_xiang_list2 = []
      }
    },
    biaoyi_num () { // 表一里的切换汇总还是明细按钮
      this.jichu_zhi(this.biao_name)
    },
    biaoer_num () { // 表二里的切换汇总还是明细按钮
      // this.qiehuan_hz_mx2()
      this.jichu_zhi(this.biao_name)
    },
    dangqian_xiangqing: {
      handler (newValue, oldValue) {
        this.zidong_jisuan()
      },
      deep: true
    }
  },
  mounted () {
  },
  created () {
  },
  methods: {
    jichu (i) {
      this.biaoyi_num = 0
      this.biaoer_num = 0
      this.sousuo_text = ''
      this.sousuo_text2 = ''
      // tongxun.$on('liebiao_name',(i)=>{
      this.biao_name = i.name
      this.look_type = i.look_type
      this.jichu_zhi(i.name)
      this.yemian = true
      // })
    },
    jichu_zhi (table) {
      this.biao1_tit = []
      this.zhanshi_list = []
      this.biao1_tit2 = []
      this.zhanshi_list2 = []
      this.dangqian_xiangqing = []
      this.dangqian_xiangqing2 = []
      if (table == '漏斗阶段') {
        this.biao1_tit = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '漏斗阶段',
            con: 'jieduan'
          },
          {
            name: '漏斗金额',
            con: 'amt_funn'
          },
          {
            name: '预计合同日期',
            con: 'date_con_est'
          },
          {
            name: '预计漏斗折算比例',
            con: 'bili'
          },
          {
            name: '漏斗折算金额',
            con: 'loudou_zhesuan_jine'
          }
        ]
        this.biao2_tit = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '漏斗阶段',
            con: 'jieduan'
          },
          {
            name: '预计合同日期',
            con: 'date_con_est'
          },
          {
            name: '预计漏斗折算比例',
            con: 'bili'
          },
          {
            name: '漏斗折算金额',
            con: 'loudou_zhesuan_jine'
          },
          {
            name: '产品',
            con: 'prod_name'
          },
          {
            name: '产品编号',
            con: 'prod_code'
          },
          {
            name: '类型',
            con: 'cat_name'
          },
          {
            name: '规格',
            con: 'spec'
          },
          {
            name: '型号',
            con: 'type'
          },
          {
            name: '单位',
            con: 'unit'
          },
          {
            name: '单价',
            con: 'price_unit'
          },
          {
            name: '数量',
            con: 'qty'
          },
          {
            name: '原价',
            con: 'price_orig'
          },
          {
            name: '折扣率',
            con: 'disc_rate'
          },
          {
            name: '售价',
            con: 'price_sale'
          }
        ]
        this.xianshi_tit = this.biao1_tit
        this.zhanshi1_list = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          {
            name: '漏斗阶段',
            con: 'jieduan',
            zhi: false
          },
          {
            name: '漏斗金额',
            con: 'amt_funn',
            zhi: false
          },
          {
            name: '预计合同日期',
            con: 'date_con_est',
            zhi: false
          },
          {
            name: '预计漏斗折算比例',
            con: 'bili',
            zhi: false
          },
          {
            name: '漏斗折算金额',
            con: 'loudou_zhesuan_jine',
            zhi: false
          }
        ]
        this.zhanshi2_list = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          {
            name: '漏斗阶段',
            con: 'jieduan',
            zhi: false
          },
          {
            name: '漏斗金额',
            con: 'amt_funn',
            zhi: false
          },
          {
            name: '预计合同日期',
            con: 'date_con_est',
            zhi: false
          },
          {
            name: '预计漏斗折算比例',
            con: 'bili',
            zhi: false
          },
          {
            name: '漏斗折算金额',
            con: 'loudou_zhesuan_jine',
            zhi: false
          },
          {
            name: '产品',
            con: 'prod_name',
            zhi: false
          },
          {
            name: '产品编号',
            con: 'prod_code',
            zhi: false
          },
          {
            name: '类型',
            con: 'cat_name',
            zhi: false
          },
          {
            name: '规格',
            con: 'spec',
            zhi: false
          },
          {
            name: '型号',
            con: 'type',
            zhi: false
          },
          {
            name: '单位',
            con: 'unit',
            zhi: false
          },
          {
            name: '单价',
            con: 'price_unit',
            zhi: false
          },
          {
            name: '数量',
            con: 'qty',
            zhi: false
          },
          {
            name: '原价',
            con: 'price_orig',
            zhi: false
          },
          {
            name: '折扣率',
            con: 'disc_rate',
            zhi: false
          },
          {
            name: '售价',
            con: 'price_sale',
            zhi: false
          }
        ]
        this.xianshi_list = this.zhanshi1_list
        if (this.biaoyi_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'funn',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              date.forEach(item => {
                this.$set(item, 'jieduan', this.jieduan_hanzi(item.stage_funn))
                this.$set(item, 'loudou_zhesuan_jine', (Number(item.rate_funn_disc) * Number(item.amt_funn)).toFixed(2))
                this.$set(item, 'bili', Number(item.rate_funn_disc) * 100 + '%')
              })
              console.log(date)
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoyi_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'funn',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              date.forEach(item => {
                this.$set(item, 'jieduan', this.jieduan_hanzi(item.stage_funn))
                this.$set(item, 'loudou_zhesuan_jine', (Number(item.rate_funn_disc) * Number(item.amt_funn)).toFixed(2))
                this.$set(item, 'bili', Number(item.rate_funn_disc) * 100 + '%')
              })
              console.log(date)
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
              this.qiehuan_hz_mx()
            } else if (res.data.code == 500) {}
          })
        }
      } else if (table == '合同') {
        if (this.biaoyi_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'contract',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
              const cuowu = []
              date.forEach(item => {
                Object.assign(item, item.cl)
                // if(item.amt_list.length!=1){
                //     cuowu.push('111')
                // }
              })
              this.biao1_tit = [
                {
                  name: '客户',
                  con: 'cust_name'
                },
                {
                  name: '归档号',
                  con: 'file_no'
                },
                {
                  name: '负责人',
                  con: 'staff_name'
                },
                {
                  name: '部门',
                  con: 'dept_name'
                },
                {
                  name: '合同日期',
                  con: 'date_con'
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ'
                },
                {
                  name: '行业',
                  con: 'industry'
                },
                {
                  name: '合同类型',
                  con: 'con_type'
                },
                {
                  name: '合同有效时间',
                  con: 'con_valid_term'
                },
                {
                  name: '合同金额',
                  con: 'amt_con'
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum'
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_con_no_inv'
                },
                {
                  name: '合同未开发票天数',
                  con: 'days_con_no_inv'
                },
                {
                  name: '累计回款金额',
                  con: 'amt_rec_sum'
                },
                {
                  name: '累计应收金额',
                  con: 'amt_sum_should'
                },
                {
                  name: '应收回款天数',
                  con: 'days_rec_should'
                },
                {
                  name: '累计交付金额',
                  con: 'amt_del_sum'
                },
                {
                  name: '累计未交付金额',
                  con: 'amt_del_no'
                },
                {
                  name: '累计交付天数',
                  con: 'days_del'
                }
              ]
              this.zhanshi1_list = [
                {
                  name: '客户',
                  con: 'cust_name',
                  zhi: false
                },
                {
                  name: '归档号',
                  con: 'file_no',
                  zhi: false
                },
                {
                  name: '负责人',
                  con: 'staff_name',
                  zhi: false
                },
                {
                  name: '部门',
                  con: 'dept_name',
                  zhi: false
                },
                {
                  name: '合同日期',
                  con: 'date_con',
                  zhi: false
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ',
                  zhi: false
                },
                {
                  name: '行业',
                  con: 'industry',
                  zhi: false
                },
                {
                  name: '合同类型',
                  con: 'con_type',
                  zhi: false
                },
                {
                  name: '合同有效时间',
                  con: 'con_valid_term',
                  zhi: false
                },
                {
                  name: '合同金额',
                  con: 'amt_con',
                  zhi: false
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum',
                  zhi: false
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_con_no_inv',
                  zhi: false
                },
                {
                  name: '合同未开发票天数',
                  con: 'days_con_no_inv',
                  zhi: false
                },
                {
                  name: '累计回款金额',
                  con: 'amt_rec_sum',
                  zhi: false
                },
                {
                  name: '累计应收金额',
                  con: 'amt_sum_should',
                  zhi: false
                },
                {
                  name: '应收回款天数',
                  con: 'days_rec_should',
                  zhi: false
                },
                {
                  name: '累计交付金额',
                  con: 'amt_del_sum',
                  zhi: false
                },
                {
                  name: '累计未交付金额',
                  con: 'amt_del_no',
                  zhi: false
                },
                {
                  name: '累计交付天数',
                  con: 'days_del',
                  zhi: false
                }
              ]
              if (cuowu.length != 0) {
                this.biao2_tit = [
                  {
                    name: '客户',
                    con: 'cust_name'
                  },
                  {
                    name: '归档号',
                    con: 'file_no'
                  },
                  {
                    name: '负责人',
                    con: 'staff_name'
                  },
                  {
                    name: '部门',
                    con: 'dept_name'
                  },
                  {
                    name: '合同日期',
                    con: 'date_con'
                  },
                  {
                    name: '所属公司',
                    con: 'belong_to_organ'
                  },
                  {
                    name: '行业',
                    con: 'industry'
                  },
                  {
                    name: '合同类型',
                    con: 'con_type'
                  },
                  {
                    name: '合同有效时间',
                    con: 'con_valid_term'
                  },
                  {
                    name: '合同金额',
                    con: 'amt_con'
                  },
                  {
                    name: '产品',
                    con: 'prod_name'
                  },
                  {
                    name: '产品编号',
                    con: 'prod_code'
                  },
                  {
                    name: '类型',
                    con: 'cat_name'
                  },
                  {
                    name: '规格',
                    con: 'spec'
                  },
                  {
                    name: '型号',
                    con: 'type'
                  },
                  {
                    name: '单位',
                    con: 'unit'
                  },
                  {
                    name: '单价',
                    con: 'price_unit'
                  },
                  {
                    name: '数量',
                    con: 'qty'
                  },
                  {
                    name: '原价',
                    con: 'price_orig'
                  },
                  {
                    name: '折扣率',
                    con: 'disc_rate'
                  },
                  {
                    name: '售价',
                    con: 'price_sale'
                  }
                ]
                this.zhanshi2_list = [
                  {
                    name: '客户',
                    con: 'cust_name',
                    zhi: false
                  },
                  {
                    name: '归档号',
                    con: 'file_no',
                    zhi: false
                  },
                  {
                    name: '负责人',
                    con: 'staff_name',
                    zhi: false
                  },
                  {
                    name: '部门',
                    con: 'dept_name',
                    zhi: false
                  },
                  {
                    name: '合同日期',
                    con: 'date_con',
                    zhi: false
                  },
                  {
                    name: '所属公司',
                    con: 'belong_to_organ',
                    zhi: false
                  },
                  {
                    name: '行业',
                    con: 'industry',
                    zhi: false
                  },
                  {
                    name: '合同类型',
                    con: 'con_type',
                    zhi: false
                  },
                  {
                    name: '合同有效时间',
                    con: 'con_valid_term',
                    zhi: false
                  },
                  {
                    name: '合同金额',
                    con: 'amt_con',
                    zhi: false
                  },
                  {
                    name: '产品',
                    con: 'prod_name',
                    zhi: false
                  },
                  {
                    name: '产品编号',
                    con: 'prod_code',
                    zhi: false
                  },
                  {
                    name: '类型',
                    con: 'cat_name',
                    zhi: false
                  },
                  {
                    name: '规格',
                    con: 'spec',
                    zhi: false
                  },
                  {
                    name: '型号',
                    con: 'type',
                    zhi: false
                  },
                  {
                    name: '单位',
                    con: 'unit',
                    zhi: false
                  },
                  {
                    name: '单价',
                    con: 'price_unit',
                    zhi: false
                  },
                  {
                    name: '数量',
                    con: 'qty',
                    zhi: false
                  },
                  {
                    name: '原价',
                    con: 'price_orig',
                    zhi: false
                  },
                  {
                    name: '折扣率',
                    con: 'disc_rate',
                    zhi: false
                  },
                  {
                    name: '售价',
                    con: 'price_sale',
                    zhi: false
                  }
                ]
                // this.no_mingxi=true
              } else {
                this.biao2_tit = [
                  {
                    name: '客户',
                    con: 'cust_name'
                  },
                  {
                    name: '归档号',
                    con: 'file_no'
                  },
                  {
                    name: '负责人',
                    con: 'staff_name'
                  },
                  {
                    name: '部门',
                    con: 'dept_name'
                  },
                  {
                    name: '合同日期',
                    con: 'date_con'
                  },
                  {
                    name: '所属公司',
                    con: 'belong_to_organ'
                  },
                  {
                    name: '行业',
                    con: 'industry'
                  },
                  {
                    name: '合同类型',
                    con: 'con_type'
                  },
                  {
                    name: '合同有效时间',
                    con: 'con_valid_term'
                  },
                  {
                    name: '合同金额',
                    con: 'amt_con'
                  },
                  {
                    name: '产品',
                    con: 'prod_name'
                  },
                  {
                    name: '产品编号',
                    con: 'prod_code'
                  },
                  {
                    name: '类型',
                    con: 'cat_name'
                  },
                  {
                    name: '规格',
                    con: 'spec'
                  },
                  {
                    name: '型号',
                    con: 'type'
                  },
                  {
                    name: '单位',
                    con: 'unit'
                  },
                  {
                    name: '单价',
                    con: 'price_unit'
                  },
                  {
                    name: '数量',
                    con: 'qty'
                  },
                  {
                    name: '原价',
                    con: 'price_orig'
                  },
                  {
                    name: '折扣率',
                    con: 'disc_rate'
                  },
                  {
                    name: '售价',
                    con: 'price_sale'
                  },
                  {
                    name: '预计发票日期',
                    con: 'date_inv_est'
                  },
                  {
                    name: '预计发票金额',
                    con: 'amt_inv_est'
                  }
                ]
                this.zhanshi2_list = [
                  {
                    name: '客户',
                    con: 'cust_name',
                    zhi: false
                  },
                  {
                    name: '归档号',
                    con: 'file_no',
                    zhi: false
                  },
                  {
                    name: '负责人',
                    con: 'staff_name',
                    zhi: false
                  },
                  {
                    name: '部门',
                    con: 'dept_name',
                    zhi: false
                  },
                  {
                    name: '合同日期',
                    con: 'date_con',
                    zhi: false
                  },
                  {
                    name: '所属公司',
                    con: 'belong_to_organ',
                    zhi: false
                  },
                  {
                    name: '行业',
                    con: 'industry',
                    zhi: false
                  },
                  {
                    name: '合同类型',
                    con: 'con_type',
                    zhi: false
                  },
                  {
                    name: '合同有效时间',
                    con: 'con_valid_term',
                    zhi: false
                  },
                  {
                    name: '合同金额',
                    con: 'amt_con',
                    zhi: false
                  },
                  {
                    name: '产品',
                    con: 'prod_name',
                    zhi: false
                  },
                  {
                    name: '产品编号',
                    con: 'prod_code',
                    zhi: false
                  },
                  {
                    name: '类型',
                    con: 'cat_name',
                    zhi: false
                  },
                  {
                    name: '规格',
                    con: 'spec',
                    zhi: false
                  },
                  {
                    name: '型号',
                    con: 'type',
                    zhi: false
                  },
                  {
                    name: '单位',
                    con: 'unit',
                    zhi: false
                  },
                  {
                    name: '单价',
                    con: 'price_unit',
                    zhi: false
                  },
                  {
                    name: '数量',
                    con: 'qty',
                    zhi: false
                  },
                  {
                    name: '原价',
                    con: 'price_orig',
                    zhi: false
                  },
                  {
                    name: '折扣率',
                    con: 'disc_rate',
                    zhi: false
                  },
                  {
                    name: '售价',
                    con: 'price_sale',
                    zhi: false
                  },
                  {
                    name: '预计发票日期',
                    con: 'date_inv_est',
                    zhi: false
                  },
                  {
                    name: '预计发票金额',
                    con: 'amt_inv_est',
                    zhi: false
                  }
                ]
                // this.no_mingxi=false
              }
              this.xianshi_tit = this.biao1_tit
              this.xianshi_list = this.zhanshi1_list
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoyi_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'contract',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              const cuowu = []
              date.forEach(item => {
                Object.assign(item, item.cl)
                // if(item.amt_list.length!=1){
                //     cuowu.push('111')
                // }
              })
              this.biao1_tit = [
                {
                  name: '客户',
                  con: 'cust_name'
                },
                {
                  name: '归档号',
                  con: 'file_no'
                },
                {
                  name: '负责人',
                  con: 'staff_name'
                },
                {
                  name: '部门',
                  con: 'dept_name'
                },
                {
                  name: '合同日期',
                  con: 'date_con'
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ'
                },
                {
                  name: '行业',
                  con: 'industry'
                },
                {
                  name: '合同类型',
                  con: 'con_type'
                },
                {
                  name: '合同有效时间',
                  con: 'con_valid_term'
                },
                {
                  name: '合同金额',
                  con: 'amt_con'
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum'
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_con_no_inv'
                },
                {
                  name: '合同未开发票天数',
                  con: 'days_con_no_inv'
                },
                {
                  name: '累计回款金额',
                  con: 'amt_rec_sum'
                },
                {
                  name: '累计应收金额',
                  con: 'amt_sum_should'
                },
                {
                  name: '应收回款天数',
                  con: 'days_rec_should'
                },
                {
                  name: '累计交付金额',
                  con: 'amt_del_sum'
                },
                {
                  name: '累计未交付金额',
                  con: 'amt_del_no'
                },
                {
                  name: '累计交付天数',
                  con: 'days_del'
                }
              ]
              this.zhanshi1_list = [
                {
                  name: '客户',
                  con: 'cust_name',
                  zhi: false
                },
                {
                  name: '归档号',
                  con: 'file_no',
                  zhi: false
                },
                {
                  name: '负责人',
                  con: 'staff_name',
                  zhi: false
                },
                {
                  name: '部门',
                  con: 'dept_name',
                  zhi: false
                },
                {
                  name: '合同日期',
                  con: 'date_con',
                  zhi: false
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ',
                  zhi: false
                },
                {
                  name: '行业',
                  con: 'industry',
                  zhi: false
                },
                {
                  name: '合同类型',
                  con: 'con_type',
                  zhi: false
                },
                {
                  name: '合同有效时间',
                  con: 'con_valid_term',
                  zhi: false
                },
                {
                  name: '合同金额',
                  con: 'amt_con',
                  zhi: false
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum',
                  zhi: false
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_con_no_inv',
                  zhi: false
                },
                {
                  name: '合同未开发票天数',
                  con: 'days_con_no_inv',
                  zhi: false
                },
                {
                  name: '累计回款金额',
                  con: 'amt_rec_sum',
                  zhi: false
                },
                {
                  name: '累计应收金额',
                  con: 'amt_sum_should',
                  zhi: false
                },
                {
                  name: '应收回款天数',
                  con: 'days_rec_should',
                  zhi: false
                },
                {
                  name: '累计交付金额',
                  con: 'amt_del_sum',
                  zhi: false
                },
                {
                  name: '累计未交付金额',
                  con: 'amt_del_no',
                  zhi: false
                },
                {
                  name: '累计交付天数',
                  con: 'days_del',
                  zhi: false
                }
              ]
              if (cuowu.length != 0) {
              } else {
                this.biao2_tit = [
                  {
                    name: '客户',
                    con: 'cust_name'
                  },
                  {
                    name: '归档号',
                    con: 'file_no'
                  },
                  {
                    name: '负责人',
                    con: 'staff_name'
                  },
                  {
                    name: '部门',
                    con: 'dept_name'
                  },
                  {
                    name: '合同日期',
                    con: 'date_con'
                  },
                  {
                    name: '所属公司',
                    con: 'belong_to_organ'
                  },
                  {
                    name: '行业',
                    con: 'industry'
                  },
                  {
                    name: '合同类型',
                    con: 'con_type'
                  },
                  {
                    name: '合同有效时间',
                    con: 'con_valid_term'
                  },
                  {
                    name: '合同金额',
                    con: 'amt_con'
                  },
                  {
                    name: '产品',
                    con: 'prod_name'
                  },
                  {
                    name: '产品编号',
                    con: 'prod_code'
                  },
                  {
                    name: '类型',
                    con: 'cat_name'
                  },
                  {
                    name: '规格',
                    con: 'spec'
                  },
                  {
                    name: '型号',
                    con: 'type'
                  },
                  {
                    name: '单位',
                    con: 'unit'
                  },
                  {
                    name: '单价',
                    con: 'price_unit'
                  },
                  {
                    name: '数量',
                    con: 'qty'
                  },
                  {
                    name: '原价',
                    con: 'price_orig'
                  },
                  {
                    name: '折扣率',
                    con: 'disc_rate'
                  },
                  {
                    name: '售价',
                    con: 'price_sale'
                  },
                  {
                    name: '预计发票日期',
                    con: 'date_inv_est'
                  },
                  {
                    name: '预计发票金额',
                    con: 'amt_inv_est'
                  }
                ]
                this.zhanshi2_list = [
                  {
                    name: '客户',
                    con: 'cust_name',
                    zhi: false
                  },
                  {
                    name: '归档号',
                    con: 'file_no',
                    zhi: false
                  },
                  {
                    name: '负责人',
                    con: 'staff_name',
                    zhi: false
                  },
                  {
                    name: '部门',
                    con: 'dept_name',
                    zhi: false
                  },
                  {
                    name: '合同日期',
                    con: 'date_con',
                    zhi: false
                  },
                  {
                    name: '所属公司',
                    con: 'belong_to_organ',
                    zhi: false
                  },
                  {
                    name: '行业',
                    con: 'industry',
                    zhi: false
                  },
                  {
                    name: '合同类型',
                    con: 'con_type',
                    zhi: false
                  },
                  {
                    name: '合同有效时间',
                    con: 'con_valid_term',
                    zhi: false
                  },
                  {
                    name: '合同金额',
                    con: 'amt_con',
                    zhi: false
                  },
                  {
                    name: '产品',
                    con: 'prod_name',
                    zhi: false
                  },
                  {
                    name: '产品编号',
                    con: 'prod_code',
                    zhi: false
                  },
                  {
                    name: '类型',
                    con: 'cat_name',
                    zhi: false
                  },
                  {
                    name: '规格',
                    con: 'spec',
                    zhi: false
                  },
                  {
                    name: '型号',
                    con: 'type',
                    zhi: false
                  },
                  {
                    name: '单位',
                    con: 'unit',
                    zhi: false
                  },
                  {
                    name: '单价',
                    con: 'price_unit',
                    zhi: false
                  },
                  {
                    name: '数量',
                    con: 'qty',
                    zhi: false
                  },
                  {
                    name: '原价',
                    con: 'price_orig',
                    zhi: false
                  },
                  {
                    name: '折扣率',
                    con: 'disc_rate',
                    zhi: false
                  },
                  {
                    name: '售价',
                    con: 'price_sale',
                    zhi: false
                  },
                  {
                    name: '预计发票日期',
                    con: 'date_inv_est',
                    zhi: false
                  },
                  {
                    name: '预计发票金额',
                    con: 'amt_inv_est',
                    zhi: false
                  }
                ]
              }
              this.xianshi_tit = this.biao1_tit
              this.xianshi_list = this.zhanshi1_list
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
              this.qiehuan_hz_mx()
            } else if (res.data.code == 500) {}
          })
        }
      } else if (table == '发票') {
        if (this.biaoyi_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'invoice',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              date.forEach(item => {
                Object.assign(item, item.cl)
              })
              this.biao1_tit = [
                {
                  name: '客户',
                  con: 'cust_name'
                },
                {
                  name: '归档号',
                  con: 'file_no'
                },
                {
                  name: '负责人',
                  con: 'staff_name'
                },
                {
                  name: '部门',
                  con: 'dept_name'
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ'
                },
                {
                  name: '合计不含税金额',
                  con: 'amt_no_tax_sum'
                },
                {
                  name: '合计税额',
                  con: 'amt_tax_sum'
                },
                // // amt_inv  小虎哥让改的
                {
                  name: '合计发票金额',
                  con: 'amt_inv_sum'
                },
                {
                  name: '累计合同金额',
                  con: 'amt_con_sum'
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum'
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_con_no_inv'
                },
                {
                  name: '合同未开发票天数',
                  con: 'days_con_no_inv'
                },
                {
                  name: '累计回款金额',
                  con: 'amt_rec_sum'
                },
                {
                  name: '累计应收金额',
                  con: 'amt_sum_should'
                },
                {
                  name: '应收回款天数',
                  con: 'days_rec_should'
                },
                {
                  name: '累计交付金额',
                  con: 'amt_del_sum'
                },
                {
                  name: '累计未交付金额',
                  con: 'amt_del_no'
                },
                {
                  name: '累计交付天数',
                  con: 'days_del'
                }
              ]
              this.zhanshi1_list = [
                {
                  name: '客户',
                  con: 'cust_name',
                  zhi: false
                },
                {
                  name: '归档号',
                  con: 'file_no',
                  zhi: false
                },
                {
                  name: '负责人',
                  con: 'staff_name',
                  zhi: false
                },
                {
                  name: '部门',
                  con: 'dept_name',
                  zhi: false
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ',
                  zhi: false
                },
                {
                  name: '合计不含税金额',
                  con: 'amt_no_tax_sum',
                  zhi: false
                },
                {
                  name: '合计税额',
                  con: 'amt_tax_sum',
                  zhi: false
                },
                {
                  name: '合计发票金额',
                  con: 'amt_inv_sum',
                  zhi: false
                },
                {
                  name: '累计合同金额',
                  con: 'amt_con_sum',
                  zhi: false
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum',
                  zhi: false
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_con_no_inv',
                  zhi: false
                },
                {
                  name: '合同未开发票天数',
                  con: 'days_con_no_inv',
                  zhi: false
                },
                {
                  name: '累计回款金额',
                  con: 'amt_rec_sum',
                  zhi: false
                },
                {
                  name: '累计应收金额',
                  con: 'amt_sum_should',
                  zhi: false
                },
                {
                  name: '应收回款天数',
                  con: 'days_rec_should',
                  zhi: false
                },
                {
                  name: '累计交付金额',
                  con: 'amt_del_sum',
                  zhi: false
                },
                {
                  name: '累计未交付金额',
                  con: 'amt_del_no',
                  zhi: false
                },
                {
                  name: '累计交付天数',
                  con: 'days_del',
                  zhi: false
                }
              ]
              this.biao2_tit = [
                {
                  name: '客户',
                  con: 'cust_name'
                },
                {
                  name: '归档号',
                  con: 'file_no'
                },
                {
                  name: '负责人',
                  con: 'staff_name'
                },
                {
                  name: '部门',
                  con: 'dept_name'
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ'
                },
                {
                  name: '发票日期',
                  con: 'date_inv'
                },
                {
                  name: '发票类型',
                  con: 'inv_type'
                },
                {
                  name: '合计不含税金额',
                  con: 'amt_no_tax_sum'
                },
                {
                  name: '合计税额',
                  con: 'amt_tax_sum'
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum'
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_inv_un'
                },
                {
                  name: '合计发票金额',
                  con: 'amt_inv'
                },
                {
                  name: '产品',
                  con: 'prod_name'
                },
                {
                  name: '产品编号',
                  con: 'prod_code'
                },
                {
                  name: '类型',
                  con: 'cat_name'
                },
                {
                  name: '规格',
                  con: 'spec'
                },
                {
                  name: '型号',
                  con: 'type'
                },
                {
                  name: '单位',
                  con: 'unit'
                },
                {
                  name: '单价',
                  con: 'price_unit'
                },
                {
                  name: '数量',
                  con: 'qty'
                },
                {
                  name: '原价',
                  con: 'price_orig'
                },
                {
                  name: '折扣率',
                  con: 'disc_rate'
                },
                {
                  name: '售价',
                  con: 'price_sale'
                },
                {
                  name: '不含税折后单价',
                  con: 'price_unit_no_tax'
                },
                {
                  name: '不含税金额',
                  con: 'amt_no_tax'
                },
                {
                  name: '税率',
                  con: 'rate_tax'
                },
                {
                  name: '税额',
                  con: 'amt_tax'
                },
                {
                  name: '发票金额',
                  con: 'amt_inv_detail'
                },
                {
                  name: '预计回款日期',
                  con: 'date_rec_est'
                },
                {
                  name: '预计回款金额',
                  con: 'amt_rec_est'
                },
                {
                  name: '纳税识别号',
                  con: 'tax_no'
                },
                {
                  name: '手机',
                  con: 'mobile'
                },
                {
                  name: '地址',
                  con: 'addr'
                },
                {
                  name: '开户行',
                  con: 'bank'
                },
                {
                  name: '开户行账号',
                  con: 'bank_acct'
                }
              ]
              this.zhanshi2_list = [
                {
                  name: '客户',
                  con: 'cust_name',
                  zhi: false
                },
                {
                  name: '归档号',
                  con: 'file_no',
                  zhi: false
                },
                {
                  name: '负责人',
                  con: 'staff_name',
                  zhi: false
                },
                {
                  name: '部门',
                  con: 'dept_name',
                  zhi: false
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ',
                  zhi: false
                },
                {
                  name: '发票日期',
                  con: 'date_inv',
                  zhi: false
                },
                {
                  name: '发票类型',
                  con: 'inv_type',
                  zhi: false
                },
                {
                  name: '合计不含税金额',
                  con: 'amt_no_tax_sum',
                  zhi: false
                },
                {
                  name: '合计税额',
                  con: 'amt_tax_sum',
                  zhi: false
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum',
                  zhi: false
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_inv_un',
                  zhi: false
                },
                {
                  name: '合计发票金额',
                  con: 'amt_inv',
                  zhi: false
                },
                {
                  name: '产品',
                  con: 'prod_name',
                  zhi: false
                },
                {
                  name: '产品编号',
                  con: 'prod_code',
                  zhi: false
                },
                {
                  name: '类型',
                  con: 'cat_name',
                  zhi: false
                },
                {
                  name: '规格',
                  con: 'spec',
                  zhi: false
                },
                {
                  name: '型号',
                  con: 'type',
                  zhi: false
                },
                {
                  name: '单位',
                  con: 'unit',
                  zhi: false
                },
                {
                  name: '单价',
                  con: 'price_unit',
                  zhi: false
                },
                {
                  name: '数量',
                  con: 'qty',
                  zhi: false
                },
                {
                  name: '原价',
                  con: 'price_orig',
                  zhi: false
                },
                {
                  name: '折扣率',
                  con: 'disc_rate',
                  zhi: false
                },
                {
                  name: '售价',
                  con: 'price_sale',
                  zhi: false
                },
                {
                  name: '不含税折后单价',
                  con: 'price_unit_no_tax',
                  zhi: false
                },
                {
                  name: '不含税金额',
                  con: 'amt_no_tax',
                  zhi: false
                },
                {
                  name: '税率',
                  con: 'rate_tax',
                  zhi: false
                },
                {
                  name: '税额',
                  con: 'amt_tax',
                  zhi: false
                },
                {
                  name: '发票金额',
                  con: 'amt_inv_detail',
                  zhi: false
                },
                {
                  name: '预计回款日期',
                  con: 'date_rec_est',
                  zhi: false
                },
                {
                  name: '预计回款金额',
                  con: 'amt_rec_est',
                  zhi: false
                },
                {
                  name: '纳税识别号',
                  con: 'tax_no',
                  zhi: false
                },
                {
                  name: '手机',
                  con: 'mobile',
                  zhi: false
                },
                {
                  name: '地址',
                  con: 'addr',
                  zhi: false
                },
                {
                  name: '开户行',
                  con: 'bank',
                  zhi: false
                },
                {
                  name: '开户行账号',
                  con: 'bank_acct',
                  zhi: false
                }
              ]
              this.xianshi_tit = this.biao1_tit
              this.xianshi_list = this.zhanshi1_list
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoyi_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'invoice',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              const cuowu = []
              date.forEach(item => {
                Object.assign(item, item.cl)
                // if(item.amt_list.length!=1){
                //     cuowu.push('111')
                // }
              })
              this.biao1_tit = [
                {
                  name: '客户',
                  con: 'cust_name'
                },
                {
                  name: '归档号',
                  con: 'file_no'
                },
                {
                  name: '负责人',
                  con: 'staff_name'
                },
                {
                  name: '部门',
                  con: 'dept_name'
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ'
                },
                {
                  name: '合计不含税金额',
                  con: 'amt_no_tax_sum'
                },
                {
                  name: '合计税额',
                  con: 'amt_tax_sum'
                },
                {
                  name: '合计发票金额',
                  con: 'amt_inv'
                },
                {
                  name: '累计合同金额',
                  con: 'amt_con_sum'
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum'
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_con_no_inv'
                },
                {
                  name: '合同未开发票天数',
                  con: 'days_con_no_inv'
                },
                {
                  name: '累计回款金额',
                  con: 'amt_rec_sum'
                },
                {
                  name: '累计应收金额',
                  con: 'amt_sum_should'
                },
                {
                  name: '应收回款天数',
                  con: 'days_rec_should'
                },
                {
                  name: '累计交付金额',
                  con: 'amt_del_sum'
                },
                {
                  name: '累计未交付金额',
                  con: 'amt_del_no'
                },
                {
                  name: '累计交付天数',
                  con: 'days_del'
                }
              ]
              this.zhanshi1_list = [
                {
                  name: '客户',
                  con: 'cust_name',
                  zhi: false
                },
                {
                  name: '归档号',
                  con: 'file_no',
                  zhi: false
                },
                {
                  name: '负责人',
                  con: 'staff_name',
                  zhi: false
                },
                {
                  name: '部门',
                  con: 'dept_name',
                  zhi: false
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ',
                  zhi: false
                },
                {
                  name: '合计不含税金额',
                  con: 'amt_no_tax_sum',
                  zhi: false
                },
                {
                  name: '合计税额',
                  con: 'amt_tax_sum',
                  zhi: false
                },
                {
                  name: '合计发票金额',
                  con: 'amt_inv',
                  zhi: false
                },
                {
                  name: '累计合同金额',
                  con: 'amt_con_sum',
                  zhi: false
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum',
                  zhi: false
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_con_no_inv',
                  zhi: false
                },
                {
                  name: '合同未开发票天数',
                  con: 'days_con_no_inv',
                  zhi: false
                },
                {
                  name: '累计回款金额',
                  con: 'amt_rec_sum',
                  zhi: false
                },
                {
                  name: '累计应收金额',
                  con: 'amt_sum_should',
                  zhi: false
                },
                {
                  name: '应收回款天数',
                  con: 'days_rec_should',
                  zhi: false
                },
                {
                  name: '累计交付金额',
                  con: 'amt_del_sum',
                  zhi: false
                },
                {
                  name: '累计未交付金额',
                  con: 'amt_del_no',
                  zhi: false
                },
                {
                  name: '累计交付天数',
                  con: 'days_del',
                  zhi: false
                }
              ]
              this.biao2_tit = [
                {
                  name: '客户',
                  con: 'cust_name'
                },
                {
                  name: '归档号',
                  con: 'file_no'
                },
                {
                  name: '负责人',
                  con: 'staff_name'
                },
                {
                  name: '部门',
                  con: 'dept_name'
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ'
                },
                {
                  name: '发票日期',
                  con: 'date_inv'
                },
                {
                  name: '发票类型',
                  con: 'inv_type'
                },
                {
                  name: '合计不含税金额',
                  con: 'amt_no_tax_sum'
                },
                {
                  name: '合计税额',
                  con: 'amt_tax_sum'
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum'
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_inv_un'
                },
                {
                  name: '合计发票金额',
                  con: 'amt_inv'
                },
                {
                  name: '产品',
                  con: 'prod_name'
                },
                {
                  name: '产品编号',
                  con: 'prod_code'
                },
                {
                  name: '类型',
                  con: 'cat_name'
                },
                {
                  name: '规格',
                  con: 'spec'
                },
                {
                  name: '型号',
                  con: 'type'
                },
                {
                  name: '单位',
                  con: 'unit'
                },
                {
                  name: '单价',
                  con: 'price_unit'
                },
                {
                  name: '数量',
                  con: 'qty'
                },
                {
                  name: '原价',
                  con: 'price_orig'
                },
                {
                  name: '折扣率',
                  con: 'disc_rate'
                },
                {
                  name: '售价',
                  con: 'price_sale'
                },
                {
                  name: '不含税折后单价',
                  con: 'price_unit_no_tax'
                },
                {
                  name: '不含税金额',
                  con: 'amt_no_tax'
                },
                {
                  name: '税率',
                  con: 'rate_tax'
                },
                {
                  name: '税额',
                  con: 'amt_tax'
                },
                {
                  name: '发票金额',
                  con: 'amt_inv_detail'
                },
                {
                  name: '预计回款日期',
                  con: 'date_rec_est'
                },
                {
                  name: '预计回款金额',
                  con: 'amt_rec_est'
                },
                {
                  name: '纳税识别号',
                  con: 'tax_no'
                },
                {
                  name: '手机',
                  con: 'mobile'
                },
                {
                  name: '地址',
                  con: 'addr'
                },
                {
                  name: '开户行',
                  con: 'bank'
                },
                {
                  name: '开户行账号',
                  con: 'bank_acct'
                }
              ]
              this.zhanshi2_list = [
                {
                  name: '客户',
                  con: 'cust_name',
                  zhi: false
                },
                {
                  name: '归档号',
                  con: 'file_no',
                  zhi: false
                },
                {
                  name: '负责人',
                  con: 'staff_name',
                  zhi: false
                },
                {
                  name: '部门',
                  con: 'dept_name',
                  zhi: false
                },
                {
                  name: '所属公司',
                  con: 'belong_to_organ',
                  zhi: false
                },
                {
                  name: '发票日期',
                  con: 'date_inv',
                  zhi: false
                },
                {
                  name: '发票类型',
                  con: 'inv_type',
                  zhi: false
                },
                {
                  name: '合计不含税金额',
                  con: 'amt_no_tax_sum',
                  zhi: false
                },
                {
                  name: '合计税额',
                  con: 'amt_tax_sum',
                  zhi: false
                },
                {
                  name: '累计发票金额',
                  con: 'amt_inv_sum',
                  zhi: false
                },
                {
                  name: '合同未开发票金额',
                  con: 'amt_inv_un',
                  zhi: false
                },
                {
                  name: '合计发票金额',
                  con: 'amt_inv',
                  zhi: false
                },
                {
                  name: '产品',
                  con: 'prod_name',
                  zhi: false
                },
                {
                  name: '产品编号',
                  con: 'prod_code',
                  zhi: false
                },
                {
                  name: '类型',
                  con: 'cat_name',
                  zhi: false
                },
                {
                  name: '规格',
                  con: 'spec',
                  zhi: false
                },
                {
                  name: '型号',
                  con: 'type',
                  zhi: false
                },
                {
                  name: '单位',
                  con: 'unit',
                  zhi: false
                },
                {
                  name: '单价',
                  con: 'price_unit',
                  zhi: false
                },
                {
                  name: '数量',
                  con: 'qty',
                  zhi: false
                },
                {
                  name: '原价',
                  con: 'price_orig',
                  zhi: false
                },
                {
                  name: '折扣率',
                  con: 'disc_rate',
                  zhi: false
                },
                {
                  name: '售价',
                  con: 'price_sale',
                  zhi: false
                },
                {
                  name: '不含税折后单价',
                  con: 'price_unit_no_tax',
                  zhi: false
                },
                {
                  name: '不含税金额',
                  con: 'amt_no_tax',
                  zhi: false
                },
                {
                  name: '税率',
                  con: 'rate_tax',
                  zhi: false
                },
                {
                  name: '税额',
                  con: 'amt_tax',
                  zhi: false
                },
                {
                  name: '发票金额',
                  con: 'amt_inv_detail',
                  zhi: false
                },
                {
                  name: '预计回款日期',
                  con: 'date_rec_est',
                  zhi: false
                },
                {
                  name: '预计回款金额',
                  con: 'amt_rec_est',
                  zhi: false
                },
                {
                  name: '纳税识别号',
                  con: 'tax_no',
                  zhi: false
                },
                {
                  name: '手机',
                  con: 'mobile',
                  zhi: false
                },
                {
                  name: '地址',
                  con: 'addr',
                  zhi: false
                },
                {
                  name: '开户行',
                  con: 'bank',
                  zhi: false
                },
                {
                  name: '开户行账号',
                  con: 'bank_acct',
                  zhi: false
                }
              ]
              this.xianshi_tit = this.biao1_tit
              this.xianshi_list = this.zhanshi1_list
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
              this.qiehuan_hz_mx()
            } else if (res.data.code == 500) {}
          })
        }
      } else if (table == '回款') {
        this.biao1_tit = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '回款金额',
            con: 'amt_rec'
          },
          {
            name: '应收金额',
            con: 'amt_sum_should'
          },
          {
            name: '累计合同金额',
            con: 'amt_con_sum'
          },
          {
            name: '累计发票金额',
            con: 'amt_inv_sum'
          },
          {
            name: '合同未开发票金额',
            con: 'amt_con_no_inv'
          },
          {
            name: '合同未开发票天数',
            con: 'days_con_no_inv'
          },
          {
            name: '累计回款金额',
            con: 'amt_rec_sum'
          },
          {
            name: '累计应收金额',
            con: 'amt_sum_should'
          },
          {
            name: '应收回款天数',
            con: 'days_rec_should'
          },
          {
            name: '累计交付金额',
            con: 'amt_del_sum'
          },
          {
            name: '累计未交付金额',
            con: 'amt_del_no'
          },
          {
            name: '累计交付天数',
            con: 'days_del'
          }
        ]
        this.biao2_tit = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '回款日期',
            con: 'date_rec'
          },
          {
            name: '发票代码',
            con: 'inv_code'
          },
          {
            name: '发票号码',
            con: 'inv_no'
          },
          {
            name: '回款金额',
            con: 'amt_rec'
          },
          {
            name: '累计回款金额',
            con: 'amt_rec_sum'
          },
          {
            name: '累计开票金额',
            con: 'amt_inv_sum'
          },
          {
            name: '应收金额',
            con: 'amt_sum_should'
          },
          {
            name: '产品',
            con: 'prod_name'
          },
          {
            name: '产品编号',
            con: 'prod_code'
          },
          {
            name: '类型',
            con: 'cat_name'
          },
          {
            name: '规格',
            con: 'spec'
          },
          {
            name: '型号',
            con: 'type'
          },
          {
            name: '单位',
            con: 'unit'
          },
          {
            name: '单价',
            con: 'price_unit'
          },
          {
            name: '数量',
            con: 'qty'
          },
          {
            name: '原价',
            con: 'price_orig'
          },
          {
            name: '折扣率',
            con: 'disc_rate'
          },
          {
            name: '售价',
            con: 'price_sale'
          }
        ]
        this.xianshi_tit = this.biao1_tit
        this.zhanshi1_list = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          {
            name: '回款金额',
            con: 'amt_rec',
            zhi: false
          },
          {
            name: '累计回款金额',
            con: 'amt_rec_sum',
            zhi: false
          },
          {
            name: '应收金额',
            con: 'amt_sum_should',
            zhi: false
          },
          {
            name: '累计合同金额',
            con: 'amt_con_sum',
            zhi: false
          },
          {
            name: '累计发票金额',
            con: 'amt_inv_sum',
            zhi: false
          },
          {
            name: '合同未开发票金额',
            con: 'amt_con_no_inv',
            zhi: false
          },
          {
            name: '合同未开发票天数',
            con: 'days_con_no_inv',
            zhi: false
          },
          {
            name: '累计回款金额',
            con: 'amt_rec_sum',
            zhi: false
          },
          {
            name: '累计应收金额',
            con: 'amt_sum_should',
            zhi: false
          },
          {
            name: '应收回款天数',
            con: 'days_rec_should',
            zhi: false
          },
          {
            name: '累计交付金额',
            con: 'amt_del_sum',
            zhi: false
          },
          {
            name: '累计未交付金额',
            con: 'amt_del_no',
            zhi: false
          },
          {
            name: '累计交付天数',
            con: 'days_del',
            zhi: false
          }
        ]
        this.zhanshi2_list = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          {
            name: '回款日期',
            con: 'date_rec',
            zhi: false
          },
          {
            name: '发票代码',
            con: 'inv_code',
            zhi: false
          },
          {
            name: '发票号码',
            con: 'inv_no',
            zhi: false
          },
          {
            name: '回款金额',
            con: 'amt_rec',
            zhi: false
          },
          {
            name: '累计回款金额',
            con: 'amt_rec_sum',
            zhi: false
          },
          {
            name: '累计发票金额',
            con: 'amt_inv_sum',
            zhi: false
          },
          {
            name: '应收金额',
            con: 'amt_sum_should',
            zhi: false
          },
          {
            name: '产品',
            con: 'prod_name',
            zhi: false
          },
          {
            name: '产品编号',
            con: 'prod_code',
            zhi: false
          },
          {
            name: '类型',
            con: 'cat_name',
            zhi: false
          },
          {
            name: '规格',
            con: 'spec',
            zhi: false
          },
          {
            name: '型号',
            con: 'type',
            zhi: false
          },
          {
            name: '单位',
            con: 'unit',
            zhi: false
          },
          {
            name: '单价',
            con: 'price_unit',
            zhi: false
          },
          {
            name: '数量',
            con: 'qty',
            zhi: false
          },
          {
            name: '原价',
            con: 'price_orig',
            zhi: false
          },
          {
            name: '折扣率',
            con: 'disc_rate',
            zhi: false
          },
          {
            name: '售价',
            con: 'price_sale',
            zhi: false
          }
        ]
        this.xianshi_list = this.zhanshi1_list
        if (this.biaoyi_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'received_cash',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              date.forEach(item => {
                Object.assign(item, item.cl)
              })
              console.log(date)
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoyi_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 'received_cash',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              date.forEach(item => {
                Object.assign(item, item.cl)
              })
              console.log(date)
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
              this.qiehuan_hz_mx()
            } else if (res.data.code == 500) {}
          })
        }
      } else if (table == '交付单') {
        this.biao1_tit = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          // {  梦颖让删的
          //     name:'日期',
          //     con:'date_deliver'
          // },
          {
            name: '交付金额',
            con: 'amt_deliver'
          }
        ]
        this.biao2_tit = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '日期',
            con: 'date_deliver'
          },
          {
            name: '交付金额',
            con: 'amt_deliver'
          },
          {
            name: '产品',
            con: 'prod_name'
          },
          {
            name: '产品编号',
            con: 'prod_code'
          },
          {
            name: '类型',
            con: 'cat_name'
          },
          {
            name: '规格',
            con: 'spec'
          },
          {
            name: '型号',
            con: 'type'
          },
          {
            name: '单位',
            con: 'unit'
          },
          {
            name: '单价',
            con: 'price_unit'
          },
          {
            name: '数量',
            con: 'qty'
          },
          // {
          //     name:'原价',
          //     con:'price_orig'
          // },
          {
            name: '折扣率',
            con: 'disc_rate'
          },
          {
            name: '售价',
            con: 'price_sale'
          }
        ]
        this.xianshi_tit = this.biao1_tit
        this.zhanshi1_list = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          // {
          //     name:'日期',
          //     con:'date_deliver',
          //     zhi:false
          // },
          {
            name: '交付金额',
            con: 'amt_deliver',
            zhi: false
          }
        ]
        this.zhanshi2_list = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          {
            name: '日期',
            con: 'date_deliver',
            zhi: false
          },
          {
            name: '交付金额',
            con: 'amt_deliver',
            zhi: false
          },
          {
            name: '产品',
            con: 'prod_name',
            zhi: false
          },
          {
            name: '产品编号',
            con: 'prod_code',
            zhi: false
          },
          {
            name: '类型',
            con: 'cat_name',
            zhi: false
          },
          {
            name: '规格',
            con: 'spec',
            zhi: false
          },
          {
            name: '型号',
            con: 'type',
            zhi: false
          },
          {
            name: '单位',
            con: 'unit',
            zhi: false
          },
          {
            name: '单价',
            con: 'price_unit',
            zhi: false
          },
          {
            name: '数量',
            con: 'qty',
            zhi: false
          },
          // {
          //     name:'原价',
          //     con:'price_orig',
          //     zhi:false
          // },
          {
            name: '折扣率',
            con: 'disc_rate',
            zhi: false
          },
          {
            name: '售价',
            con: 'price_sale',
            zhi: false
          }
        ]
        this.xianshi_list = this.zhanshi1_list
        if (this.biaoyi_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 's_deliver',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoyi_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 's_deliver',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
              this.qiehuan_hz_mx()
            } else if (res.data.code == 500) {}
          })
        }
      } else if (table == '访客记录') {
        this.biao1_tit = [
        ]
        this.biao2_tit = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '拜访方式',
            con: 'visit_type'
          },
          {
            name: '拜访时间',
            con: 'visit_time'
          },
          {
            name: '决策人',
            con: 'contact'
          },
          {
            name: '漏斗现阶段',
            con: 'loudou_xianjie_duan'
          },
          {
            name: '预计下一漏斗阶段',
            con: 'yuji_xiayi_jieduan'
          },
          {
            name: '备注',
            con: 'remarks'
          }
        ]
        this.xianshi_tit = this.biao1_tit
        this.zhanshi1_list = [
        ]
        this.zhanshi2_list = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          {
            name: '拜访方式',
            con: 'visit_type',
            zhi: false
          },
          {
            name: '拜访时间',
            con: 'visit_time',
            zhi: false
          },
          {
            name: '决策人',
            con: 'contact',
            zhi: false
          },
          {
            name: '漏斗现阶段',
            // con:'current_stage',
            con: 'loudou_xianjie_duan',
            zhi: false
          },
          {
            name: '预计下一漏斗阶段',
            // con:'next_stage',
            con: 'yuji_xiayi_jieduan',
            zhi: false
          },
          {
            name: '备注',
            con: 'remarks',
            zhi: false
          }
        ]
        this.xianshi_list = this.zhanshi1_list
        this.biaoyi_num = 1
        // if(this.biaoyi_num==0){
        // query_form_list_gather({data:{
        //     ent_id:this.$ent_id(),
        //     form_type:'s_visit_plan',
        //     flow_status:'4',
        //     user_id:this.$jichuxinxi().user_id,
        //     look_type:this.look_type
        // }}).then(res=>{
        //     if(res.data.code==200){
        //         let date=JSON.parse(res.data.body.data)
        //         date.forEach(it=>{
        //             this.$set(it,'loudou_xianjie_duan',this.jieduan_hanzi(it.current_stage))
        //             this.$set(it,'yuji_xiayi_jieduan',this.jieduan_hanzi(it.next_stage))
        //         })
        //         console.log(date)
        // this.dangqian_xiangqing=[]
        // }else if(res.data.code==500){}
        // })
        // }else if(this.biaoyi_num==1){
        query_form_list({
          data: {
            ent_id: this.$ent_id(),
            form_type: 's_visit_plan',
            flow_status: '4',
            user_id: this.$jichuxinxi().user_id,
            look_type: this.look_type
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            date.forEach(it => {
              this.$set(it, 'loudou_xianjie_duan', this.jieduan_hanzi(it.current_stage))
              this.$set(it, 'yuji_xiayi_jieduan', this.jieduan_hanzi(it.next_stage))
            })
            console.log(date)
            this.dangqian_xiangqing = date
            this.qiehuan_hz_mx()
          } else if (res.data.code == 500) {}
        })
        // }
        this.biao1_tit2 = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          // {
          //     name:'开始时间',
          //     con:'start_time'
          // },
          // {
          //     name:'结束时间',
          //     con:'end_time'
          // },
          {
            name: '时长',
            con: 'hour_long'
          }
        ]
        this.biao2_tit2 = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '开始时间',
            con: 'start_time'
          },
          {
            name: '结束时间',
            con: 'end_time'
          },
          {
            name: '时长',
            con: 'hour_long'
          },
          {
            name: '漏斗现阶段',
            con: 'loudou_xianjie_duan'
          },
          {
            name: '漏斗阶段变化',
            con: 'funn_stage_change_flag'
          },
          {
            name: '漏斗达成阶段',
            con: 'yuji_xiayi_jieduan'
          },
          {
            name: '阶段变化原因',
            con: 'change_cause'
          },
          {
            name: '备注',
            con: 'remarks'
          }
        ]
        this.xianshi2_tit = this.biao1_tit2
        this.zhanshi1_list2 = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          // {
          //     name:'开始时间',
          //     con:'start_time',
          //     zhi:false
          // },
          // {
          //     name:'结束时间',
          //     con:'end_time',
          //     zhi:false
          // },
          {
            name: '时长',
            con: 'hour_long',
            zhi: false
          }
        ]
        this.zhanshi2_list2 = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          {
            name: '开始时间',
            con: 'start_time',
            zhi: false
          },
          {
            name: '结束时间',
            con: 'end_time',
            zhi: false
          },
          {
            name: '时长',
            con: 'hour_long',
            zhi: false
          },
          {
            name: '漏斗现阶段',
            con: 'loudou_xianjie_duan',
            zhi: false
          },
          {
            name: '漏斗阶段变化',
            con: 'funn_stage_change_flag',
            zhi: false
          },
          {
            name: '漏斗达成阶段',
            con: 'yuji_xiayi_jieduan',
            zhi: false
          },
          {
            name: '阶段变化原因',
            con: 'change_cause',
            zhi: false
          },
          {
            name: '备注',
            con: 'remarks',
            zhi: false
          }
        ]
        this.xianshi2_list = this.zhanshi1_list2
        if (this.biaoer_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 's_visit_record',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              date.forEach(it => {
                this.$set(it, 'loudou_xianjie_duan', this.jieduan_hanzi(it.current_stage))
                this.$set(it, 'yuji_xiayi_jieduan', this.jieduan_hanzi(it.funn_stage))
              })
              this.dangqian_xiangqing2 = date
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoer_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 's_visit_record',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              date.forEach(it => {
                this.$set(it, 'loudou_xianjie_duan', this.jieduan_hanzi(it.current_stage))
                this.$set(it, 'yuji_xiayi_jieduan', this.jieduan_hanzi(it.funn_stage))
              })
              this.dangqian_xiangqing2 = date
              this.qiehuan_hz_mx2()
            } else if (res.data.code == 500) {}
          })
        }
      } else if (table == '工单') {
        this.biao1_tit = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '派工人员',
            con: 'disp_staff_name'
          },
          {
            name: '决策人',
            con: 'contact'
          },
          {
            name: '手机',
            con: 'mobile'
          },
          {
            name: '时长',
            con: 'hour_long'
          }
        ]
        this.biao2_tit = [
          {
            name: '客户',
            con: 'cust_name'
          },
          {
            name: '归档号',
            con: 'file_no'
          },
          {
            name: '负责人',
            con: 'staff_name'
          },
          {
            name: '部门',
            con: 'dept_name'
          },
          {
            name: '是否为助单行为',
            con: 'help_order_flag'
          },
          {
            name: '派工人员',
            con: 'disp_staff_name'
          },
          {
            name: '助单类型',
            con: 'help_order_type'
          },
          {
            name: '出差类型',
            con: 'biz_trip_type'
          },
          {
            name: '地址',
            con: 'addr'
          },
          {
            name: '决策人',
            con: 'contact'
          },
          {
            name: '手机',
            con: 'mobile'
          },
          {
            name: '开始时间',
            con: 'trip_start_time'
          },
          {
            name: '结束时间',
            con: 'trip_end_time'
          },
          {
            name: '时长',
            con: 'hour_long'
          },
          {
            name: '产品',
            con: 'prod_name'
          },
          {
            name: '产品编号',
            con: 'prod_code'
          },
          {
            name: '类型',
            con: 'cat_name'
          },
          {
            name: '规格',
            con: 'spec'
          },
          {
            name: '型号',
            con: 'type'
          },
          {
            name: '单位',
            con: 'unit'
          },
          {
            name: '单价',
            con: 'price_unit'
          },
          {
            name: '派工项目',
            con: 'disp_item'
          }
        ]
        this.xianshi_tit = this.biao1_tit
        this.zhanshi1_list = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          {
            name: '派工人员',
            con: 'disp_staff_name',
            zhi: false
          },
          {
            name: '决策人',
            con: 'contact',
            zhi: false
          },
          {
            name: '手机',
            con: 'mobile',
            zhi: false
          },
          {
            name: '时长',
            con: 'hour_long',
            zhi: false
          }
        ]
        this.zhanshi2_list = [
          {
            name: '客户',
            con: 'cust_name',
            zhi: false
          },
          {
            name: '归档号',
            con: 'file_no',
            zhi: false
          },
          {
            name: '负责人',
            con: 'staff_name',
            zhi: false
          },
          {
            name: '部门',
            con: 'dept_name',
            zhi: false
          },
          {
            name: '是否为助单行为',
            con: 'help_order_flag',
            zhi: false
          },
          {
            name: '派工人员',
            con: 'disp_staff_name',
            zhi: false
          },
          {
            name: '助单类型',
            con: 'help_order_type',
            zhi: false
          },
          {
            name: '出差类型',
            con: 'biz_trip_type',
            zhi: false
          },
          {
            name: '地址',
            con: 'addr',
            zhi: false
          },
          {
            name: '决策人',
            con: 'contact',
            zhi: false
          },
          {
            name: '手机',
            con: 'mobile',
            zhi: false
          },
          {
            name: '开始时间',
            con: 'trip_start_time',
            zhi: false
          },
          {
            name: '结束时间',
            con: 'trip_end_time',
            zhi: false
          },
          {
            name: '时长',
            con: 'hour_long',
            zhi: false
          },
          {
            name: '产品',
            con: 'prod_name',
            zhi: false
          },
          {
            name: '产品编号',
            con: 'prod_code',
            zhi: false
          },
          {
            name: '类型',
            con: 'cat_name',
            zhi: false
          },
          {
            name: '规格',
            con: 'spec',
            zhi: false
          },
          {
            name: '型号',
            con: 'type',
            zhi: false
          },
          {
            name: '单位',
            con: 'unit',
            zhi: false
          },
          {
            name: '单价',
            con: 'price_unit',
            zhi: false
          },
          {
            name: '派工项目',
            con: 'disp_item',
            zhi: false
          }
        ]
        this.xianshi_list = this.zhanshi1_list
        if (this.biaoyi_num == 0) {
          query_form_list_gather({
            data: {
              ent_id: this.$ent_id(),
              form_type: 's_dispatch',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              console.log(date)
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
            } else if (res.data.code == 500) {}
          })
        } else if (this.biaoyi_num == 1) {
          query_form_list({
            data: {
              ent_id: this.$ent_id(),
              form_type: 's_dispatch',
              flow_status: '4',
              user_id: this.$jichuxinxi().user_id,
              look_type: this.look_type
            }
          }).then(res => {
            if (res.data.code == 200) {
              const date = JSON.parse(res.data.body.data)
              this.dangqian_xiangqing = date
              this.dangqian_neirong = date
              this.qiehuan_hz_mx()
            } else if (res.data.code == 500) {}
          })
        }
      }
    },
    guanbi () {
      this.yemian = false
      this.biaoyi_num = '0'
      this.biaoer_num = '0'
      this.spanArr3 = []
      this.pos3 = 0
    },
    jieduan_hanzi (i) {
      if (i == 1) {
        return '初步接触'
      } else if (i == 2) {
        return '需求确认'
      } else if (i == 3) {
        return '报价及方案展示'
      } else if (i == 4) {
        return '商务谈判'
      } else if (i == 5) {
        return '赢单'
      } else if (i == 6) {
        return '输单'
      }
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;'
    },
    // 点击选择全部
    dianji_xuanze_quanbu () {
      this.xianshi_quanbu = !this.xianshi_quanbu
      if (this.xianshi_quanbu) {
        this.xianshi_list.map((i, index) => { i.zhi = true })
        this.xianshi_xiang_list = []
        for (let i = 0; i < this.xianshi_list.length; i++) {
          this.xianshi_xiang_list.push(this.xianshi_list[i])
        }
      } else {
        this.xianshi_list.map((i, index) => { i.zhi = false })
        this.xianshi_xiang_list = []
      }
    },
    xuanze_danxuan (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.xianshi_xiang_list.push(i)
      } else {
        for (let a = 0; a < this.xianshi_xiang_list.length; a++) {
          if (this.xianshi_xiang_list[a].con == i.con) {
            this.xianshi_xiang_list.splice(a, 1)
          }
        }
      }
    },
    dianji_shanchu_xianshi (i, index) {
      this.xianshi_list.map((item, idx) => {
        if (item.con == i.con) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list.splice(index, 1)
    },
    dianji_lie_queding () {
      this.xianshi_tit = this.xianshi_xiang_list
      this.xianshi_xiang_list = []
      this.xianshi_shezhi = false
    },
    // 点击选择全部
    dianji_xuanze_quanbu2 () {
      this.xianshi_quanbu2 = !this.xianshi_quanbu2
      if (this.xianshi_quanbu2) {
        this.xianshi2_list.map((i, index) => { i.zhi = true })
        this.xianshi_xiang_list2 = []
        for (let i = 0; i < this.xianshi2_list.length; i++) {
          this.xianshi_xiang_list2.push(this.xianshi2_list[i])
        }
      } else {
        this.xianshi2_list.map((i, index) => { i.zhi = false })
        this.xianshi_xiang_list2 = []
      }
    },
    xuanze_danxuan2 (i) {
      i.zhi = !i.zhi
      if (i.zhi) {
        this.xianshi_xiang_list2.push(i)
      } else {
        for (let a = 0; a < this.xianshi_xiang_list2.length; a++) {
          if (this.xianshi_xiang_list2[a].con == i.con) {
            this.xianshi_xiang_list2.splice(a, 1)
          }
        }
      }
    },
    dianji_shanchu_xianshi2 (i, index) {
      this.xianshi2_list.map((item, idx) => {
        if (item.con == i.con) {
          item.zhi = false
        }
      })
      this.xianshi_xiang_list2.splice(index, 1)
    },
    dianji_lie_queding2 () {
      this.xianshi2_tit = this.xianshi_xiang_list2
      this.xianshi_xiang_list2 = []
      this.xianshi_shezhi2 = false
    },
    type_zhuanhua (i) {
      if (i == '漏斗阶段') {
        return 'funn'
      } else if (i == '合同') {
        return 'contract'
      } else if (i == '发票') {
        return 'invoice'
      } else if (i == '回款') {
        return 'received_cash'
      } else if (i == '交付单') {
        return 's_deliver'
      } else if (i == '访客记录') {
        return 's_visit_plan'
      } else if (i == '工单') {
        return 's_dispatch'
      }
    },
    sousuo_1 () {
      if (this.biaoyi_num == 0) {
        query_form_list_gather({
          data: {
            ent_id: this.$ent_id(),
            form_type: this.type_zhuanhua(this.biao_name),
            search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
            flow_status: '4',
            user_id: this.$jichuxinxi().user_id,
            look_type: this.look_type
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            if (this.biao_name == '漏斗阶段') {
              date.forEach(item => {
                this.$set(item, 'jieduan', this.jieduan_hanzi(item.stage_funn))
                this.$set(item, 'loudou_zhesuan_jine', (Number(item.rate_funn_disc) * Number(item.amt_funn)).toFixed(2))
                this.$set(item, 'bili', Number(item.rate_funn_disc) * 100 + '%')
              })
            } else if (this.biao_name == '合同' || this.biao_name == '发票' || this.biao_name == '回款') {
              date.forEach(item => {
                Object.assign(item, item.cl)
              })
            } else if (this.biao_name == '访客记录') {
              date.forEach(it => {
                this.$set(it, 'loudou_xianjie_duan', this.jieduan_hanzi(it.current_stage))
                this.$set(it, 'yuji_xiayi_jieduan', this.jieduan_hanzi(it.next_stage))
              })
            }
            this.dangqian_xiangqing = date
            this.dangqian_neirong = date
          } else if (res.data.code == 500) {}
        })
      } else if (this.biaoyi_num == 1) {
        query_form_list({
          data: {
            ent_id: this.$ent_id(),
            form_type: this.type_zhuanhua(this.biao_name),
            search_str: this.sousuo_text.length != 0 ? this.sousuo_text : null,
            flow_status: '4',
            user_id: this.$jichuxinxi().user_id,
            look_type: this.look_type
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            if (this.biao_name == '漏斗阶段') {
              date.forEach(item => {
                this.$set(item, 'jieduan', this.jieduan_hanzi(item.stage_funn))
                this.$set(item, 'loudou_zhesuan_jine', (Number(item.rate_funn_disc) * Number(item.amt_funn)).toFixed(2))
                this.$set(item, 'bili', Number(item.rate_funn_disc) * 100 + '%')
              })
            } else if (this.biao_name == '合同' || this.biao_name == '发票' || this.biao_name == '回款') {
              date.forEach(item => {
                Object.assign(item, item.cl)
              })
            } else if (this.biao_name == '访客记录') {
              date.forEach(it => {
                this.$set(it, 'loudou_xianjie_duan', this.jieduan_hanzi(it.current_stage))
                this.$set(it, 'yuji_xiayi_jieduan', this.jieduan_hanzi(it.next_stage))
              })
            }
            this.dangqian_xiangqing = date
            this.dangqian_neirong = date
            this.qiehuan_hz_mx()
          } else if (res.data.code == 500) {}
        })
      }
    },
    sousuo_2 () {
      if (this.biaoyi_num == 0) {
        query_form_list_gather({
          data: {
            ent_id: this.$ent_id(),
            form_type: 's_visit_record',
            search_str: this.sousuo_text2.length != 0 ? this.sousuo_text2 : null,
            flow_status: '4',
            user_id: this.$jichuxinxi().user_id,
            look_type: this.look_type
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            if (this.biao_name == '漏斗阶段') {
              date.forEach(item => {
                this.$set(item, 'jieduan', this.jieduan_hanzi(item.stage_funn))
                this.$set(item, 'loudou_zhesuan_jine', (Number(item.rate_funn_disc) * Number(item.amt_funn)).toFixed(2))
                this.$set(item, 'bili', Number(item.rate_funn_disc) * 100 + '%')
              })
            } else if (this.biao_name == '合同' || this.biao_name == '发票' || this.biao_name == '回款') {
              date.forEach(item => {
                Object.assign(item, item.cl)
              })
            } else if (this.biao_name == '访客记录') {
              date.forEach(it => {
                this.$set(it, 'loudou_xianjie_duan', this.jieduan_hanzi(it.current_stage))
                this.$set(it, 'yuji_xiayi_jieduan', this.jieduan_hanzi(it.next_stage))
              })
            }
            this.dangqian_xiangqing2 = date
          } else if (res.data.code == 500) {}
        })
      } else if (this.biaoyi_num == 1) {
        query_form_list({
          data: {
            ent_id: this.$ent_id(),
            form_type: 's_visit_record',
            search_str: this.sousuo_text2.length != 0 ? this.sousuo_text2 : null,
            flow_status: '4',
            user_id: this.$jichuxinxi().user_id,
            look_type: this.look_type
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            if (this.biao_name == '漏斗阶段') {
              date.forEach(item => {
                this.$set(item, 'jieduan', this.jieduan_hanzi(item.stage_funn))
                this.$set(item, 'loudou_zhesuan_jine', (Number(item.rate_funn_disc) * Number(item.amt_funn)).toFixed(2))
                this.$set(item, 'bili', Number(item.rate_funn_disc) * 100 + '%')
              })
            } else if (this.biao_name == '合同' || this.biao_name == '发票' || this.biao_name == '回款') {
              date.forEach(item => {
                Object.assign(item, item.cl)
              })
            } else if (this.biao_name == '访客记录') {
              date.forEach(it => {
                this.$set(it, 'loudou_xianjie_duan', this.jieduan_hanzi(it.current_stage))
                this.$set(it, 'yuji_xiayi_jieduan', this.jieduan_hanzi(it.next_stage))
              })
            }
            this.dangqian_xiangqing2 = date
            // this.dangqian_neirong2=date
            this.qiehuan_hz_mx2()
          } else if (res.data.code == 500) {}
        })
      }
    },
    // 切换汇总明细按钮
    qiehuan_hz_mx () {
      // this.biaoyi_num==0
      if (this.biaoyi_num == 0) {
        this.xianshi_tit = this.biao1_tit
        this.xianshi_list = this.zhanshi1_list
        this.spanArr3 = []
        this.pos3 = 0
        if (this.biao_name == '工单' || this.biao_name == '交付单' || this.biao_name == '漏斗阶段' || this.biao_name == '合同' || this.biao_name == '发票' || this.biao_name == '回款') {
          this.dangqian_xiangqing = this.dangqian_neirong // 当前内容不变
        }
      } else if (this.biaoyi_num == 1) {
        this.xianshi_tit = this.biao2_tit
        this.biaoyi_num = 1
        this.xianshi_list = this.zhanshi2_list
        console.log(this.dangqian_xiangqing)
        if (this.biao_name == '工单' || this.biao_name == '交付单' || this.biao_name == '漏斗阶段' || this.biao_name == '回款') {
          const date = this.dangqian_xiangqing
          const list = []
          date.forEach(item => {
            item.prod_detail.forEach(it => {
              const obj = {}
              Object.assign(obj, item, it)
              list.push(obj)
              // list.push
            })
          })
          console.log(list)
          this.dangqian_xiangqing = list
          // this.getSpanArr3(this.dangqian_xiangqing)
        }
        // else if(this.biao_name=='合同'||this.biao_name=='发票'){
        //     let date=this.dangqian_xiangqing
        //     // let list=[]
        //     // date.forEach(item=>{
        //     //     item.prod_list.forEach(it=>{
        //     //         let obj={}
        //     //         Object.assign(obj,item,it,item.amt_list[0])
        //     //         list.push(obj)
        //     //         // list.push
        //     //     })
        //     // })
        //     // console.log(list)
        //     this.dangqian_xiangqing=date
        //     // this.getSpanArr3(this.dangqian_xiangqing)
        // }
      }
    },
    qiehuan_hz_mx2 () {
      if (this.biaoer_num == 0) {
        this.xianshi2_tit = this.biao1_tit2
        this.xianshi2_list = this.zhanshi1_list2
      } else if (this.biaoer_num == 1) {
        this.xianshi2_tit = this.biao2_tit2
        this.xianshi2_list = this.zhanshi2_list2
      }
    },
    getSpanArr3 (data) {
      for (var i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr3.push(1)
          this.pos3 = 0
        } else {
          // 判断当前元素与上一个元素是否相同（line为标记）
          if (data[i].cust_name === data[i - 1].cust_name) {
            this.spanArr3[this.pos3] += 1
            this.spanArr3.push(0)
          } else {
            this.spanArr3.push(1)
            this.pos3 = i
          }
        }
      }
    },
    hebing ({ row, column, rowIndex, columnIndex }) {
      if (this.indexArr3.includes(rowIndex)) {
        if (columnIndex === 0) {
          return {
            rowspan: 1,
            colspan: 3
          }
        } else if (columnIndex === 1) {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
      if (columnIndex === 0) {
        const _row = this.spanArr3[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    wuyong () {},
    no_mingxi_tishi () {
      this.$message({
        message: '不可进入明细',
        type: 'warning'
      })
    },
    zidong_jisuan () {
      // if(this.biao_name=='合同'&&this.biaoyi_num==1){
      //     let hetong_jine_heji=''
      //     let list=this.$func.Es5duplicate(this.dangqian_xiangqing,'file_no')
      //     list.forEach(item=>{
      //         if(item.amt_con!=undefined){
      //             hetong_jine_heji=Number(hetong_jine_heji)+Number(item.amt_con)
      //         }
      //     })
      //     this.hetong_jine_heji=this.$qianwei(Number(hetong_jine_heji).toFixed(2))
      // }
      // if(this.biao_name=='发票'&&this.biaoyi_num==1){
      //     let buhan_shui_jine_heji='',
      //         shuie_heji='',
      //         fapiao_jine_heji=''
      //     this.dangqian_xiangqing.forEach(item=>{
      //         if(item.amt_no_tax!=undefined){
      //             buhan_shui_jine_heji=Number(buhan_shui_jine_heji)+Number(item.amt_no_tax)
      //         }
      //         if(item.amt_tax!=undefined){
      //             shuie_heji=Number(shuie_heji)+Number(item.amt_tax)
      //         }
      //         if(item.amt_inv_detail!=undefined){
      //             fapiao_jine_heji=Number(fapiao_jine_heji)+Number(item.amt_inv_detail)
      //         }
      //     })
      //     this.buhan_shui_jine_heji=this.$qianwei(Number(buhan_shui_jine_heji).toFixed(2))
      //     this.shuie_heji=this.$qianwei(Number(shuie_heji).toFixed(2))
      //     this.fapiao_jine_heji=this.$qianwei(Number(fapiao_jine_heji).toFixed(2))
      // }
      // if(this.biao_name=='回款'&&this.biaoyi_num==1){
      //     let huikuan_jine_heji='',
      //         leiji_fapiao_jine_heji='',
      //         yingshou_jine_heji='',
      //         leiji_huikuan_jine_heji=''
      //     let list=this.$func.Es5duplicate(this.dangqian_xiangqing,'file_no')
      //     list.forEach(item=>{
      //         if(item.amt_rec!=undefined){
      //             huikuan_jine_heji=Number(huikuan_jine_heji)+Number(item.amt_rec)
      //         }
      //         if(item.amt_inv_sum!=undefined){
      //             leiji_fapiao_jine_heji=Number(leiji_fapiao_jine_heji)+Number(item.amt_inv_sum)
      //         }
      //         if(item.amt_sum_should!=undefined){
      //             yingshou_jine_heji=Number(yingshou_jine_heji)+Number(item.amt_sum_should)
      //         }
      //         if(item.amt_rec_sum!=undefined){
      //             leiji_huikuan_jine_heji=Number(leiji_huikuan_jine_heji)+Number(item.amt_rec_sum)
      //         }
      //     })
      //     this.huikuan_jine_heji=this.$qianwei(Number(huikuan_jine_heji).toFixed(2))
      //     this.leiji_fapiao_jine_heji=this.$qianwei(Number(leiji_fapiao_jine_heji).toFixed(2))
      //     this.yingshou_jine_heji=this.$qianwei(Number(yingshou_jine_heji).toFixed(2))
      //     this.leiji_huikuan_jine_heji=this.$qianwei(Number(leiji_huikuan_jine_heji).toFixed(2))
      // }
      if (this.biao_name == '交付单') {
        let jine = ''
        // $func.Es5duplicate(this.dangqian_xiangqing,'file_no')
        const list = this.$func.Es5duplicate(this.dangqian_xiangqing, 'file_no')
        list.forEach(item => {
          if (item.amt_deliver != undefined) {
            jine = Number(jine) + Number(item.amt_deliver)
          }
        })
        this.jiaofu_jine_heji = this.$qianwei(Number(jine).toFixed(2))
      }
      if (this.biao_name == '漏斗阶段' && this.biaoyi_num == 0) {
        let jine = ''
        let loudou_zhesuan_jine_heji = ''
        this.dangqian_xiangqing.forEach(item => {
          if (item.amt_funn != undefined) {
            jine = Number(jine) + Number(item.amt_funn)
          }
          if (item.loudou_zhesuan_jine != undefined) {
            loudou_zhesuan_jine_heji = Number(loudou_zhesuan_jine_heji) + Number(item.loudou_zhesuan_jine)
          }
        })
        this.loudou_jine_heji = this.$qianwei(Number(jine).toFixed(2))
        this.loudou_zhesuan_jine_heji = this.$qianwei(Number(loudou_zhesuan_jine_heji).toFixed(2))
      } else if ((this.biao_name == '漏斗阶段' || this.biao_name == '交付单') && this.biaoyi_num == 1) {
        // ||this.biao_name=='合同'||this.biao_name=='发票'||this.biao_name=='回款'
        let shuliang = ''
        let yuanjia = ''
        let shoujia = ''
        this.dangqian_xiangqing.forEach(item => {
          if (item.qty != undefined) {
            shuliang = Number(shuliang) + Number(item.qty)
          }
          if (item.price_orig != undefined) {
            yuanjia = Number(yuanjia) + Number(item.price_orig)
          }
          if (item.price_sale != undefined) {
            shoujia = Number(shoujia) + Number(item.price_sale)
          }
        })
        this.shuliang_heji = this.$qianwei(Number(shuliang) + '')
        this.yuanjia_heji = this.$qianwei(Number(yuanjia).toFixed(2))
        this.shoujia_heji = this.$qianwei(Number(shoujia).toFixed(2))
        // &&this.biaoyi_num==0
      } else if (this.biao_name == '合同') {
        let hetong_jine_heji = ''; let fapiao_jine_heji = ''
        let weikai_fapiao_jine_heji = ''; let leiji_yingshou_jine_heji = ''
        let leiji_jiaofu_jine_heji = ''; let wei_jiaofu_jine_heji = ''
        let leiji_huikuan_jine_heji = ''
        const liebiao = JSON.parse(JSON.stringify(this.dangqian_xiangqing))
        console.log(liebiao)
        if (this.biaoyi_num == 1 && liebiao.length != 0) {
          const list = []
          // this.dangqian_xiangqing.forEach(item=>{
          //     if(Number(item.amt_con)<=0){
          //         list.push(item)
          //     }
          // })
          for (let a = 0; a < liebiao.length; a++) {
            if (liebiao[a].active == 0) {
              liebiao.splice(a, 1)
              a = a - 1   
            }
            // list.forEach(item=>{
            //     if(liebiao[a]!=undefined&&liebiao[a].file_no==item.file_no){
            //         liebiao.splice(a,1)
            //         a=a-1
            //     }
            //     // if(item.amt_con!=undefined){
            //     //     hetong_jine_heji=Number(hetong_jine_heji)+Number(item.amt_con)
            //     // }
            //     // if(item.amt_inv_sum!=undefined){
            //     //     fapiao_jine_heji=Number(fapiao_jine_heji)+Number(item.amt_inv_sum)
            //     // }
            //     // if(item.amt_con_no_inv!=undefined){
            //     //     weikai_fapiao_jine_heji=Number(weikai_fapiao_jine_heji)+Number(item.amt_con_no_inv)
            //     // }
            //     // if(item.amt_rec_sum!=undefined){
            //     //     leiji_huikuan_jine_heji=Number(leiji_huikuan_jine_heji)+Number(item.amt_rec_sum)
            //     // }
            //     // if(item.amt_sum_should!=undefined){
            //     //     leiji_yingshou_jine_heji=Number(leiji_yingshou_jine_heji)+Number(item.amt_sum_should)
            //     // }
            //     // if(item.amt_del_sum!=undefined){
            //     //     leiji_jiaofu_jine_heji=Number(leiji_jiaofu_jine_heji)+Number(item.amt_del_sum)
            //     // }
            //     // if(item.amt_del_no!=undefined){
            //     //     wei_jiaofu_jine_heji=Number(wei_jiaofu_jine_heji)+Number(item.amt_del_no)
            //     // }
            // })
          }
        }
        setTimeout(() => {
          console.log(liebiao)
          const list2 = this.$func.Es5duplicate(liebiao, 'file_no')
          list2.forEach(item => {
            if (item.amt_con != undefined) {
              hetong_jine_heji = Number(hetong_jine_heji) + Number(item.amt_con)
            }
            if (item.amt_inv_sum != undefined) {
              fapiao_jine_heji = Number(fapiao_jine_heji) + Number(item.amt_inv_sum)
            }
            if (item.amt_con_no_inv != undefined) {
              weikai_fapiao_jine_heji = Number(weikai_fapiao_jine_heji) + Number(item.amt_con_no_inv)
            }
            if (item.amt_rec_sum != undefined) {
              leiji_huikuan_jine_heji = Number(leiji_huikuan_jine_heji) + Number(item.amt_rec_sum)
            }
            if (item.amt_sum_should != undefined) {
              leiji_yingshou_jine_heji = Number(leiji_yingshou_jine_heji) + Number(item.amt_sum_should)
            }
            if (item.amt_del_sum != undefined) {
              leiji_jiaofu_jine_heji = Number(leiji_jiaofu_jine_heji) + Number(item.amt_del_sum)
            }
            if (item.amt_del_no != undefined) {
              wei_jiaofu_jine_heji = Number(wei_jiaofu_jine_heji) + Number(item.amt_del_no)
            }
          })
          this.hetong_jine_heji = this.$qianwei(Number(hetong_jine_heji).toFixed(2))
          this.fapiao_jine_heji = this.$qianwei(Number(fapiao_jine_heji).toFixed(2))
          this.weikai_fapiao_jine_heji = this.$qianwei(Number(weikai_fapiao_jine_heji).toFixed(2))
          this.leiji_huikuan_jine_heji = this.$qianwei(Number(leiji_huikuan_jine_heji).toFixed(2))
          this.leiji_yingshou_jine_heji = this.$qianwei(Number(leiji_yingshou_jine_heji).toFixed(2))
          this.leiji_jiaofu_jine_heji = this.$qianwei(Number(leiji_jiaofu_jine_heji).toFixed(2))
          this.wei_jiaofu_jine_heji = this.$qianwei(Number(wei_jiaofu_jine_heji).toFixed(2))
        }, 0)
      } else if (this.biao_name == '发票') {
        let buhan_shui_jine_heji = ''
        let shuie_heji = ''
        let fapiao_jine_heji = ''
        let leiji_fapiao_jine_heji = ''
        let leiji_hetong_jine_heji = ''
        let hetong_weikai_fapiao_jine_heji = ''
        let leiji_huikuan_jine_heji = ''
        let leiji_yingshou_jine_heji = ''
        let leiji_jiaofu_jine_heji = ''
        let wei_jiaofu_jine_heji = ''
        // let list=this.$func.Es5duplicate(this.dangqian_xiangqing,'file_no')
        const liebiao = JSON.parse(JSON.stringify(this.dangqian_xiangqing))
        if (this.biaoyi_num == 1 && liebiao.length != 0) {
          // let list=[]
          // this.dangqian_xiangqing.forEach(item=>{
          //     // 这里之后肯定会出问题   问题原因：没有判断是作废单，直接判断为负值就是作废   这逻辑不对
          //     if(Number(item.amt_inv)<=0){
          //         list.push(item)
          //     }
          // })
          for (let a = 0; a < liebiao.length; a++) {
            if (liebiao[a].active == 0) {
              liebiao.splice(a, 1)
              a = a - 1   
            }
            // list.forEach(item=>{
            //     if(liebiao[a]!=undefined&&liebiao[a].file_no==item.file_no){
            //         liebiao.splice(a,1)
            //         a=a-1
            //     }
            // })
          }
        }
        setTimeout(() => {
          console.log(liebiao)
          const list2 = this.$func.Es5duplicate(liebiao, 'file_no')
          list2.forEach(item => {
            if (item.amt_no_tax_sum != undefined) {
              buhan_shui_jine_heji = Number(buhan_shui_jine_heji) + Number(item.amt_no_tax_sum)
            }
            if (item.amt_tax_sum != undefined) {
              shuie_heji = Number(shuie_heji) + Number(item.amt_tax_sum)
            }
            if (item.amt_inv != undefined) {
              fapiao_jine_heji = Number(fapiao_jine_heji) + Number(item.amt_inv)
            }
            if (item.amt_inv_sum != undefined) {
              leiji_fapiao_jine_heji = Number(leiji_fapiao_jine_heji) + Number(item.amt_inv_sum)
            }
            if (item.amt_con_sum != undefined) {
              leiji_hetong_jine_heji = Number(leiji_hetong_jine_heji) + Number(item.amt_con_sum)
            }
            if (item.amt_con_no_inv != undefined) {
              hetong_weikai_fapiao_jine_heji = Number(hetong_weikai_fapiao_jine_heji) + Number(item.amt_con_no_inv)
            }
            if (item.amt_rec_sum != undefined) {
              leiji_huikuan_jine_heji = Number(leiji_huikuan_jine_heji) + Number(item.amt_rec_sum)
            }
            if (item.amt_sum_should != undefined) {
              leiji_yingshou_jine_heji = Number(leiji_yingshou_jine_heji) + Number(item.amt_sum_should)
            }
            if (item.amt_del_sum != undefined) {
              leiji_jiaofu_jine_heji = Number(leiji_jiaofu_jine_heji) + Number(item.amt_del_sum)
            }
            if (item.amt_del_no != undefined) {
              wei_jiaofu_jine_heji = Number(wei_jiaofu_jine_heji) + Number(item.amt_del_no)
            }
          })
          this.buhan_shui_jine_heji = this.$qianwei(Number(buhan_shui_jine_heji).toFixed(2))
          this.shuie_heji = this.$qianwei(Number(shuie_heji).toFixed(2))
          this.fapiao_jine_heji = this.$qianwei(Number(fapiao_jine_heji).toFixed(2))
          this.leiji_fapiao_jine_heji = this.$qianwei(Number(leiji_fapiao_jine_heji).toFixed(2))
          this.hetong_weikai_fapiao_jine_heji = this.$qianwei(Number(hetong_weikai_fapiao_jine_heji).toFixed(2))
          this.leiji_huikuan_jine_heji = this.$qianwei(Number(leiji_huikuan_jine_heji).toFixed(2))
          this.leiji_yingshou_jine_heji = this.$qianwei(Number(leiji_yingshou_jine_heji).toFixed(2))
          this.leiji_jiaofu_jine_heji = this.$qianwei(Number(leiji_jiaofu_jine_heji).toFixed(2))
          this.wei_jiaofu_jine_heji = this.$qianwei(Number(wei_jiaofu_jine_heji).toFixed(2))
          this.leiji_hetong_jine_heji = this.$qianwei(Number(leiji_hetong_jine_heji).toFixed(2))
        }, 0)
      } else if (this.biao_name == '回款') {
        let huikuan_jine_heji = ''
        let leiji_fapiao_jine_heji = ''
        let yingshou_jine_heji = ''
        let leiji_hetong_jine_heji = ''
        let hetong_weikai_fapiao_jine_heji = ''
        let leiji_huikuan_jine_heji = ''
        let leiji_yingshou_jine_heji = ''
        let leiji_jiaofu_jine_heji = ''
        let wei_jiaofu_jine_heji = ''
        const liebiao = JSON.parse(JSON.stringify(this.dangqian_xiangqing))
        if (this.biaoyi_num == 1 && liebiao.length != 0) {
          // let list=[]
          // this.dangqian_xiangqing.forEach(item=>{
          //     // 这里之后肯定会出问题   问题原因：没有判断是作废单，直接判断为负值就是作废   这逻辑不对
          //     if(Number(item.amt_inv)<=0){
          //         list.push(item)
          //     }
          // })
          for (let a = 0; a < liebiao.length; a++) {
            if (liebiao[a].active == 0) {
              liebiao.splice(a, 1)
              a = a - 1   
            }
            // list.forEach(item=>{
            //     if(liebiao[a]!=undefined&&liebiao[a].file_no==item.file_no){
            //         liebiao.splice(a,1)
            //         a=a-1
            //     }
            // })
          }
        }
        // let list=this.$func.Es5duplicate(this.dangqian_xiangqing,'flow_no')
        setTimeout(() => {
          const list2 = this.$func.Es5duplicate(liebiao, 'file_no')
          list2.forEach(item => {
            if (item.amt_rec != undefined) {
              huikuan_jine_heji = Number(huikuan_jine_heji) + Number(item.amt_rec)
            }
            if (item.amt_inv_sum != undefined) {
              leiji_fapiao_jine_heji = Number(leiji_fapiao_jine_heji) + Number(item.amt_inv_sum)
            }
            if (item.amt_con_sum != undefined) {
              leiji_hetong_jine_heji = Number(leiji_hetong_jine_heji) + Number(item.amt_con_sum)
            }
            if (item.amt_sum_should != undefined) {
              yingshou_jine_heji = Number(yingshou_jine_heji) + Number(item.amt_sum_should)
            }
            if (item.amt_con_no_inv != undefined) {
              hetong_weikai_fapiao_jine_heji = Number(hetong_weikai_fapiao_jine_heji) + Number(item.amt_con_no_inv)
            }
            if (item.amt_rec_sum != undefined) {
              leiji_huikuan_jine_heji = Number(leiji_huikuan_jine_heji) + Number(item.amt_rec_sum)
            }
            if (item.amt_sum_should != undefined) {
              leiji_yingshou_jine_heji = Number(leiji_yingshou_jine_heji) + Number(item.amt_sum_should)
            }
            if (item.amt_del_sum != undefined) {
              leiji_jiaofu_jine_heji = Number(leiji_jiaofu_jine_heji) + Number(item.amt_del_sum)
            }
            if (item.amt_del_no != undefined) {
              wei_jiaofu_jine_heji = Number(wei_jiaofu_jine_heji) + Number(item.amt_del_no)
            }
          })
          this.huikuan_jine_heji = this.$qianwei(Number(huikuan_jine_heji).toFixed(2))
          this.leiji_fapiao_jine_heji = this.$qianwei(Number(leiji_fapiao_jine_heji).toFixed(2))
          this.leiji_hetong_jine_heji = this.$qianwei(Number(leiji_hetong_jine_heji).toFixed(2))
          this.yingshou_jine_heji = this.$qianwei(Number(yingshou_jine_heji).toFixed(2))
          this.hetong_weikai_fapiao_jine_heji = this.$qianwei(Number(hetong_weikai_fapiao_jine_heji).toFixed(2))
          this.leiji_huikuan_jine_heji = this.$qianwei(Number(leiji_huikuan_jine_heji).toFixed(2))
          this.leiji_yingshou_jine_heji = this.$qianwei(Number(leiji_yingshou_jine_heji).toFixed(2))
          this.leiji_jiaofu_jine_heji = this.$qianwei(Number(leiji_jiaofu_jine_heji).toFixed(2))
          this.wei_jiaofu_jine_heji = this.$qianwei(Number(wei_jiaofu_jine_heji).toFixed(2))
        }, 0)
      }
    },
    dianji_daochu () {
      if (this.biaoyi_num == 0) {
        let date = ''
        if (this.biao_name == '访客记录') {
          date = this.url + 'export_gather_visit_plan?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '漏斗阶段') {
          date = this.url + 'export_gather_funn?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '合同') {
          date = this.url + 'export_gather_contract?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '发票') {
          date = this.url + 'export_gather_invoice?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '工单') {
          date = this.url + 'export_gather_dispatch?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '交付单') {
          date = this.url + 'export_gather_deliver?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '回款') {
          date = this.url + 'export_gather_received_cash?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        }
                
        this.$func.multiDownLoad([date])
      } else if (this.biaoyi_num == 1) {
        let date = ''
        if (this.biao_name == '访客记录') {
          date = this.url + 'export_detail_visit_plan?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '漏斗阶段') {
          date = this.url + 'export_detail_funn?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '合同') {
          date = this.url + 'export_detail_contract?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '发票') {
          date = this.url + 'export_detail_invoice?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '工单') {
          date = this.url + 'export_detail_dispatch?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '交付单') {
          date = this.url + 'export_detail_deliver?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        } else if (this.biao_name == '回款') {
          date = this.url + 'export_detail_received_cash?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        }
        this.$func.multiDownLoad([date])
      }
    },
    dianji_daochu2 () {
      if (this.biaoer_num == 0) {
        let date = ''
        if (this.biao_name == '访客记录') {
          date = this.url + 'export_gather_visit_record?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        }
                
        this.$func.multiDownLoad([date])
      } else if (this.biaoer_num == 1) {
        let date = ''
        if (this.biao_name == '访客记录') {
          date = this.url + 'export_detail_visit_record?ent_id=' + this.$ent_id() + '&excel_type=2&flow_status=4&user_id=' + this.$jichuxinxi().user_id + '&look_type=' + this.look_type
        }
        this.$func.multiDownLoad([date])
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../style/salesman.scss';
</style>

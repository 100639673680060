<template>
    <div class="kanban">
        <ul class="salesman_tit">
            <li v-for="(i,index) in tit_list" :key="index" @click="dangqian=index" :class="dangqian==index?'bei':''">{{i}}</li>
        </ul>
        <div class="kanban_box" v-if="dangqian==0">
            <div class="kanban_box_tit">
                <div><p><span @click="dianji_fanhui">销售管理</span><i class="el-icon-arrow-right"></i>销售看板</p>
                    <ul>
                        <li v-for="(i,index) in kanshei" :key="index" @click="dian_kanshei(i,index)" :class="kan_shei==index?'zise':''">{{i}}</li>
                    </ul>
                </div>
                <p class="kanban_sousuo"><i class="el-icon-search"></i><input type="text" v-model="sousuo_text" @keyup.enter="sousuo" placeholder="搜索"></p>
            </div>
            <div class="kanban_box_table">
                <el-table
                    :data="kanban_neirong_list"
                    :cell-style="liebiao"
                    height="7.5rem"
                    :header-cell-style="biaotou"
                    style="width: 100%">
                    <el-table-column
                        fixed
                        label="归档号"
                        width="110"
                        >
                        <template slot-scope="props">
                            {{props.row.file_no}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed
                        label="客户名称"
                        width="160"
                        >
                        <template slot-scope="props">
                            {{props.row.cust_name}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="决策人"
                        >
                        <template slot-scope="props">
                            {{props.row.contact}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="电话"
                        width="100"
                        >
                        <template slot-scope="props">
                            {{props.row.mobile!=undefined&&props.row.mobile.length!=0?props.row.mobile:'—'}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="负责人"
                        >
                        <template slot-scope="props">
                            {{props.row.staff_name}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="归档"
                        sortable
                        :sort-method="guidang_paixu"
                        >
                        <template slot-scope="props">
                            <el-tooltip class="item" effect="dark" :content="props.row.file_status==2?'已归档':'点击归档，则不可更改'" placement="bottom-start">
                                <p @click="kanshei.length!=0?dianji_guidang(props.row):wuyong()" :class="props.row.file_status==2?'green':'yellow'"></p>
                            </el-tooltip>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="建档时间"
                        width='180'
                        >
                        <template slot-scope="props">
                            {{props.row.file_time}}
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width='180'
                        >
                        <template slot-scope="props">
                            <p>{{props.row.days_visit}}天</p>
                            <div class="jiantou">
                                <p class="hengxian"></p>
                                <p class="el-icon-caret-right"></p>
                            </div>
                            <p>&nbsp;</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="访客记录"
                        width="130"
                        :render-header="ceshi_loudou"
                        >
                        <template slot-scope="props">
                            <p :class="Number(props.row.visit_num)>Number(fangke_jilu_rzhi)?'shandong_text':''">拜访{{props.row.visit_num}}次</p>
                            <!-- <el-tooltip class="item" effect="dark" :content="props.row.status_visit==2?'查看拜访详情':''" placement="bottom-start">
                            </el-tooltip> -->
                            <el-tooltip v-if="(kan_shei==0&&jilu_bianji)||shifou_have_daixie" placement="bottom-start" popper-class="liebiao_tishi" effect="light">
                                <!-- <el-button>Light</el-button> -->
                                <ul v-if="props.row.file_status!=2" slot="content">
                                    <li v-if="(props.row.staff_id==jichuxinxi.user_id)||shifou_have_daixie" @click="dianji_xinzeng(props.row,'453y6pLD52','访客记录')">新增</li>
                                    <!-- <li v-if="props.row.status_visit==2">编辑</li> -->
                                </ul>
                                <!--  @click="props.row.status_visit==2?dakai_youce_xiangqing(props.row.file_no,'访客'):''" -->
                                <p @click="dakai_youce_xiangqing(props.row,'访客')" :class="Number(props.row.visit_num)>Number(fangke_jilu_rzhi)?(props.row.status_visit==2?'green shandong':'red shandong'):(props.row.status_visit==2?'green':'red')"></p>
                            </el-tooltip>
                            <p v-if="(kan_shei!=0||!jilu_bianji)&&!shifou_have_daixie" @click="dakai_youce_xiangqing(props.row,'访客')" :class="Number(props.row.visit_num)>Number(fangke_jilu_rzhi)?(props.row.status_visit==2?'green shandong':'red shandong'):(props.row.status_visit==2?'green':'red')"></p>
                            <p>&nbsp;</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width='180'
                        >
                        <template slot-scope="props">
                            <p>{{props.row.days_funn}}天</p>
                            <div class="jiantou">
                                <p class="hengxian"></p>
                                <p class="el-icon-caret-right"></p>
                            </div>
                            <p>{{props.row.amt_funn}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="漏斗阶段"
                        width='130'
                        sortable
                        :sort-method="loudou_paixu"
                        :render-header="ceshi_loudou"
                        >
                        <template slot-scope="props">
                            <p :class="Number(props.row.days_funn)>Number(loudou_jieduan)?'shandong_text':''">{{props.row.stage_funn}}</p>
                            <el-tooltip v-if="(kan_shei==0&&loudou_bianji)||shifou_have_daixie" class="item" effect="light" popper-class="liebiao_tishi" placement="bottom-start">
                                <ul v-if="props.row.file_status!=2" slot="content">
                                    <li v-if="(props.row.have_funn==0||props.row.stage_funn=='赢单'||props.row.stage_funn=='输单')&&props.row.funn_all_stage==1&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&(props.row.funn_flow_status==undefined||props.row.funn_flow_status==4||props.row.funn_flow_status==7)" @click="dianji_xinzeng(props.row,'PLOQ9ku3Ip','漏斗单')">新增</li>
                                    <!--  v-if="props.row.status_funn==1" -->
                                    <li v-if="(props.row.have_funn==1&&((props.row.stage_funn!='赢单'&&props.row.stage_funn!='输单')||props.row.funn_flow_status==2||props.row.funn_flow_status==7)&&(jichuxinxi.user_id==props.row.staff_id||shifou_have_daixie))&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&(props.row.funn_flow_status==4||props.row.funn_flow_status==2||props.row.funn_flow_status==7)" @click="dianji_bianji(props.row,'PLOQ9ku3Ip','漏斗单')">编辑</li>
                                </ul>
                                <p @click="dakai_youce_xiangqing(props.row,'漏斗阶段')" :class="Number(props.row.days_funn)>Number(loudou_jieduan)?(props.row.status_funn==2?'green shandong':(props.row.status_funn==1?'yellow shandong':'red shandong')):(props.row.status_funn==2?'green':(props.row.status_funn==1?'yellow':'red'))"></p>
                            </el-tooltip>
                            <p @click="dakai_youce_xiangqing(props.row,'漏斗阶段')" v-if="(kan_shei!=0||!loudou_bianji)&&!shifou_have_daixie" :class="Number(props.row.days_funn)>Number(loudou_jieduan)?(props.row.status_funn==2?'green shandong':(props.row.status_funn==1?'yellow shandong':'red shandong')):(props.row.status_funn==2?'green':(props.row.status_funn==1?'yellow':'red'))"></p>
                            <p>&nbsp;</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width='180'
                        >
                        <template slot-scope="props">
                            <p>{{props.row.days_con}}天</p>
                            <div class="jiantou">
                                <p class="hengxian"></p>
                                <p class="el-icon-caret-right"></p>
                            </div>
                            <p>{{props.row.amt_con}}</p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="合同"
                        width='110'
                        sortable
                        :sort-method="hetong_paixu"
                        :render-header="ceshi_loudou"
                        >
                        <template slot-scope="props">
                            <el-tooltip v-if="(kan_shei==0&&hetong_bianji)||shifou_have_daixie" class="item" effect="light" popper-class="liebiao_tishi" placement="bottom-start">
                                <ul v-if="props.row.file_status!=2" slot="content">
                                    <li v-if="props.row.stage_funn!='输单'&&props.row.have_con==0&&(jichuxinxi.user_id==props.row.staff_id||shifou_have_daixie)" @click="dianji_xinzeng(props.row,'qIwxmsMTgv','合同单')">新增</li>
                                    <!--  v-if="props.row.status_con==1" -->
                                    <!-- <li v-if="props.row.have_con==1&&jichuxinxi.user_id==props.row.staff_id" @click="dianji_bianji(props.row,'qIwxmsMTgv','合同单')">编辑</li> -->
                                </ul>
                                <!--  @click="props.row.status_con==2||props.row.status_con==1?dakai_youce_xiangqing(props.row.file_no,'合同'):''" -->
                                <p @click="dakai_youce_xiangqing(props.row,'合同')" :class="Number(props.row.days_con)>Number(hetong)?(props.row.status_con==2?'green shandong':(props.row.status_con==1?'yellow shandong':'red shandong')):(props.row.status_con==2?'green':(props.row.status_con==1?'yellow':'red'))"></p>
                            </el-tooltip>
                            <p v-if="(kan_shei!=0||!hetong_bianji)&&!shifou_have_daixie" @click="dakai_youce_xiangqing(props.row,'合同')" :class="Number(props.row.days_con)>Number(hetong)?(props.row.status_con==2?'green shandong':(props.row.status_con==1?'yellow shandong':'red shandong')):(props.row.status_con==2?'green':(props.row.status_con==1?'yellow':'red'))"></p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width='180'
                        >
                        <template slot-scope="props">
                            <p>{{props.row.days_inv}}天</p>
                            <div class="jiantou">
                                <p class="hengxian"></p>
                                <p class="el-icon-caret-right"></p>
                            </div>
                            <p>
                                <span class="shoudao">{{props.row.amt_inv_yes}}</span>
                                <span class="weishou">{{props.row.amt_inv_no}}</span>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="发票"
                        width='110'
                        sortable
                        :sort-method="fapiao_paixu"
                        :render-header="ceshi_loudou"
                        >
                        <template slot-scope="props">
                            <el-tooltip v-if="(kan_shei==0&&fapiao_bianji)||shifou_have_daixie" class="item" effect="light"  popper-class="liebiao_tishi" placement="bottom-start">
                                <ul v-if="props.row.file_status!=2" slot="content">
                                    <li v-if="props.row.stage_funn!='输单'&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&props.row.file_status!=2" @click="dianji_xinzeng(props.row,'VMCizuVOXb','发票单')">新增</li>
                                    <!--  v-if="props.row.status_inv==1"  -->
                                    <!-- <li v-if="props.row.status_inv==1&&jichuxinxi.user_id==props.row.staff_id&&props.row.file_status!=2" @click="dianji_bianji(props.row,'VMCizuVOXb','发票单')">编辑</li> -->
                                </ul>
                                <!--  @click="props.row.status_inv==2||props.row.status_inv==1?dakai_youce_xiangqing(props.row.file_no,'发票'):''" -->
                                <p @click="dakai_youce_xiangqing(props.row,'发票')" :class="Number(props.row.days_inv)>Number(fapiao)?(props.row.status_inv==2?'green shandong':(props.row.status_inv==1?'yellow shandong':'red shandong')):(props.row.status_inv==2?'green':(props.row.status_inv==1?'yellow':'red'))"></p>
                            </el-tooltip>
                            <p v-if="(kan_shei!=0||!fapiao_bianji)&&!shifou_have_daixie" @click="dakai_youce_xiangqing(props.row,'发票')" :class="Number(props.row.days_inv)>Number(fapiao)?(props.row.status_inv==2?'green shandong':(props.row.status_inv==1?'yellow shandong':'red shandong')):(props.row.status_inv==2?'green':(props.row.status_inv==1?'yellow':'red'))"></p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width='180'
                        >
                        <template slot-scope="props">
                            <p>{{props.row.days_rec}}天</p>
                            <div class="jiantou">
                                <p class="hengxian"></p>
                                <p class="el-icon-caret-right"></p>
                            </div>
                            <p>
                                <span class="shoudao">{{props.row.amt_rec_yes}}</span>
                                <span class="weishou">{{props.row.amt_rec_no}}</span>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="回款"
                        width='110'
                        sortable
                        :sort-method="huikuan_paixu"
                        :render-header="ceshi_loudou"
                        >
                        <template slot-scope="props">
                            <el-tooltip v-if="(kan_shei==0&&huikuan_bianji)||shifou_have_daixie" class="item" effect="light"  popper-class="liebiao_tishi" placement="bottom-start">
                                <ul v-if="props.row.file_status!=2" slot="content">
                                    <li v-if="props.row.stage_funn!='输单'&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&props.row.file_status!=2" @click="dianji_xinzeng(props.row,'He5ln0bdDO','回款单')">新增</li>
                                    <!--  v-if="props.row.status_rec==1" -->
                                    <!-- <li v-if="props.row.status_rec==1&&jichuxinxi.user_id==props.row.staff_id&&props.row.file_status!=2" @click="dianji_bianji(props.row,'He5ln0bdDO','回款单')">编辑</li> -->
                                </ul>
                                <!--  @click="props.row.status_rec==2||props.row.status_rec==1?dakai_youce_xiangqing(props.row.file_no,'回款'):''" -->
                                <!-- status_rec -->
                                <p @click="dakai_youce_xiangqing(props.row,'回款')" :class="Number(props.row.days_rec)>Number(huikuan)?(props.row.status_rec==2?'green shandong':(props.row.status_rec==1?'yellow shandong':'red shandong')):(props.row.status_rec==2?'green':(props.row.status_rec==1?'yellow':'red'))"></p>
                            </el-tooltip>
                            <p v-if="(kan_shei!=0||!huikuan_bianji)&&!shifou_have_daixie" @click="dakai_youce_xiangqing(props.row,'回款')" :class="Number(props.row.days_rec)>Number(huikuan)?(props.row.status_rec==2?'green shandong':(props.row.status_rec==1?'yellow shandong':'red shandong')):(props.row.status_rec==2?'green':(props.row.status_rec==1?'yellow':'red'))"></p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label=""
                        width='180'
                        >
                        <template slot-scope="props">
                            <p>{{props.row.days_deliver}}天</p>
                            <div class="jiantou">
                                <p class="hengxian"></p>
                                <p class="el-icon-caret-right"></p>
                            </div>
                            <p>
                                <span class="shoudao">{{props.row.amt_deliver_yes}}</span>
                                <span class="weishou">{{props.row.amt_deliver_no}}</span>
                            </p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="交付单"
                        width='120'
                        sortable
                        :sort-method="jiaofu_paixu"
                        :render-header="ceshi_loudou"
                        >
                        <template slot-scope="props">
                            <el-tooltip v-if="(kan_shei==0&&jiaofu_bianji)||shifou_have_daixie" class="item" effect="light"  popper-class="liebiao_tishi" placement="bottom-start">
                                <ul v-if="props.row.file_status!=2" slot="content">
                                    <li v-if="props.row.stage_funn!='输单'&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&props.row.file_status!=2" @click="dianji_xinzeng(props.row,'H49d4edc3T','交付单')">新增</li>
                                    <!--  v-if="props.row.status_deliver==1" -->
                                    <!-- <li v-if="props.row.status_deliver==1&&jichuxinxi.user_id==props.row.staff_id&&props.row.file_status!=2" @click="dianji_bianji(props.row,'H49d4edc3T','交付单')">编辑</li> -->
                                </ul>
                                <!--  @click="props.row.status_deliver==2||props.row.status_deliver==1?dakai_youce_xiangqing(props.row.file_no,'交付单'):''" -->
                                <p @click="dakai_youce_xiangqing(props.row,'交付单')" :class="Number(props.row.days_deliver)>Number(jiaofu)?(props.row.status_deliver==2?'green shandong':(props.row.status_deliver==1?'yellow shandong':'red shandong')):(props.row.status_deliver==2?'green':(props.row.status_deliver==1?'yellow':'red'))"></p>
                            </el-tooltip>
                            <p v-if="(kan_shei!=0||!jiaofu_bianji)&&!shifou_have_daixie" @click="dakai_youce_xiangqing(props.row,'交付单')" :class="Number(props.row.days_deliver)>Number(jiaofu)?(props.row.status_deliver==2?'green shandong':(props.row.status_deliver==1?'yellow shandong':'red shandong')):(props.row.status_deliver==2?'green':(props.row.status_deliver==1?'yellow':'red'))"></p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="工单"
                        width='120'
                        :render-header="ceshi_loudou"
                        >
                        <template slot-scope="props">
                            <!-- <span class="fujian_red">派工单</span> -->
                            <el-tooltip v-if="(kan_shei==0&&paigong_bianji)||shifou_have_daixie" class="item" effect="light"  popper-class="liebiao_tishi" placement="bottom-start">
                                <ul  v-if="props.row.stage_funn!='输单'&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&props.row.file_status!=2" slot="content">
                                    <li @click="dianji_xinzeng(props.row,'Eb3n7CKQzi','派工单')">新增</li>
                                </ul>
                                <p @click="dakai_youce_xiangqing(props.row,'派工单')" :class="props.row.status_dispatch==2?'green':(props.row.status_dispatch==1?'yellow':'red')"></p>
                            </el-tooltip>
                            <p v-if="(kan_shei!=0||!paigong_bianji)&&!shifou_have_daixie" @click="dakai_youce_xiangqing(props.row,'派工单')" :class="props.row.status_dispatch==2?'green':(props.row.status_dispatch==1?'yellow':'red')"></p>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="附件"
                        width='150'
                        >
                        <template slot-scope="props">
                            <p>
                                <!-- <el-tooltip class="item" effect="light" :content="props.row.status_con_url==2?'下载合同':'请上传合同'" placement="top-start"> -->
                                    <span @click="props.row.stage_funn!='输单'&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&props.row.file_status!=2?dianji_xiazai(props.row,'合同'):wuyong()" :class="props.row.status_con_url==2?'fujian_green':'fujian_red'">合同</span>
                                <!-- </el-tooltip> -->
                                <!-- <el-tooltip class="item" effect="light" :content="props.row.status_inv_url==2?'下载发票':'请上传发票'" placement="top-end"> -->
                                    <span @click="props.row.stage_funn!='输单'&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&props.row.file_status!=2?dianji_xiazai(props.row,'发票'):wuyong()" :class="props.row.status_inv_url==2?'fujian_green':'fujian_red'">发票</span>
                                <!-- </el-tooltip> -->
                            </p>
                            <p>
                                <!-- <el-tooltip class="item" effect="light" :content="props.row.status_rec_url==2?'下载回款':'请上传回款'" placement="bottom-start"> -->
                                    <span @click="props.row.stage_funn!='输单'&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&props.row.file_status!=2?dianji_xiazai(props.row,'回款'):wuyong()" :class="props.row.status_rec_url==2?'fujian_green':'fujian_red'">回款</span>
                                <!-- </el-tooltip> -->
                                <!-- <el-tooltip class="item" effect="light" :content="props.row.status_deliver_url==2?'下载交付单':'请上传交付单'" placement="bottom-end"> -->
                                    <span @click="props.row.stage_funn!='输单'&&(props.row.staff_id==jichuxinxi.user_id||shifou_have_daixie)&&props.row.file_status!=2?dianji_xiazai(props.row,'交付'):wuyong()" :class="props.row.status_deliver_url==2?'fujian_green':'fujian_red'">交付单</span>
                                <!-- </el-tooltip> -->
                            </p>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <el-pagination
                @current-change="dianji_yeshu"
                :current-page="dangqian_yeshu"
                class="fenye_qi"
                layout="total, prev, pager, next, jumper"
                :total="dangqian_zongshu">
            </el-pagination>
        </div>
        <div class="kanban_box" v-if="dangqian==1">
            <table class="kanban_guize_table">
                <tr>
                    <th>项目</th>
                    <th>预警规则</th>
                </tr>
                <tr>
                    <td>访客记录日志</td>
                    <td>≤<input type="number" disabled  v-model="fangke_jilu_rzhi">次</td>
                </tr>
                <tr>
                    <td>漏斗阶段</td>
                    <td>≤<input type="number" disabled  v-model="loudou_jieduan">天</td>
                </tr>
                <tr>
                    <td>合同</td>
                    <td>≤<input type="number" disabled  v-model="hetong">天</td>
                </tr>
                <tr>
                    <td>发票</td>
                    <td>≤<input type="number" disabled  v-model="fapiao">天</td>
                </tr>
                <tr>
                    <td>未开发票</td>
                    <td>≤<input type="number" disabled  v-model="weikai_fapiao">天</td>
                </tr>
                <tr>
                    <td>回款</td>
                    <td>≤<input type="number"  disabled v-model="huikuan">天</td>
                </tr>
                <tr>
                    <td>应收金额</td>
                    <td>≤<input type="number" disabled  v-model="yingshou_jine">天</td>
                </tr>
                <tr>
                    <td>交付</td>
                    <td>≤<input type="number" disabled  v-model="jiaofu">天</td>
                </tr>
            </table>
            <!-- <p class="kanban_guize_baocun">
                <span @click="dianji_guize_baocun">保存</span>
            </p> -->
        </div>
        <div class="youce_xianshi" v-if="dianji_chuxian_youce" @click="dianji_chuxian_youce=false">
            <div class="youce_box" @click.stop>
                <p class="youce_box_tit">
                    <i> </i>
                    <i class="el-icon-close" @click="dianji_chuxian_youce=false"></i>
                </p>
                <div class="transition-box">
                    <div v-if="biao_name=='访客'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>访客计划单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.cust_name!==undefined">客户名称：{{it.cust_name}}</p>
                            <p v-if="it.operate_time!==undefined">提交时间：{{it.operate_time}}</p>
                            <p v-if="it.appr_time!==undefined">提时间：{{it.appr_time}}</p>
                            <p v-if="it.file_no!==undefined">归档号：{{it.file_no}}</p>
                            <p v-if='it.staff_name!==undefined'>负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.visit_type!==undefined">拜访方式：{{it.visit_type}}</p>
                            <p v-if="it.visit_time!==undefined">拜访时间：{{it.visit_time}}</p>
                            <p v-if="it.contact!==undefined">决策人：{{it.contact}}</p>
                            <p v-if="it.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(it.current_stage)}}</p>
                            <p v-if="it.next_stage!==undefined">预计下一漏斗阶段：{{jieduan_hanzi(it.next_stage)}}</p>
                            <p v-if="it.remarks!==undefined">备注：{{it.remarks}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <div v-if="biao_name=='访客'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing2" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>访客记录单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.cust_name!==undefined">客户名称：{{it.cust_name}}</p>
                            <p v-if="it.operate_time!==undefined">提交时间：{{it.operate_time}}</p>
                            <p v-if="it.appr_time!==undefined">通过时间：{{it.appr_time}}</p>
                            <p v-if="it.file_no!==undefined">归档号：{{it.file_no}}</p>
                            <p v-if='it.staff_name!==undefined'>负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.start_time!==undefined">拜访开始时间：{{it.start_time}}</p>
                            <p v-if="it.end_time!==undefined">拜访结束时间：{{it.end_time}}</p>
                            <p v-if="it.hour_long!==undefined">时长：{{it.hour_long}}小时</p>
                            <p v-if="it.current_stage!==undefined">漏斗现阶段：{{jieduan_hanzi(it.current_stage)}}</p>
                            <p v-if="it.funn_stage_change_flag!==undefined">漏斗阶段变化：{{it.funn_stage_change_flag}}</p>
                            <p v-if="it.funn_stage!==undefined">漏斗达成阶段：{{jieduan_hanzi(it.funn_stage)}}</p>
                            <p v-if="it.change_cause!==undefined">阶段变化原因：{{it.change_cause}}</p>
                            <p v-if="it.remarks!==undefined">备注：{{it.remarks}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>

                    </div>
                    <div v-if="biao_name=='漏斗阶段'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>漏斗单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.cust_name!==undefined">客户名称：{{it.cust_name}}</p>
                            <p v-if="it.operate_time!==undefined">提交时间：{{it.operate_time}}</p>
                            <p v-if="it.appr_time!==undefined">通过时间：{{it.appr_time}}</p>
                            <p v-if="it.file_no!==undefined">归档号：{{it.file_no}}</p>
                            <p v-if='it.staff_name!==undefined'>负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <ul>
                                <li v-for="(i,index) in it.prod_detail" :key="index" style="border-top:0.01rem solid #ccc;">
                                    <p>产品明细({{index+1}})</p>
                                    <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                    <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                    <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                    <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                    <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                    <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                    <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                    <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                    <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                    <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                    <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                </li>
                            </ul>
                            <p v-if="it.stage_funn!==undefined">漏斗阶段：{{jieduan_hanzi(it.stage_funn)}}</p>
                            <p v-if="it.amt_funn!==undefined">漏斗金额：{{it.amt_funn}}</p>
                            <p v-if="it.date_con_est!==undefined">预计合同日期：{{it.date_con_est}}</p>
                            <p v-if="it.rate_funn_disc!==undefined">漏斗折算比例：{{it.rate_funn_disc}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <div v-if="biao_name=='合同'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>合同单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.cust_name!==undefined">客户名称：{{it.cust_name}}</p>
                            <p v-if="it.operate_time!==undefined">提交时间：{{it.operate_time}}</p>
                            <p v-if="it.appr_time!==undefined">通过时间：{{it.appr_time}}</p>
                            <p v-if="it.file_no!==undefined">归档号：{{it.file_no}}</p>
                            <p v-if='it.staff_name!==undefined'>负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.date_con!==undefined">合同日期：{{it.date_con}}</p>
                            <p v-if="it.belong_to_organ!==undefined">所属公司：{{it.belong_to_organ}}</p>
                            <p v-if="it.industry!==undefined">行业：{{it.industry}}</p>
                            <p v-if="it.con_type!==undefined">合同类型：{{it.con_type}}</p>
                            <p v-if="it.con_valid_term!==undefined">合同有效时间：{{it.con_valid_term}}</p>
                            <ul>
                                <li v-for="(i,index) in it.prod_list" :key="index" style="border-top:0.01rem solid #ccc;">
                                    <p>产品明细({{index+1}})</p>
                                    <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                    <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                    <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                    <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                    <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                    <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                    <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                    <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                    <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                    <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                    <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                </li>
                            </ul>
                            <p v-if="it.amt_con!==undefined">合同金额：{{it.amt_con}}</p>
                            <ul>
                                <li v-for="(i,index) in it.amt_list" :key="index" style="border-top:0.01rem solid #ccc;">
                                    <p>开票({{index+1}})</p>
                                    <p v-if="i.date_inv_est!==undefined">预计发票日期：{{i.date_inv_est}}</p>
                                    <p v-if="i.amt_inv_est!==undefined">预计发票金额：{{i.amt_inv_est}}</p>
                                </li>
                            </ul>
                            <p v-if="it.amt_con!==undefined">预计发票金额合计：{{it.amt_con}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <div v-if="biao_name=='发票'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>发票单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.cust_name!==undefined">客户名称：{{it.cust_name}}</p>
                            <p v-if="it.operate_time!==undefined">提交时间：{{it.operate_time}}</p>
                            <p v-if="it.appr_time!==undefined">通过时间：{{it.appr_time}}</p>
                            <p v-if="it.file_no!==undefined">归档号：{{it.file_no}}</p>
                            <p v-if='it.staff_name!==undefined'>负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.belong_to_organ!==undefined">所属公司：{{it.belong_to_organ}}</p>
                            <p v-if="it.date_inv!==undefined">发票日期：{{it.date_inv}}</p>
                            <p v-if="it.inv_type!==undefined">发票类型：{{it.inv_type}}</p>
                            <ul>
                                <li v-for="(i,index) in it.prod_list" :key="index" style="border-top:0.01rem solid #ccc;">
                                    <p>发票内容({{index+1}})</p>
                                    <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                    <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                    <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                    <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                    <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                    <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                    <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                    <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                    <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                    <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                    <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                    <p v-if="i.price_unit_no_tax!==undefined">不含税折后单价：{{i.price_unit_no_tax}}</p>
                                    <p v-if="i.amt_no_tax!==undefined">不含税金额：{{i.amt_no_tax}}</p>
                                    <p v-if="i.rate_tax!==undefined">税率：{{i.rate_tax}}%</p>
                                    <p v-if="i.amt_tax!==undefined">税额：{{i.amt_tax}}</p>
                                    <p v-if="i.amt_inv_detail!==undefined">发票金额：{{i.amt_inv_detail}}</p>
                                </li>
                            </ul>
                            <p v-if="it.amt_no_tax_sum!==undefined">合计不含税金额：{{it.amt_no_tax_sum}}</p>
                            <p v-if="it.amt_tax_sum!==undefined">合计税额：{{it.amt_tax_sum}}</p>
                            <p v-if="it.amt_inv!==undefined">合计发票金额：{{it.amt_inv}}</p>
                            <p v-if="it.amt_inv_sum!==undefined">累计发票金额：{{it.amt_inv_sum}}</p>
                            <p v-if="it.amt_inv_un!==undefined">合同未开发票金额：{{it.amt_inv_un}}</p>
                            <ul>
                                <li v-for="(i,index) in it.amt_list" :key="index" style="border-top:0.01rem solid #ccc;">
                                    <p>回款({{index+1}})</p>
                                    <p v-if="i.date_rec_est!==undefined">预计回款日期：{{i.date_rec_est}}</p>
                                    <p v-if="i.amt_rec_est!==undefined">预计回款金额：{{i.amt_rec_est}}</p>
                                </li>
                            </ul>
                            <p v-if="it.cust_name!==undefined">客户：{{it.cust_name}}</p>
                            <p v-if="it.tax_no!==undefined">纳税识别号：{{it.tax_no}}</p>
                            <p v-if="it.mobile!==undefined">电话：{{it.mobile}}</p>
                            <p v-if="it.addr!==undefined">地址：{{it.addr}}</p>
                            <p v-if="it.bank!==undefined">开户行：{{it.bank}}</p>
                            <p v-if="it.bank_acct!==undefined">开户行账号：{{it.bank_acct}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <div v-if="biao_name=='回款'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>回款单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.cust_name!==undefined">客户名称：{{it.cust_name}}</p>
                            <p v-if="it.operate_time!==undefined">提交时间：{{it.operate_time}}</p>
                            <p v-if="it.appr_time!==undefined">通过时间：{{it.appr_time}}</p>
                            <p v-if="it.file_no!==undefined">归档号：{{it.file_no}}</p>
                            <p v-if='it.staff_name!==undefined'>负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.date_rec!==undefined">回款日期：{{it.date_rec}}</p>
                            <p v-if="it.inv_code!==undefined">发票代码：{{it.inv_code}}</p>
                            <p v-if="it.inv_no!==undefined">发票号码：{{it.inv_no}}</p>
                            <ul>
                                <li v-for="(i,index) in it.prod_detail" :key="index" style="border-top:0.01rem solid #ccc;">
                                    <p>产品回款({{index+1}})</p>
                                    <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                    <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                    <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                    <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                    <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                    <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                    <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                    <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                    <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                    <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                    <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                </li>
                            </ul>
                            <p v-if="it.amt_rec!==undefined">回款金额：{{it.amt_rec}}</p>
                            <p v-if="it.amt_rec_sum!==undefined">累计回款金额：{{it.amt_rec_sum}}</p>
                            <p v-if="it.amt_inv_sum!==undefined">累计开票金额：{{it.amt_inv_sum}}</p>
                            <p v-if="it.amt_rec_unrec!==undefined">应收金额：{{it.amt_rec_unrec}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <div v-if="biao_name=='交付单'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>交付单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.cust_name!==undefined">客户名称：{{it.cust_name}}</p>
                            <p v-if="it.operate_time!==undefined">提交时间：{{it.operate_time}}</p>
                            <p v-if="it.appr_time!==undefined">通过时间：{{it.appr_time}}</p>
                            <p v-if="it.file_no!==undefined">归档号：{{it.file_no}}</p>
                            <p v-if='it.staff_name!==undefined'>负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.date_deliver!==undefined">日期：{{it.date_deliver}}</p>
                            <ul>
                                <li v-for="(i,index) in it.prod_detail" :key="index" style="border-top:0.01rem solid #ccc;">
                                    <p>产品明细({{index+1}})</p>
                                    <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                    <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                    <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                    <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                    <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                    <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                    <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                    <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                    <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                    <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                </li>
                            </ul>
                            <p v-if="it.amt_deliver!==undefined">交付金额：{{it.amt_deliver}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <div v-if="biao_name=='派工单'">
                        <div class="transition-box_con" v-for="(it,index) in youce_xiangqing" :key="index" style="border-top:0.04rem solid #ccc;">
                            <p>派工单({{index+1}})<span v-if="it.flag_cancel==1">作废单</span></p>
                            <p class="zhuangtai" :style="it.flow_status==4?'color:#67C23A':'color:#F56C6C'">{{tongguo_jujue(it.flow_status)}}</p>
                            <p v-if="it.cust_name!==undefined">客户名称：{{it.cust_name}}</p>
                            <p v-if="it.operate_time!==undefined">提交时间：{{it.operate_time}}</p>
                            <p v-if="it.appr_time!==undefined">通过时间：{{it.appr_time}}</p>
                            <p v-if="it.file_no!==undefined">归档号：{{it.file_no}}</p>
                            <p v-if='it.staff_name!==undefined'>负责人：{{it.staff_name}}</p>
                            <p v-if="it.dept_name!==undefined">部门：{{it.dept_name}}</p>
                            <p v-if="it.help_order_flag!==undefined">是否为助单行为：{{it.help_order_flag}}</p>
                            <p v-if="it.disp_staff_name!==undefined">派工人员：{{it.disp_staff_name}}</p>
                            <p v-if="it.help_order_type!==undefined">助单类型：{{it.help_order_type}}</p>
                            <p v-if="it.biz_trip_type!==undefined">出差类型：{{it.biz_trip_type}}</p>
                            <p v-if="it.addr!==undefined">地址：{{it.addr}}</p>
                            <p v-if="it.contact!==undefined">决策人：{{it.contact}}</p>
                            <p v-if="it.mobile!==undefined">手机：{{it.mobile}}</p>
                            <p v-if="it.trip_start_time!==undefined">开始时间：{{it.trip_start_time}}</p>
                            <p v-if="it.trip_end_time!==undefined">结束时间：{{it.trip_end_time}}</p>
                            <p v-if="it.hour_long!==undefined">时长：{{it.hour_long}}小时</p>
                            <ul>
                                <li v-for="(i,index) in it.prod_detail" :key="index" style="border-top:0.01rem solid #ccc;">
                                    <p>产品明细({{index+1}})</p>
                                    <p v-if="i.prod_name!==undefined">产品：{{i.prod_name}}</p>
                                    <p v-if="i.prod_code!==undefined">产品编号：{{i.prod_code}}</p>
                                    <p v-if="i.cat_name!==undefined">类型：{{i.cat_name}}</p>
                                    <p v-if="i.spec!==undefined">规格：{{i.spec}}</p>
                                    <p v-if="i.type!==undefined">型号：{{i.type}}</p>
                                    <p v-if="i.unit!==undefined">单位：{{i.unit}}</p>
                                    <p v-if="i.price_unit!==undefined">单价：{{i.price_unit}}元</p>
                                    <p v-if="i.qty!==undefined">数量：{{i.qty}}</p>
                                    <p v-if="i.price_orig!==undefined">原价：{{i.price_orig}}元</p>
                                    <p v-if="i.disc_rate!==undefined">折扣率：{{i.disc_rate}}</p>
                                    <p v-if="i.price_sale!==undefined">售价：{{i.price_sale}}</p>
                                    <p v-if="i.disp_item!==undefined">派工项目：{{i.disp_item}}</p>
                                </li>
                            </ul>
                            <p v-if="it.remarks!==undefined">备注：{{it.remarks}}</p>
                            <ul>
								<li v-for="(i,index) in it.zidingyi" :key="index">
									<!-- <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p> -->
									<div v-if="i.type=='ddattachment'">
                                        <p v-if="i.fujian_list!=undefined&&i.fujian_list.length!=0">附件：</p>
										<ul>
											<li class="keyi_dianji" v-for="(ita,inde) in i.fujian_list" :key="inde">
												<el-tooltip class="item" effect="dark" content="点击下载查看" placement="top">
													<p class="fujian_xiazai_kuang" @click="dianji_xiazai_fujian(ita)">
														<i class="el-icon-paperclip" style="margin-right:0.1rem;"></i>
														{{ita.name}}
													</p>
												</el-tooltip>
											</li>
										</ul>
									</div>
									<!-- 关联审批单 -->
									<div class="rqi_kuang_xiangqiang" v-if="i.type=='assappform'">
                                        <p>{{i.name}}：</p>
										<ul>
											<li class="keyi_dianji" @click="biaodan_xiangqing(ita)" v-for="(ita,inde) in i.liebiao" :key="inde">
												<p class="guanlian_kuang">
													<i class="el-icon-connection" style="margin-right:0.1rem;"></i>
													{{ita.flow_desc}}:{{ita.start_time}}
												</p>
											</li>
										</ul>
										<BiaodanXiangqing ref='biaodan_xiangqing_zhanshi'></BiaodanXiangqing>
									</div>
									<!-- <div v-if="i.type=='province'" @click="ceshi_diqu2(i)" class="rqi_kuang_xiangqiang">
										{{i.name}}：
										<v-distpicker @selected="onSelected2" :province='i.value_id' :city='i.value_ids' :area='i.value_idss'></v-distpicker>
									</div> -->
									<div v-if="i.type=='tablefield'">
										<div v-for="(ita,inde) in i.mingxi_list" :key="inde">
											{{i.name+'('+(inde+1)+')'}}：
											<div v-for="(ite,ind) in ita.mingxi_list" :key="ind">
												<p v-if="ite.type=='ddphotofield'">{{ite.name}}：<img @click="tupian_dianji(ite.img_url)" class="tupian_kuang" :src="ite.img_url" alt=""></p>
												<p v-if="ite.type!='ddattachment'&&ite.type!='ddphotofield'&&ite.type!='assappform'&&ite.type!='product'&&ite.value!=undefined&&ite.value.length!=0">
													{{ite.name}}：{{ite.value}}
												</p>
												<div v-if="ite.type=='product'&&ite.value!=undefined&&ite.value.length!=0">
													<p>{{ite.name}}：{{ite.value}}</p>
													<p>产品编号：{{ite.liebiao[0].prod_code}}</p>
													<p>产品类型：{{ite.liebiao[0].cat_name}}</p>
													<p>规格：{{ite.liebiao[0].spec}}</p>
													<p>型号：{{ite.liebiao[0].type}}</p>
													<p>单位：{{ite.liebiao[0].unit}}</p>
												</div>
											</div>
										</div>
									</div>
									<p v-if="i.type=='ddphotofield'&&i.img_url!=undefined&&i.img_url.length!=0">{{i.name}}：<img @click="tupian_dianji(i.img_url)" class="tupian_kuang" :src="i.img_url" alt=""></p>
									<p v-if="i.type!='ddattachment'&&i.type!='ddphotofield'&&i.type!='assappform'&&i.type!='tablefield'&&i.type!='product'&&i.value!=undefined&&i.value.length!=0">
										{{i.name}}：{{i.value}}
									</p>
									<div v-if="i.type=='product'&&i.value!=undefined&&i.value.length!=0">
										<p>{{i.name}}：{{i.value}}</p>
										<p>产品编号：{{i.liebiao[0].prod_code}}</p>
										<p>产品类型：{{i.liebiao[0].cat_name}}</p>
										<p>规格：{{i.liebiao[0].spec}}</p>
										<p>型号：{{i.liebiao[0].type}}</p>
										<p>单位：{{i.liebiao[0].unit}}</p>
									</div>
								</li>
							</ul>
                        </div>
                    </div>
                    <!-- <ul>
                        <li v-for="(i,index) in youce_xiangqing.extra" :key="index">
                            <p v-if="i.value.length!=0">{{i.name}}：{{i.value}}</p>
                        </li>
                    </ul> -->
                </div>
            </div>
        </div>
        <div class="beijing" v-if="shifou" @click="shifou=false">
            <div class="beijing_box2" @click.stop>
                <!-- <p class="box_con">您当前流程暂未编辑，请编辑<router-link to='/appsett'>审批设置</router-link></p> -->
                <p class="box_con"><span>{{danzi_name}}</span>尚未设置审批流程，请联系管理员进行流程设置。</p>
                <p class="foot">
                    <!-- <span @click="shifou=false">取消</span> -->
                    <span @click="shifou=false">我知道了</span>
                    <!-- <router-link to='/appsett'>确定</router-link> -->
                </p>
            </div>
        </div>
        <kanbanLiebiao ref='kanban_liebiao'></kanbanLiebiao>
		<tupian ref="bigphote"></tupian>
    </div>
</template>

<script>
import { query_all_module_sale_userid, query_form_replace_write, query_all_module_read_power_list, query_form_use_power_list, update_sale_mng_board_file_status, query_rel_customer_list, query_form_list_file_nos, query_user_info, query_customer_detail, query_flow_info_all, query_flow_form_have_set, query_form_list, generate_sale_mng_board, query_sale_mng_warn_rule, insert_sale_mng_warn_rule, import_dept } from '../../api/api.js'
import tongxun from './tongxun'
import kanbanLiebiao from './Kanbanlist'
import BiaodanXiangqing from '../../components/biaodan/biaodanxq'
import tupian from '../../components/bigphote/bigphote'
export default {
  name: 'kanban',
  data () {
    return {
      tit_list: ['销售看板', '预警规则'],
      dangqian: '0',
      kanban_neirong_list: [],
      dianji_chuxian_youce: false,
      fangke_jilu_rzhi: '7',
      loudou_jieduan: '60',
      hetong: '14',
      fapiao: '45',
      weikai_fapiao: '45',
      huikuan: '60',
      yingshou_jine: '60',
      jiaofu: '15',
      sousuo_text: '',
      youce_xiangqing: '',
      youce_xiangqing2: '',
      biao_name: '',
      quanxian: '',
      danzi_name: '',
      shifou: false,
      kanshei: [],
      kan_shei: '0',
      jichuxinxi: '',
      quanbu_kejian: false,
      shifou_bianji: false,
      dangqian_yeshu: 1,
      dangqian_zongshu: 0,
      jilu_bianji: false,
      loudou_bianji: false,
      paigong_bianji: false,
      hetong_bianji: false,
      fapiao_bianji: false,
      huikuan_bianji: false,
      jiaofu_bianji: false,
      jichuxinxi: {},
      shifou_have_daixie: false // 自己是否有代写功能
    }
  },
  mounted () {
  },
  components: { kanbanLiebiao, BiaodanXiangqing, tupian },
  watch: {
    dangqian_yeshu () {
      this.liebiao_jiekou()
    }
    // dangqian(){
    //     if(this.dangqian==0){
    //         this.jichu()
    //     }else{
    //         this.chaxun_guize()
    //     }
    // }
  },
  created () {
    this.jichuxinxi = this.$jichuxinxi()
    this.chushi_chaxun()
    sessionStorage.removeItem('mlbb_one_shezhi')
    sessionStorage.removeItem('mlbb_four_shezhi')
    sessionStorage.removeItem('mlbb_shenpi_zhengyi')
    sessionStorage.removeItem('mlbb_biaodan_sheji')
    sessionStorage.removeItem('mlbb_liucheng_shezhi')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_flowPermission')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_tableId')
    sessionStorage.removeItem('mlbb_liucheng_shezhi_list')

    sessionStorage.removeItem('mlbb_shenpi_biaodan')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_2')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_3')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_4')
    sessionStorage.removeItem('mlbb_shenpi_biaodan_5')
    this.quanxian_jiekou()
    this.jichu()
    // this.ceshi_sishuju()
  },
  methods: {
    chushi_chaxun () { // 查询他有没有代写权限
      query_form_replace_write({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          const _this = this
          console.log(date)
          if (date != undefined && date != null && date.length != 0) {
            date.forEach(item => {
              if (item.staff_id == _this.$jichuxinxi().user_id) {
                _this.shifou_have_daixie = true
              }
            })
          } else {
            this.shifou_have_daixie = false
          }
        } else if (res.data.code == 500) {}
      })
    },
    jianting () {
      if (this.$route.fullPath === '/kanban' || this.$route.fullPath === '/importexport_xiaoshou') {
        this.dangqian = 0
      }
    },
    quanxian_jiekou () {
      this.kanshei = []
      query_user_info({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          if (res.data.body.dept_manager == 1 || res.data.body.staff_level == 1 || res.data.body.staff_level == 2) {
            this.kanshei = ['看我', '看员工', '看全部']
          } else {
            query_all_module_read_power_list({
              data: {
                ent_id: this.$ent_id(),
                user_id: this.$jichuxinxi().user_id
              }
            }).then(kan_zong => {
              console.log(kan_zong)
              if (kan_zong.data.code == 200) {
                const zong = JSON.parse(kan_zong.data.body.data)
                console.log(zong)
                if (zong.length != 0) {
                  this.kanshei = ['看我', '看员工', '看全部']
                } else {
                  query_all_module_sale_userid({
                    data: {
                      ent_id: this.$ent_id(),
                      read_flag: '1'
                    }
                  }).then(xiaoshou_all => {
                    console.log(xiaoshou_all)
                    if (xiaoshou_all.data.code == 200) {
                      const date = JSON.parse(xiaoshou_all.data.body.data)
                      if (date != null) {
                        console.log(date)
                        let ids = []
                        if (date.staff_ids != undefined) {
                          ids = date.staff_ids.split(',')
                        }
                        console.log(ids)

                        ids.forEach(item => {
                          if (item == this.$jichuxinxi().user_id) {
                            this.kanshei = ['看我', '看员工', '看全部']
                          }
                        })
                      }
                    } else if (xiaoshou_all.data.code == 500) {}
                  })
                }
              } else if (kan_zong.data.code == 500) {}
            })
          }
        }
      })
    },
    // 基础值
    jichu () {
      this.jichuxinxi = this.$jichuxinxi()
      // this.quanxian=''
      // if(this.$func.quanxian()==='0'){
      //     this.quanxian='0'
      // }else{
      //     let data=this.$func.quanxian()
      //     this.quanxian=data.map((item,index)=>{if(item.module_id==2){return item.read_only}})
      // }
      // query_user_info({data:{
      //     ent_id:this.$ent_id(),
      //     user_id:this.$jichuxinxi().user_id
      // }}).then(res=>{
      //     console.log(res)
      //     if(res.data.code==200){
      //         if(res.data.body.staff_level==1){
      //             this.zhu_guanli_yuan=true
      //             // this.shifou_bianji=true
      //         }else if(res.data.body.dept_manager==1){
      //             this.zhu_guanli_yuan=true
      //         }else{
      query_form_use_power_list({
        data: {
          ent_id: this.$ent_id(),
          user_id: this.$jichuxinxi().user_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const cuowu = []
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.forEach(item => {
            item.children.forEach(it => {
              if (it.flow_id == '453y6pLD52') {
                this.jilu_bianji = true
              } else if (it.flow_id == 'PLOQ9ku3Ip') {
                this.loudou_bianji = true
              } else if (it.flow_id == 'Eb3n7CKQzi') {
                this.paigong_bianji = true
              } else if (it.flow_id == 'qIwxmsMTgv') {
                this.hetong_bianji = true
              } else if (it.flow_id == 'VMCizuVOXb') {
                this.fapiao_bianji = true
              } else if (it.flow_id == 'He5ln0bdDO') {
                this.huikuan_bianji = true
              } else if (it.flow_id == 'H49d4edc3T') {
                this.jiaofu_bianji = true
              }
            })
          })
          // this.title=[
          //     {
          //         name:'销售',
          //         children:[]
          //     }
          // ]
          // for(let i=0;i<date.length;i++){
          //     if(date[i].flow_group_id==1){
          //         for(let a=0;a<this.title.length;a++){
          //             if(this.title[a].name=='销售'){
          //                 if(date[i].flow_id=='7TvJHPyovM'){

          //                 }else if(date[i].flow_id=='Z7VDOK9HGu'){
          //                     cuowu.push('111')
          //                 }else if(date[i].flow_id=='453y6pLD52'){
          //                     this.jilu_bianji=true
          //                 }else if(date[i].flow_id=='PLOQ9ku3Ip'){
          //                     this.loudou_bianji=true
          //                 }else if(date[i].flow_id=='Eb3n7CKQzi'){
          //                     this.paigong_bianji=true
          //                 }else if(date[i].flow_id=='qIwxmsMTgv'){
          //                     this.hetong_bianji=true
          //                 }else if(date[i].flow_id=='VMCizuVOXb'){
          //                     this.fapiao_bianji=true
          //                 }else if(date[i].flow_id=='He5ln0bdDO'){
          //                     this.huikuan_bianji=true
          //                 }else   if(date[i].flow_id=='cvRyWGiUQH'){
          //                 }else  if(date[i].flow_id=='GBh7N2jMsi'){
          //                 }else if(date[i].flow_id=='H49d4edc3T'){
          //                     this.jiaofu_bianji=true
          //                 }
          //             }
          //         }
          //     }
          // }
          // if(cuowu.length==8){
          //     this.shifou_bianji=true
          // }else{

          // }
        } else if (res.data.code == 500) {}
      })
      //         }
      //     }else if(res.data.code==500){}
      // })

      this.jianting()
      this.liebiao_jiekou()
      this.chaxun_guize()
    },
    liebiao_jiekou () {
      generate_sale_mng_board({
        data: {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length == 0 ? null : this.sousuo_text,
          user_id: this.$jichuxinxi().user_id,
          look_type: this.kan_shei == 0 ? '2' : (this.kan_shei == '1' ? '1' : '3'),
          page_size: '10',
          page_index: this.dangqian_yeshu + ''
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.list.length != 0) {
            date.list.map((item, index) => {
              item.stage_funn = this.jieduan_hanzi(item.stage_funn)
              item.amt_con = (item.amt_con != undefined ? Number(item.amt_con).toFixed(0) : '0') + '元'
              item.amt_inv_yes = (item.amt_inv_yes != undefined ? Number(item.amt_inv_yes).toFixed(0) : '0') + '元'
              item.amt_inv_no = (item.amt_inv_no != undefined ? Number(item.amt_inv_no).toFixed(0) : '0') + '元'
              item.amt_rec_yes = (item.amt_rec_yes != undefined ? Number(item.amt_rec_yes).toFixed(0) : '0') + '元'
              item.amt_rec_no = (item.amt_rec_no != undefined ? Number(item.amt_rec_no).toFixed(0) : '0') + '元'
              item.amt_funn = (item.amt_funn != undefined ? Number(item.amt_funn).toFixed(0) : '0') + '元'
              item.amt_deliver_yes = (item.amt_deliver_yes != undefined ? Number(item.amt_deliver_yes).toFixed(0) : '0') + '元'
              item.amt_deliver_no = (item.amt_deliver_no != undefined ? Number(item.amt_deliver_no).toFixed(0) : '0') + '元'
              item.days_visit = item.days_visit != undefined ? item.days_visit : '0'
              item.visit_num = item.visit_num != undefined ? item.visit_num : '0'
              item.days_con = item.days_con != undefined ? item.days_con : '0'
              item.days_funn = item.days_funn != undefined ? item.days_funn : '0'
              item.days_inv = item.days_inv != undefined ? item.days_inv : '0'
              item.days_deliver = item.days_deliver != undefined ? item.days_deliver : '0'
              item.days_rec = item.days_rec != undefined ? item.days_rec : '0'
            })
            this.dangqian_zongshu = date.total
            this.kanban_neirong_list = date.list
          } else {
            this.dangqian_zongshu = 0
            this.kanban_neirong_list = []
          }
        } else if (res.data.code == 500) {}
      })
    },
    dianji_yeshu (val) {
      this.dangqian_yeshu = val
    },
    ceshi_sishuju () {
      this.kanban_neirong_list = [
        {
          // 归档号
          file_no: '2020021001',

          // 客户名称
          cust_name: '北京建筑设计研究院一分',

          // 决策人
          contact: '研一',

          // 建档时间
          file_time: '2020-02-10',

          // 负责人

          staff_name: '王梦颖',

          // 访客记录--天数
          days_visit: '15',

          // 访客记录--次数
          visit_num: '7',

          // 访客记录--状态
          status_visit: '2',

          // 漏斗--天数
          days_funn: '15',

          // 漏斗--金额
          amt_funn: '1,000,000元',

          // 漏斗--阶段
          stage_funn: '赢单',

          // 漏斗--状态
          status_funn: '2',

          // 合同--天数
          days_con: '1',

          // 合同--金额
          amt_con: '600,000元',

          // 合同--状态
          status_con: '2',

          // 发票--天数
          days_inv: '5',

          // 发票--已开发票金额
          amt_inv_yes: '600,000元',

          // 发票--未开发票金额
          amt_inv_no: '0元',

          // 发票--状态
          status_inv: '2',

          // 回款--天数
          days_rec: '10',

          // 回款--已回款金额
          amt_rec_yes: '600,000元',

          // 回款--未回款金额
          amt_rec_no: '0元',

          // 回款--状态
          status_rec: '2',

          // 交付单--天数
          days_deliver: '1',

          // 交付单--已交付金额
          amt_deliver_yes: '600,000元',

          // 交付单--未交付金额
          amt_deliver_no: '0元',

          // 交付单--状态
          status_deliver: '2',

          // 归档状态
          file_status: '2',

          // 合同附件 状态 1有，0无
          status_con_url: '1',

          // 交付单附件 状态 1有，0无
          status_deliver_url: '1',

          // 发票单附件 状态 1有，0无
          status_inv_url: '1',

          // 回款单附件 状态 1有，0无
          status_rec_url: '1'
        },
        {
          // 归档号
          file_no: '2020021001-1',

          // 客户名称
          cust_name: '北京建筑设计研究院一分',

          // 决策人
          contact: '研一',

          // 建档时间
          file_time: '2020-02-10',

          // 负责人

          staff_name: '王梦颖',

          // 访客记录--天数
          days_visit: '3',

          // 访客记录--次数
          visit_num: '1',

          // 访客记录--状态
          status_visit: '2',

          // 漏斗--天数
          days_funn: '15',

          // 漏斗--金额
          amt_funn: '50,000元',

          // 漏斗--阶段
          stage_funn: '需求确认',

          // 漏斗--状态
          status_funn: '1',

          // 合同--天数
          days_con: '0',

          // 合同--金额
          amt_con: '0元',

          // 合同--状态
          status_con: '0',

          // 发票--天数
          days_inv: '0',

          // 发票--已开发票金额
          amt_inv_yes: '0元',

          // 发票--未开发票金额
          amt_inv_no: '0元',

          // 发票--状态
          status_inv: '0',

          // 回款--天数
          days_rec: '0',

          // 回款--已回款金额
          amt_rec_yes: '0元',

          // 回款--未回款金额
          amt_rec_no: '0元',

          // 回款--状态
          status_rec: '0',

          // 交付单--天数
          days_deliver: '0',

          // 交付单--已交付金额
          amt_deliver_yes: '0元',

          // 交付单--未交付金额
          amt_deliver_no: '0元',

          // 交付单--状态
          status_deliver: '0',

          // 归档状态
          file_status: '0',

          // 合同附件 状态 1有，0无
          status_con_url: '0',

          // 交付单附件 状态 1有，0无
          status_deliver_url: '0',

          // 发票单附件 状态 1有，0无
          status_inv_url: '0',

          // 回款单附件 状态 1有，0无
          status_rec_url: '0'
        },
        {
          // 归档号
          file_no: '2020021002',

          // 客户名称
          cust_name: '北京建筑设计研究院二分',

          // 决策人
          contact: '研二',

          // 建档时间
          file_time: '2020-02-11',

          // 负责人

          staff_name: '王梦颖',

          // 访客记录--天数
          days_visit: '5',

          // 访客记录--次数
          visit_num: '3',

          // 访客记录--状态
          status_visit: '2',

          // 漏斗--天数
          days_funn: '30',

          // 漏斗--金额
          amt_funn: '500,000元',

          // 漏斗--阶段
          stage_funn: '赢单',

          // 漏斗--状态
          status_funn: '2',

          // 合同--天数
          days_con: '3',

          // 合同--金额
          amt_con: '500,000元',

          // 合同--状态
          status_con: '2',

          // 发票--天数
          days_inv: '3',

          // 发票--已开发票金额
          amt_inv_yes: '500,000元',

          // 发票--未开发票金额
          amt_inv_no: '0元',

          // 发票--状态
          status_inv: '2',

          // 回款--天数
          days_rec: '2',

          // 回款--已回款金额
          amt_rec_yes: '300,000元',

          // 回款--未回款金额
          amt_rec_no: '200,000元',

          // 回款--状态
          status_rec: '1',

          // 交付单--天数
          days_deliver: '0',

          // 交付单--已交付金额
          amt_deliver_yes: '0元',

          // 交付单--未交付金额
          amt_deliver_no: '500,000元',

          // 交付单--状态
          status_deliver: '0',

          // 归档状态
          file_status: '0',

          // 合同附件 状态 1有，0无
          status_con_url: '1',

          // 交付单附件 状态 1有，0无
          status_deliver_url: '0',

          // 发票单附件 状态 1有，0无
          status_inv_url: '0',

          // 回款单附件 状态 1有，0无
          status_rec_url: '0'
        },
        {
          // 归档号
          file_no: '2020021003',
          // 客户名称
          cust_name: '北京建筑设计研究院三分',

          // 决策人
          contact: '研三',

          // 建档时间
          file_time: '2020-02-12',

          // 负责人

          staff_name: '王梦颖',

          // 访客记录--天数
          days_visit: '7',

          // 访客记录--次数
          visit_num: '9',

          // 访客记录--状态
          status_visit: '2',

          // 漏斗--天数
          days_funn: '10',

          // 漏斗--金额
          amt_funn: '500,000元',

          // 漏斗--阶段
          stage_funn: '赢单',

          // 漏斗--状态
          status_funn: '2',

          // 合同--天数
          days_con: '15',

          // 合同--金额
          amt_con: '700,000元',

          // 合同--状态
          status_con: '2',

          // 发票--天数
          days_inv: '5',

          // 发票--已开发票金额
          amt_inv_yes: '200,000元',

          // 发票--未开发票金额
          amt_inv_no: '500,000元',

          // 发票--状态
          status_inv: '1',

          // 回款--天数
          days_rec: '0',

          // 回款--已回款金额
          amt_rec_yes: '0元',

          // 回款--未回款金额
          amt_rec_no: '200,000元',

          // 回款--状态
          status_rec: '0',

          // 交付单--天数
          days_deliver: '0',

          // 交付单--已交付金额
          amt_deliver_yes: '0元',

          // 交付单--未交付金额
          amt_deliver_no: '700,000元',

          // 交付单--状态
          status_deliver: '0',

          // 归档状态
          file_status: '0',

          // 合同附件 状态 1有，0无
          status_con_url: '1',

          // 交付单附件 状态 1有，0无
          status_deliver_url: '0',

          // 发票单附件 状态 1有，0无
          status_inv_url: '0',

          // 回款单附件 状态 1有，0无
          status_rec_url: '0'
        },
        {
          // 归档号
          file_no: '2020021004',
          // 客户名称
          cust_name: '北京建筑设计研究院四分',

          // 决策人
          contact: '研四',

          // 建档时间
          file_time: '2020-02-13',

          // 负责人

          staff_name: '王梦颖',

          // 访客记录--天数
          days_visit: '30',

          // 访客记录--次数
          visit_num: '17',

          // 访客记录--状态
          status_visit: '2',

          // 漏斗--天数
          days_funn: '35',

          // 漏斗--金额
          amt_funn: '200,000元',

          // 漏斗--阶段
          stage_funn: '赢单',

          // 漏斗--状态
          status_funn: '2',

          // 合同--天数
          days_con: '0',

          // 合同--金额
          amt_con: '100,000元',

          // 合同--状态
          status_con: '1',

          // 发票--天数
          days_inv: '0',

          // 发票--已开发票金额
          amt_inv_yes: '0元',

          // 发票--未开发票金额
          amt_inv_no: '100,000元',

          // 发票--状态
          status_inv: '0',

          // 回款--天数
          days_rec: '0',

          // 回款--已回款金额
          amt_rec_yes: '0元',

          // 回款--未回款金额
          amt_rec_no: '0元',

          // 回款--状态
          status_rec: '0',

          // 交付单--天数
          days_deliver: '0',

          // 交付单--已交付金额
          amt_deliver_yes: '0元',

          // 交付单--未交付金额
          amt_deliver_no: '0元',

          // 交付单--状态
          status_deliver: '0',

          // 归档状态
          file_status: '0',

          // 合同附件 状态 1有，0无
          status_con_url: '0',

          // 交付单附件 状态 1有，0无
          status_deliver_url: '0',

          // 发票单附件 状态 1有，0无
          status_inv_url: '0',

          // 回款单附件 状态 1有，0无
          status_rec_url: '0'
        },
        {
          // 归档号
          file_no: '2020021005',
          // 客户名称
          cust_name: '北京建筑设计研究院五分',

          // 决策人
          contact: '研五',

          // 建档时间
          file_time: '2020-02-15',

          // 负责人

          staff_name: '王梦颖',

          // 访客记录--天数
          days_visit: '10',

          // 访客记录--次数
          visit_num: '5',

          // 访客记录--状态
          status_visit: '2',

          // 漏斗--天数
          days_funn: '60',

          // 漏斗--金额
          amt_funn: '300,000元',

          // 漏斗--阶段
          stage_funn: '商务谈判',

          // 漏斗--状态
          status_funn: '1',

          // 合同--天数
          days_con: '0',

          // 合同--金额
          amt_con: '0元',

          // 合同--状态
          status_con: '0',

          // 发票--天数
          days_inv: '0',

          // 发票--已开发票金额
          amt_inv_yes: '0元',

          // 发票--未开发票金额
          amt_inv_no: '0元',

          // 发票--状态
          status_inv: '0',

          // 回款--天数
          days_rec: '0',

          // 回款--已回款金额
          amt_rec_yes: '0元',

          // 回款--未回款金额
          amt_rec_no: '0元',

          // 回款--状态
          status_rec: '0',

          // 交付单--天数
          days_deliver: '0',

          // 交付单--已交付金额
          amt_deliver_yes: '0元',

          // 交付单--未交付金额
          amt_deliver_no: '0元',

          // 交付单--状态
          status_deliver: '0',

          // 归档状态
          file_status: '0',

          // 合同附件 状态 1有，0无
          status_con_url: '0',

          // 交付单附件 状态 1有，0无
          status_deliver_url: '0',

          // 发票单附件 状态 1有，0无
          status_inv_url: '0',

          // 回款单附件 状态 1有，0无
          status_rec_url: '0'
        },
        {
          // 归档号
          file_no: '2020021006',
          // 客户名称
          cust_name: '北京建筑设计研究院六分',

          // 决策人
          contact: '研六',

          // 建档时间
          file_time: '2020-02-16',

          // 负责人

          staff_name: '王梦颖',

          // 访客记录--天数
          days_visit: '40',

          // 访客记录--次数
          visit_num: '11',

          // 访客记录--状态
          status_visit: '2',

          // 漏斗--天数
          days_funn: '50',

          // 漏斗--金额
          amt_funn: '250,000元',

          // 漏斗--阶段
          stage_funn: '赢单',

          // 漏斗--状态
          status_funn: '2',

          // 合同--天数
          days_con: '0',

          // 合同--金额
          amt_con: '0元',

          // 合同--状态
          status_con: '0',

          // 发票--天数
          days_inv: '0',

          // 发票--已开发票金额
          amt_inv_yes: '0元',

          // 发票--未开发票金额
          amt_inv_no: '0元',

          // 发票--状态
          status_inv: '0',

          // 回款--天数
          days_rec: '1',

          // 回款--已回款金额
          amt_rec_yes: '0元',

          // 回款--未回款金额
          amt_rec_no: '20000元',

          // 回款--状态
          status_rec: '2',

          // 交付单--天数
          days_deliver: '0',

          // 交付单--已交付金额
          amt_deliver_yes: '0元',

          // 交付单--未交付金额
          amt_deliver_no: '0元',

          // 交付单--状态
          status_deliver: '0',

          // 归档状态
          file_status: '0',

          // 合同附件 状态 1有，0无
          status_con_url: '0',

          // 交付单附件 状态 1有，0无
          status_deliver_url: '0',

          // 发票单附件 状态 1有，0无
          status_inv_url: '0',

          // 回款单附件 状态 1有，0无
          status_rec_url: '0'
        },
        {
          // 归档号
          file_no: '2020021007',
          // 客户名称
          cust_name: '北京建筑设计研究院七分',

          // 决策人
          contact: '研七',

          // 建档时间
          file_time: '2020-02-17',

          // 负责人

          staff_name: '王梦颖',

          // 访客记录--天数
          days_visit: '67',

          // 访客记录--次数
          visit_num: '25',

          // 访客记录--状态
          status_visit: '2',

          // 漏斗--天数
          days_funn: '20',

          // 漏斗--金额
          amt_funn: '714,000元',

          // 漏斗--阶段
          stage_funn: '赢单',

          // 漏斗--状态
          status_funn: '2',

          // 合同--天数
          days_con: '15',

          // 合同--金额
          amt_con: '350,000元',

          // 合同--状态
          status_con: '2',

          // 发票--天数
          days_inv: '0',

          // 发票--已开发票金额
          amt_inv_yes: '0元',

          // 发票--未开发票金额
          amt_inv_no: '350,000元',

          // 发票--状态
          status_inv: '0',

          // 回款--天数
          days_rec: '1',

          // 回款--已回款金额
          amt_rec_yes: '70,000元',

          // 回款--未回款金额
          amt_rec_no: '0元',

          // 回款--状态
          status_rec: '1',

          // 交付单--天数
          days_deliver: '0',

          // 交付单--已交付金额
          amt_deliver_yes: '0元',

          // 交付单--未交付金额
          amt_deliver_no: '350,000元',

          // 交付单--状态
          status_deliver: '0',

          // 归档状态
          file_status: '0',

          // 合同附件 状态 1有，0无
          status_con_url: '1',

          // 交付单附件 状态 1有，0无
          status_deliver_url: '0',

          // 发票单附件 状态 1有，0无
          status_inv_url: '0',

          // 回款单附件 状态 1有，0无
          status_rec_url: '0'
        },
        {
          // 归档号
          file_no: '2020021008',
          // 客户名称
          cust_name: '北京建筑设计研究院八分',

          // 决策人
          contact: '研八',

          // 建档时间
          file_time: '2020-02-18',

          // 负责人

          staff_name: '王梦颖',

          // 访客记录--天数
          days_visit: '9',

          // 访客记录--次数
          visit_num: '2',

          // 访客记录--状态
          status_visit: '2',

          // 漏斗--天数
          days_funn: '1',

          // 漏斗--金额
          amt_funn: '30,000元',

          // 漏斗--阶段
          stage_funn: '输单',

          // 漏斗--状态
          status_funn: '2',

          // 合同--天数
          days_con: '0',

          // 合同--金额
          amt_con: '0元',

          // 合同--状态
          status_con: '0',

          // 发票--天数
          days_inv: '0',

          // 发票--已开发票金额
          amt_inv_yes: '0元',

          // 发票--未开发票金额
          amt_inv_no: '0元',

          // 发票--状态
          status_inv: '0',

          // 回款--天数
          days_rec: '0',

          // 回款--已回款金额
          amt_rec_yes: '0元',

          // 回款--未回款金额
          amt_rec_no: '0元',

          // 回款--状态
          status_rec: '0',

          // 交付单--天数
          days_deliver: '0',

          // 交付单--已交付金额
          amt_deliver_yes: '0元',

          // 交付单--未交付金额
          amt_deliver_no: '0元',

          // 交付单--状态
          status_deliver: '0',

          // 归档状态
          file_status: '0',

          // 合同附件 状态 1有，0无
          status_con_url: '0',

          // 交付单附件 状态 1有，0无
          status_deliver_url: '0',

          // 发票单附件 状态 1有，0无
          status_inv_url: '0',

          // 回款单附件 状态 1有，0无
          status_rec_url: '0'
        }
      ]
      console.log(this.kanban_neirong_list)
      this.chaxun_guize()
    },
    chaxun_guize () {
      query_sale_mng_warn_rule({
        data: {
          ent_id: this.$ent_id()
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          date.map((item, index) => {
            if (item.item == 'visit') {
              this.fangke_jilu_rzhi = item.num
            } else if (item.item == 'funn') {
              this.loudou_jieduan = item.num
            } else if (item.item == 'con') {
              this.hetong = item.num
            } else if (item.item == 'inv') {
              this.fapiao = item.num
            } else if (item.item == 'inv_no') {
              this.weikai_fapiao = item.num
            } else if (item.item == 'rec') {
              this.huikuan = item.num
            } else if (item.item == 'rec_no') {
              this.yingshou_jine = item.num
            } else if (item.item == 'deliver') {
              this.jiaofu = item.num
            }
          })
        } else if (res.data.code == 500) {}
      })
    },
    // 测试值
    dianji_guidang (i) {
      if (i.file_status != 2) {
        update_sale_mng_board_file_status({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            file_no: i.file_no,
            file_status: '2'
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '操作成功',
              type: 'success'
            })
          } else if (res.data.code == 500) {}
        })
        i.file_status = 2
        // this.$forceUpdate()
        console.log(i)
      }
    },
    jieduan_hanzi (i) {
      if (i == '1') {
        return '初步接触'
      } else if (i == '2') {
        return '需求确认'
      } else if (i == '3') {
        return '报价及方案展示'
      } else if (i == '4') {
        return '商务谈判'
      } else if (i == '5') {
        return '赢单'
      } else if (i == '6') {
        return '输单'
      }
    },
    dianji_fanhui () {
      this.$router.push('/salesman')
    },
    // 表头颜色
    biaotou (row) { // 根据报警级别显示颜色
      return 'text-align:center;color:#1A2533;font-size:0.14rem'
    },
    // color:#4C4A4D;font-size:0.12rem;
    liebiao (row) {
      return 'text-align:center;color:#1A2533;font-size:0.12rem;'
    },
    dakai_youce_xiangqing (i, name) {
      console.log(i)
      this.youce_xiangqing = ''
      this.youce_xiangqing2 = ''
      this.biao_name = name
      if (name == '访客') {
        query_form_list_file_nos({
          data: {
            ent_id: this.$ent_id(),
            file_no: i.file_no,
            cust_id: i.cust_id,
            flow_id: 'Z7VDOK9HGu'
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(it => {
              this.$set(it, 'zidingyi', it.extra != undefined ? JSON.parse(it.extra) : [])
            })
            this.youce_xiangqing = date
            if (date.length != 0) {
              this.dianji_chuxian_youce = true
            }
          } else if (res.data.code == 500) {}
        })
        // s_visit_record
        query_form_list_file_nos({
          data: {
            ent_id: this.$ent_id(),
            file_no: i.file_no,
            cust_id: i.cust_id,
            flow_id: '453y6pLD52'
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(it => {
              this.$set(it, 'zidingyi', it.extra != undefined ? JSON.parse(it.extra) : [])
            })
            this.youce_xiangqing2 = date
            if (date.length != 0) {
              this.dianji_chuxian_youce = true
            }
          } else if (res.data.code == 500) {}
        })
      } else if (name == '合同') {
        query_form_list_file_nos({
          data: {
            ent_id: this.$ent_id(),
            file_no: i.file_no,
            cust_id: i.cust_id,
            flow_id: 'qIwxmsMTgv'
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(it => {
              this.$set(it, 'zidingyi', it.extra != undefined ? JSON.parse(it.extra) : [])
            })
            this.youce_xiangqing = date
            if (date.length != 0) {
              this.dianji_chuxian_youce = true
            }
          } else if (res.data.code == 500) {}
        })
      } else if (name == '发票') {
        query_form_list_file_nos({
          data: {
            ent_id: this.$ent_id(),
            file_no: i.file_no,
            cust_id: i.cust_id,
            flow_id: 'VMCizuVOXb'
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            date.forEach(it => {
              this.$set(it, 'zidingyi', it.extra != undefined ? JSON.parse(it.extra) : [])
            })
            this.youce_xiangqing = date
            if (date.length != 0) {
              this.dianji_chuxian_youce = true
            }
          } else if (res.data.code == 500) {}
        })
      } else if (name == '回款') {
        query_form_list_file_nos({
          data: {
            ent_id: this.$ent_id(),
            file_no: i.file_no,
            cust_id: i.cust_id,
            flow_id: 'He5ln0bdDO'
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date[0])
            date.forEach(it => {
              this.$set(it, 'zidingyi', it.extra != undefined ? JSON.parse(it.extra) : [])
            })
            this.youce_xiangqing = date
            if (date.length != 0) {
              this.dianji_chuxian_youce = true
            }
          } else if (res.data.code == 500) {}
        })
      } else if (name == '交付单') {
        query_form_list_file_nos({
          data: {
            ent_id: this.$ent_id(),
            file_no: i.file_no,
            cust_id: i.cust_id,
            flow_id: 'H49d4edc3T'
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date[0])
            date.forEach(it => {
              this.$set(it, 'zidingyi', it.extra != undefined ? JSON.parse(it.extra) : [])
            })
            this.youce_xiangqing = date
            if (date.length != 0) {
              this.dianji_chuxian_youce = true
            }
          } else if (res.data.code == 500) {}
        })
      } else if (name == '派工单') {
        query_form_list_file_nos({
          data: {
            ent_id: this.$ent_id(),
            file_no: i.file_no,
            cust_id: i.cust_id,
            flow_id: 'Eb3n7CKQzi'
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date[0])
            date.forEach(it => {
              this.$set(it, 'zidingyi', it.extra != undefined ? JSON.parse(it.extra) : [])
            })
            this.youce_xiangqing = date
            if (date.length != 0) {
              this.dianji_chuxian_youce = true
            }
          } else if (res.data.code == 500) {}
        })
      } else if (name == '漏斗阶段') {
        query_form_list_file_nos({
          data: {
            ent_id: this.$ent_id(),
            file_no: i.file_no,
            cust_id: i.cust_id,
            flow_id: 'PLOQ9ku3Ip'
          }
        }).then(res => {
          if (res.data.code == 200) {
            const date = JSON.parse(res.data.body.data)
            console.log(date)
            date.forEach(it => {
              this.$set(it, 'zidingyi', it.extra != undefined ? JSON.parse(it.extra) : [])
            })
            if (date.length != 0) {
              this.youce_xiangqing = date
              this.dianji_chuxian_youce = true
            }
          } else if (res.data.code == 500) {}
        })
      }
    },
    guidang_paixu (a, b) {
      const val1 = a.file_status
      const val2 = b.file_status
      return val1 - val2
    },
    // 漏斗排序
    loudou_paixu (a, b) {
      const val1 = a.status_funn
      const val2 = b.status_funn
      return val1 - val2
    },
    // 合同排序
    hetong_paixu (a, b) {
      const val1 = a.status_con
      const val2 = b.status_con
      return val1 - val2
    },
    // 发票排序
    fapiao_paixu (a, b) {
      const val1 = a.status_inv
      const val2 = b.status_inv
      return val1 - val2
    },
    // 回款排序
    huikuan_paixu (a, b) {
      const val1 = a.status_rec
      const val2 = b.status_rec
      return val1 - val2
    },
    // 交付排序
    jiaofu_paixu (a, b) {
      const val1 = a.status_deliver
      const val2 = b.status_deliver
      return val1 - val2
    },
    ceshi (i) {
      // tongxun.$emit('liebiao_name',i)
      this.$refs.kanban_liebiao.jichu(i)
      // this.$router.push('/kanbanlist')
    },
    // 测试漏斗
    ceshi_loudou (h, { column }) {
      return (
        <div>
          <i class='el-icon-s-grid' style='margin-right:0.06rem;' on-click={() => {
            this.ceshi({
              name: column.label,
              look_type: this.kan_shei == 0 ? '2' : (this.kan_shei == '1' ? '1' : '3')
            })
          }}></i>
          <span>{column.label}</span>
        </div>
      )
    },
    dianji_guize_baocun () {
      if (this.fangke_jilu_rzhi.length != 0 && this.loudou_jieduan.length != 0 && this.hetong.length != 0 && this.fapiao.length != 0 && this.weikai_fapiao.length != 0 && this.huikuan.length != 0 && this.yingshou_jine.length != 0 && this.jiaofu.length != 0) {
        insert_sale_mng_warn_rule({
          data: {
            ent_id: this.$ent_id(),
            user_id: this.$jichuxinxi().user_id,
            list: [
              {
                item: 'visit',
                active: '1',
                num: this.fangke_jilu_rzhi
              },
              {
                item: 'funn',
                active: '1',
                num: this.loudou_jieduan
              },
              {
                item: 'con',
                active: '1',
                num: this.hetong
              },
              {
                item: 'inv',
                active: '1',
                num: this.fapiao
              },
              {
                item: 'inv_no',
                active: '1',
                num: this.weikai_fapiao
              },
              {
                item: 'rec',
                active: '1',
                num: this.huikuan
              },
              {
                item: 'rec_no',
                active: '1',
                num: this.yingshou_jine
              },
              {
                item: 'deliver',
                active: '1',
                num: this.jiaofu
              }
            ]
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 200) {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.$router.push('/salesman')
          } else if (res.data.code == 500) {}
        })
      } else {
        this.$message({
          message: '没有填写完信息',
          type: 'warning'
        })
      }
    },
    // 搜索框
    sousuo () {
      generate_sale_mng_board({
        data: {
          ent_id: this.$ent_id(),
          search_str: this.sousuo_text.length == 0 ? null : this.sousuo_text,
          user_id: this.$jichuxinxi().user_id,
          look_type: this.kan_shei == 0 ? '2' : (this.kan_shei == '1' ? '1' : '3'),
          page_size: '10',
          page_index: this.dangqian_yeshu + ''
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = JSON.parse(res.data.body.data)
          console.log(date)
          if (date.list.length != 0) {
            date.list.map((item, index) => {
              item.stage_funn = this.jieduan_hanzi(item.stage_funn)
              item.amt_con = (item.amt_con != undefined ? Number(item.amt_con).toFixed(0) : '0') + '元'
              item.amt_inv_yes = (item.amt_inv_yes != undefined ? Number(item.amt_inv_yes).toFixed(0) : '0') + '元'
              item.amt_inv_no = (item.amt_inv_no != undefined ? Number(item.amt_inv_no).toFixed(0) : '0') + '元'
              item.amt_rec_yes = (item.amt_rec_yes != undefined ? Number(item.amt_rec_yes).toFixed(0) : '0') + '元'
              item.amt_rec_no = (item.amt_rec_no != undefined ? Number(item.amt_rec_no).toFixed(0) : '0') + '元'
              item.amt_funn = (item.amt_funn != undefined ? Number(item.amt_funn).toFixed(0) : '0') + '元'
              item.amt_deliver_yes = (item.amt_deliver_yes != undefined ? Number(item.amt_deliver_yes).toFixed(0) : '0') + '元'
              item.amt_deliver_no = (item.amt_deliver_no != undefined ? Number(item.amt_deliver_no).toFixed(0) : '0') + '元'
              item.days_visit = item.days_visit != undefined ? item.days_visit : '0'
              item.visit_num = item.visit_num != undefined ? item.visit_num : '0'
              item.days_con = item.days_con != undefined ? item.days_con : '0'
              item.days_funn = item.days_funn != undefined ? item.days_funn : '0'
              item.days_inv = item.days_inv != undefined ? item.days_inv : '0'
              item.days_deliver = item.days_deliver != undefined ? item.days_deliver : '0'
              item.days_rec = item.days_rec != undefined ? item.days_rec : '0'
            })
            this.dangqian_zongshu = date.total
            this.kanban_neirong_list = date.list
          } else {
            this.dangqian_zongshu = 0
            this.kanban_neirong_list = []
          }
        } else if (res.data.code == 500) {}
      })
    },
    dianji_xiazai (i, name) {
      this.$bendi_cunfang_cunchu('mlbb_xiaoshou_tian', { date: i, name: name }, '暂时')
      this.$router.push('/importexport_xiaoshou')
    },
    dianji_xinzeng (i, flow_id, flow_name) {
      // query_form_list_file_nos({data:{
      //     ent_id:this.$ent_id(),
      //     file_no:i.file_no,
      //     cust_id:i.cust_id,
      //     flow_id:flow_id
      // }}).then(rds=>{
      //     if(rds.data.code==200){
      //         let xiangqing=JSON.parse(rds.data.body.data)
      //         xiangqing=xiangqing[xiangqing.length-1]
      // query_flow_form_have_set({data:{
      //     ent_id:this.$ent_id(),
      //     flow_id:flow_id
      // }}).then(ras=>{
      //     console.log(ras)
      //     if(ras.data.code==10245){
      query_flow_info_all({
        data: {
          ent_id: this.$ent_id(),
          flow_id: flow_id
        }
      }).then(res => {
        console.log(res)
        if (res.data.code == 200) {
          const date = res.data.body.form_str
          console.log(date)
          const data = []
          for (let a = 0; a < date.length; a++) {
            if (date[a].defaultImportant != undefined) {
              date[a].defaultImportant = date[a].defaultImportant != 'false'
            }
            if (date[a].defaultPrint != undefined) {
              date[a].defaultPrint = date[a].defaultPrint != 'false'
            }
            if (date[a].defaultOptions != undefined) {
              date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
            }
            if (date[a].gongshi != undefined) {
              date[a].gongshi = JSON.parse(date[a].gongshi)
            }
            if (date[a].supportSetting != undefined) {
              date[a].supportSetting = JSON.parse(date[a].supportSetting)
            }
            if (date[a].components != undefined) {
              date[a].components = JSON.parse(date[a].components)
              for (let b = 0; b < date[a].components.length; b++) {
                if (date[a].components[b].defaultImportant != undefined) {
                  if (typeof (date[a].components[b].defaultImportant) === 'string') {
                    date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                  }
                }
                if (date[a].components[b].defaultPrint != undefined) {
                  if (typeof (date[a].components[b].defaultPrint) === 'string') {
                    date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                  }
                }
                // typeof(str)=='string'
                if (date[a].components[b].defaultOptions != undefined) {
                  if (typeof (date[a].components[b].defaultOptions) === 'string') {
                    date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                  }
                }
                if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                  if (typeof (date[a].components[b].gongshi) === 'string') {
                    console.log(date[a].components[b].gongshi)
                    date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                  }
                }
                if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                  if (typeof (date[a].components[b].supportSetting) === 'string') {
                    console.log(date[a].components[b].supportSetting)
                    date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                  }
                }
              }
            }
            data.push(date[a])
          }
          const obj = {
            flow_id: flow_id,
            flow_name: flow_name
          }
          if (flow_id == '453y6pLD52') { // 访客记录单
            data.forEach(item => {
              if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                // console.log(xiangqing.cust_name)
                // this.$set(item,'value',xiangqing.cust_name)
                // this.$set(item,'value_id',xiangqing.cust_id)
                const kehu_list = [
                  {
                    cust_id: i.cust_id,
                    cust_name: i.cust_name,
                    file_no: i.file_no,
                    dept_id: i.dept_id,
                    dept_name: i.dept_name
                  }
                ]
                this.$set(item, 'kehu_list', kehu_list)
                this.$set(item, 'zhanshi', '0')
                this.$set(item, 'value', i.cust_name)
                this.$set(item, 'value_id', i.cust_id)
              }
              if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                this.$set(item, 'value', i.file_no)
              }
            })
          } else if (flow_id == 'PLOQ9ku3Ip') { // 漏斗单
            data.forEach(item => {
              if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                // console.log(i.cust_name)
                // this.$set(item,'value',i.cust_name)
                // this.$set(item,'value_id',i.cust_id)
                const kehu_list = [
                  {
                    cust_id: i.cust_id,
                    cust_name: i.cust_name,
                    file_no: i.file_no,
                    dept_id: i.dept_id,
                    dept_name: i.dept_name
                  }
                ]
                this.$set(item, 'kehu_list', kehu_list)
                this.$set(item, 'zhanshi', '0')
                this.$set(item, 'value', i.cust_name)
                this.$set(item, 'value_id', i.cust_id)
              }
            })
          } else if (flow_id == 'qIwxmsMTgv') { // 合同单
            data.forEach(item => {
              if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                const kehu_list = [
                  {
                    cust_id: i.cust_id,
                    cust_name: i.cust_name,
                    file_no: i.file_no,
                    dept_id: i.dept_id,
                    dept_name: i.dept_name
                  }
                ]
                this.$set(item, 'kehu_list', kehu_list)
                this.$set(item, 'zhanshi', '0')
                this.$set(item, 'value', i.cust_name)
                this.$set(item, 'value_id', i.cust_id)
              }
            })
          } else if (flow_id == 'VMCizuVOXb') { // 发票
            data.forEach(item => {
              if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                const kehu_list = [
                  {
                    cust_id: i.cust_id,
                    cust_name: i.cust_name,
                    file_no: i.file_no,
                    dept_id: i.dept_id,
                    dept_name: i.dept_name
                  }
                ]
                console.log(i)
                //
                this.$set(item, 'kehu_list', kehu_list)
                this.$set(item, 'zhanshi', '0')
                this.$set(item, 'value', i.cust_name)
                this.$set(item, 'value_id', i.cust_id)
              }
              if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                this.$set(item, 'value', i.file_no)
              }
            })
          } else if (flow_id == 'He5ln0bdDO') { // 回款
            data.forEach(item => {
              if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                const kehu_list = [
                  {
                    cust_id: i.cust_id,
                    cust_name: i.cust_name,
                    file_no: i.file_no,
                    dept_id: i.dept_id,
                    dept_name: i.dept_name
                  }
                ]
                this.$set(item, 'kehu_list', kehu_list)
                this.$set(item, 'zhanshi', '0')
                this.$set(item, 'value', i.cust_name)
                this.$set(item, 'value_id', i.cust_id)
              }
              if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                this.$set(item, 'value', i.file_no)
              }
            })
          } else if (flow_id == 'H49d4edc3T') { // 交付单
            data.forEach(item => {
              if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                const kehu_list = [
                  {
                    cust_id: i.cust_id,
                    cust_name: i.cust_name,
                    file_no: i.file_no,
                    dept_id: i.dept_id,
                    dept_name: i.dept_name
                  }
                ]
                this.$set(item, 'kehu_list', kehu_list)
                this.$set(item, 'zhanshi', '0')
                this.$set(item, 'value', i.cust_name)
                this.$set(item, 'value_id', i.cust_id)
              }
              if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                this.$set(item, 'value', i.file_no)
              }
            })
          } else if (flow_id == 'Eb3n7CKQzi') {
            data.forEach(item => {
              if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                const kehu_list = [
                  {
                    cust_id: i.cust_id,
                    cust_name: i.cust_name,
                    file_no: i.file_no,
                    dept_id: i.dept_id,
                    dept_name: i.dept_name
                  }
                ]
                this.$set(item, 'kehu_list', kehu_list)
                this.$set(item, 'zhanshi', '0')
                this.$set(item, 'value', i.cust_name)
                this.$set(item, 'value_id', i.cust_id)
              }
              if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                this.$set(item, 'value', i.file_no)
              }
            })
          }

          console.log(data)
          this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
          this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
          this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '看板', '暂时')
          this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_4', i, '暂时')
          this.$router.push('/usetable')
        } else if (res.data.code == 500) {}
      })
      //     }else if(ras.data.code==10246){
      //         this.shifou=true
      //         this.danzi_name=flow_name
      //     }
      // })
      //     }else if(rds.data.code==500){}
      // })
    },
    dian_kanshei (i, index) {
      this.kan_shei = index
      this.jichu()
    },
    dianji_bianji (i, flow_id, flow_name) {
      console.log(i)
      // PLOQ9ku3Ip   漏斗单
      // query_form_list_file_nos({data:{
      //     ent_id:this.$ent_id(),
      //     cust_id:i.cust_id,
      //     file_no:i.file_no,
      //     flow_id:flow_id
      // }}).then(rds=>{
      //     console.log(rds)
      //     if(rds.data.code==200){
      //         let xiangqing=JSON.parse(rds.data.body.data)[0]
      //         console.log(xiangqing)
      query_form_list_file_nos({
        data: {
          ent_id: this.$ent_id(),
          file_no: i.file_no,
          cust_id: i.cust_id,
          flow_id: flow_id
        }
      }).then(rds => {
        if (rds.data.code == 200) {
          let xiangqing = JSON.parse(rds.data.body.data)
          xiangqing = xiangqing[xiangqing.length - 1]
          console.log(xiangqing)

          // query_flow_form_have_set({data:{
          //     ent_id:this.$ent_id(),
          //     flow_id:flow_id
          // }}).then(ras=>{
          //     console.log(ras)
          //     if(ras.data.code==10245){
          query_flow_info_all({
            data: {
              ent_id: this.$ent_id(),
              flow_id: flow_id
            }
          }).then(res => {
            console.log(res)
            if (res.data.code == 200) {
              const date = res.data.body.form_str
              console.log(date)
              const data = []
              for (let a = 0; a < date.length; a++) {
                if (date[a].defaultImportant != undefined) {
                  date[a].defaultImportant = date[a].defaultImportant != 'false'
                }
                if (date[a].defaultPrint != undefined) {
                  date[a].defaultPrint = date[a].defaultPrint != 'false'
                }
                if (date[a].defaultOptions != undefined) {
                  date[a].defaultOptions = JSON.parse(date[a].defaultOptions)
                }
                if (date[a].gongshi != undefined) {
                  date[a].gongshi = JSON.parse(date[a].gongshi)
                }
                if (date[a].supportSetting != undefined) {
                  date[a].supportSetting = JSON.parse(date[a].supportSetting)
                }
                if (date[a].components != undefined) {
                  date[a].components = JSON.parse(date[a].components)
                  for (let b = 0; b < date[a].components.length; b++) {
                    if (date[a].components[b].defaultImportant != undefined) {
                      if (typeof (date[a].components[b].defaultImportant) === 'string') {
                        date[a].components[b].defaultImportant = date[a].components[b].defaultImportant != 'false'
                      }
                    }
                    if (date[a].components[b].defaultPrint != undefined) {
                      if (typeof (date[a].components[b].defaultPrint) === 'string') {
                        date[a].components[b].defaultPrint = date[a].components[b].defaultPrint != 'false'
                      }
                    }
                    // typeof(str)=='string'
                    if (date[a].components[b].defaultOptions != undefined) {
                      if (typeof (date[a].components[b].defaultOptions) === 'string') {
                        date[a].components[b].defaultOptions = JSON.parse(date[a].components[b].defaultOptions)
                      }
                    }
                    if (date[a].components[b].gongshi != undefined && date[a].components[b].gongshi.length != 0) {
                      if (typeof (date[a].components[b].gongshi) === 'string') {
                        console.log(date[a].components[b].gongshi)
                        date[a].components[b].gongshi = JSON.parse(date[a].components[b].gongshi)
                      }
                    }
                    if (date[a].components[b].supportSetting != undefined && date[a].components[b].supportSetting.length != 0) {
                      if (typeof (date[a].components[b].supportSetting) === 'string') {
                        console.log(date[a].components[b].supportSetting)
                        date[a].components[b].supportSetting = JSON.parse(date[a].components[b].supportSetting)
                      }
                    }
                  }
                }
                data.push(date[a])
              }
              const obj = {
                flow_id: flow_id,
                flow_name: flow_name
              }
              if (flow_id == 'PLOQ9ku3Ip') {
                data.forEach(item => {
                  if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                    const kehu_list = [
                      {
                        cust_id: i.cust_id,
                        cust_name: i.cust_name,
                        file_no: i.file_no,
                        dept_id: i.dept_id,
                        dept_name: i.dept_name
                      }
                    ]
                    this.$set(item, 'kehu_list', kehu_list)
                    this.$set(item, 'zhanshi', '0')
                    this.$set(item, 'value', xiangqing.cust_name)
                    this.$set(item, 'value_id', xiangqing.cust_id)
                  }
                  if (item.componentName == 'department' && item.defaultLable == '部门') {
                    this.$set(item, 'dept_id', xiangqing.dept_id)
                    this.$set(item, 'value', xiangqing.dept_name)
                  }
                  if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                    this.$set(item, 'value', xiangqing.file_no)
                  }
                  if (item.componentName == 'ddselectfield' && item.defaultLable == '漏斗阶段' && item.idx == 11) {
                    console.log(item)
                    console.log(this.jieduan_hanzi(xiangqing.stage_funn))
                    item.defaultOptions.forEach((ite, ind) => {
                      console.log(ite.text)
                      if (ite.text == this.jieduan_hanzi(xiangqing.stage_funn)) {
                        console.log(ite.text)
                        console.log(ind)
                        this.$set(item, 'value', ind)
                        this.$set(item, 'teshu', false)
                      }
                    })
                    console.log(item.value)
                    if (item.value == undefined) {
                      this.$set(item, 'value', '0')
                      this.$set(item, 'teshu', false)
                    }
                  }
                  if (item.componentName == 'calcform' && item.defaultLable == '漏斗金额' && item.idx == 12) {
                    console.log(item)
                    this.$set(item, 'value', xiangqing.amt_funn)
                  }
                  if (item.componentName == 'numberfield' && item.defaultLable == '漏斗折算比例' && item.idx == 14) {
                    this.$set(item, 'value', xiangqing.rate_funn_disc != undefined ? xiangqing.rate_funn_disc : '')
                  }
                })
              } else if (flow_id == 'qIwxmsMTgv') {
                data.forEach(item => {
                  // query_rel_customer_list({data:{
                  //     ent_id:this.$ent_id(),
                  //     staff_id:this.$jichuxinxi().user_id
                  // }}).then(rss=>{
                  //     if(rss.data.code==200){
                  //         console.log(rss)
                  //         let datee=JSON.parse(rss.data.body.data)
                  //         if(datee.length==0){
                  //         }else{
                  //             // i.teshu=true
                  //             // i['zhanshi']='0'
                  //             // i['kehu_list']=datee
                  //             if(item.componentName=='textfield'&&item.defaultLable=='客户'){
                  //                 this.$set(item,'teshu',false)
                  //                 datee.forEach((it,ind)=>{
                  //                     if(it.file_no==xiangqing.file_no){
                  //                         this.$set(item,'zhanshi',ind)
                  //                     }
                  //                 })
                  //                 this.$set(item,'kehu_list',datee)
                  //             }

                  //         }
                  //     }
                  // })
                  if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                    // console.log(xiangqing.cust_name)
                    // this.$set(item,'value',xiangqing.cust_name)
                    // this.$set(item,'value_id',xiangqing.cust_id)
                    const kehu_list = [
                      {
                        cust_id: i.cust_id,
                        cust_name: i.cust_name,
                        file_no: i.file_no,
                        dept_id: i.dept_id,
                        dept_name: i.dept_name
                      }
                    ]
                    this.$set(item, 'kehu_list', kehu_list)
                    this.$set(item, 'zhanshi', '0')
                    this.$set(item, 'value', xiangqing.cust_name)
                    this.$set(item, 'value_id', xiangqing.cust_id)
                  }
                  if (item.componentName == 'department' && item.defaultLable == '部门') {
                    this.$set(item, 'dept_id', xiangqing.dept_id)
                    this.$set(item, 'value', xiangqing.dept_name)
                  }
                  if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                    this.$set(item, 'value', xiangqing.file_no)
                  }
                  if (item.componentName == 'ddselectfield' && item.defaultLable == '所属公司' && item.idx == 3) {
                    console.log(item)
                    item.defaultOptions.forEach((ite, ind) => {
                      if (ite.text == xiangqing.belong_to_organ) {
                        this.$set(item, 'value', ind)
                        this.$set(item, 'teshu', false)
                      }
                    })
                    if (item.value == undefined) {
                      this.$set(item, 'value', '0')
                      this.$set(item, 'teshu', false)
                    }
                  }
                  if (item.componentName == 'ddselectfield' && item.defaultLable == '行业' && item.idx == 7) {
                    console.log(item)
                    item.defaultOptions.forEach((ite, ind) => {
                      if (ite.text == xiangqing.industry) {
                        this.$set(item, 'value', ind)
                        this.$set(item, 'teshu', false)
                      }
                    })
                    if (item.value == undefined) {
                      this.$set(item, 'value', '0')
                      this.$set(item, 'teshu', false)
                    }
                  }
                  if (item.componentName == 'ddselectfield' && item.defaultLable == '合同类型' && item.idx == 8) {
                    console.log(item)
                    item.defaultOptions.forEach((ite, ind) => {
                      if (ite.text == xiangqing.con_type) {
                        this.$set(item, 'value', ind)
                        this.$set(item, 'teshu', false)
                      }
                    })
                    if (item.value == undefined) {
                      this.$set(item, 'value', '0')
                      this.$set(item, 'teshu', false)
                    }
                  }
                  if (item.componentName == 'ddselectfield' && item.defaultLable == '地址' && item.idx == 6) {
                    this.$set(item, 'value', xiangqing.province)
                    this.$set(item, 'value_id', xiangqing.city)
                  }
                })
              } else if (flow_id == 'VMCizuVOXb') {
                data.forEach(item => {
                  // query_rel_customer_list({data:{
                  //     ent_id:this.$ent_id(),
                  //     staff_id:this.$jichuxinxi().user_id
                  // }}).then(rss=>{
                  //     if(rss.data.code==200){
                  //         console.log(rss)
                  //         let datee=JSON.parse(rss.data.body.data)
                  //         if(datee.length==0){
                  //         }else{
                  //             // i.teshu=true
                  //             // i['zhanshi']='0'
                  //             // i['kehu_list']=datee
                  //             if(item.componentName=='textfield'&&item.defaultLable=='客户'){
                  //                 this.$set(item,'teshu',false)
                  //                 datee.forEach((it,ind)=>{
                  //                     if(it.file_no==xiangqing.file_no){
                  //                         this.$set(item,'zhanshi',ind)
                  //                     }
                  //                 })
                  //                 this.$set(item,'kehu_list',datee)
                  //             }

                  //         }
                  //     }
                  // })
                  if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                    // console.log(xiangqing.cust_name)
                    // this.$set(item,'value',xiangqing.cust_name)
                    // this.$set(item,'value_id',xiangqing.cust_id)
                    const kehu_list = [
                      {
                        cust_id: i.cust_id,
                        cust_name: i.cust_name,
                        file_no: i.file_no,
                        dept_id: i.dept_id,
                        dept_name: i.dept_name
                      }
                    ]
                    console.log(xiangqing)
                    //
                    this.$set(item, 'kehu_list', kehu_list)
                    this.$set(item, 'zhanshi', '0')
                    this.$set(item, 'value', xiangqing.cust_name)
                    this.$set(item, 'value_id', xiangqing.cust_id)
                  }
                  if (item.componentName == 'department' && item.defaultLable == '部门') {
                    this.$set(item, 'dept_id', xiangqing.dept_id)
                    this.$set(item, 'value', xiangqing.dept_name)
                  }
                  if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                    this.$set(item, 'value', xiangqing.file_no)
                  }
                  if (item.componentName == 'moneyfield' && item.defaultLable == '累计发票金额') {
                    this.$set(item, 'value', xiangqing.amt_inv_sum)
                  }
                  if (item.componentName == 'moneyfield' && item.defaultLable == '合同未开发票金额') {
                    this.$set(item, 'value', xiangqing.amt_inv_un)
                  }
                  if (item.componentName == 'ddselectfield' && item.defaultLable == '所属公司') {
                    console.log(item)
                    item.defaultOptions.forEach((ite, ind) => {
                      if (ite.text == xiangqing.belong_to_organ) {
                        this.$set(item, 'value', ind)
                        this.$set(item, 'teshu', false)
                      }
                    })
                    if (item.value == undefined) {
                      this.$set(item, 'value', '0')
                      this.$set(item, 'teshu', false)
                    }
                  }
                  if (item.componentName == 'ddselectfield' && item.defaultLable == '发票类型') {
                    console.log(item)
                    item.defaultOptions.forEach((ite, ind) => {
                      if (ite.text == xiangqing.inv_type) {
                        this.$set(item, 'value', ind)
                        this.$set(item, 'teshu', false)
                      }
                    })
                    if (item.value == undefined) {
                      this.$set(item, 'value', '0')
                      this.$set(item, 'teshu', false)
                    }
                  }
                })
              } else if (flow_id == 'He5ln0bdDO') {
                data.forEach(item => {
                  // query_rel_customer_list({data:{
                  //     ent_id:this.$ent_id(),
                  //     staff_id:this.$jichuxinxi().user_id
                  // }}).then(rss=>{
                  //     if(rss.data.code==200){
                  //         console.log(rss)
                  //         let datee=JSON.parse(rss.data.body.data)
                  //         if(datee.length==0){
                  //         }else{
                  //             // i.teshu=true
                  //             // i['zhanshi']='0'
                  //             // i['kehu_list']=datee
                  //             if(item.componentName=='textfield'&&item.defaultLable=='客户'){
                  //                 this.$set(item,'teshu',false)
                  //                 datee.forEach((it,ind)=>{
                  //                     if(it.file_no==xiangqing.file_no){
                  //                         this.$set(item,'zhanshi',ind)
                  //                     }
                  //                 })
                  //                 this.$set(item,'kehu_list',datee)
                  //             }

                  //         }
                  //     }
                  // })
                  if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                    // console.log(xiangqing.cust_name)
                    // this.$set(item,'value',xiangqing.cust_name)
                    // this.$set(item,'value_id',xiangqing.cust_id)
                    const kehu_list = [
                      {
                        cust_id: i.cust_id,
                        cust_name: i.cust_name,
                        file_no: i.file_no,
                        dept_id: i.dept_id,
                        dept_name: i.dept_name
                      }
                    ]
                    this.$set(item, 'kehu_list', kehu_list)
                    this.$set(item, 'zhanshi', '0')
                    this.$set(item, 'value', xiangqing.cust_name)
                    this.$set(item, 'value_id', xiangqing.cust_id)
                  }
                  if (item.componentName == 'department' && item.defaultLable == '部门') {
                    this.$set(item, 'dept_id', xiangqing.dept_id)
                    this.$set(item, 'value', xiangqing.dept_name)
                  }
                  if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                    this.$set(item, 'value', xiangqing.file_no)
                  }
                  if (item.componentName == 'moneyfield' && item.defaultLable == '累计回款金额') {
                    this.$set(item, 'value', xiangqing.amt_rec_sum)
                  }
                  if (item.componentName == 'moneyfield' && item.defaultLable == '累计发票金额') {
                    this.$set(item, 'value', xiangqing.amt_inv_sum)
                  }
                  if (item.componentName == 'textfield' && item.defaultLable == '发票代码') {
                    this.$set(item, 'value', xiangqing.inv_code != undefined ? xiangqing.inv_code : '')
                  }
                  if (item.componentName == 'textfield' && item.defaultLable == '发票号码') {
                    this.$set(item, 'value', xiangqing.inv_no != undefined ? xiangqing.inv_no : '')
                  }
                })
              } else if (flow_id == 'H49d4edc3T') {
                data.forEach(item => {
                  // query_rel_customer_list({data:{
                  //     ent_id:this.$ent_id(),
                  //     staff_id:this.$jichuxinxi().user_id
                  // }}).then(rss=>{
                  //     if(rss.data.code==200){
                  //         console.log(rss)
                  //         let datee=JSON.parse(rss.data.body.data)
                  //         if(datee.length==0){
                  //         }else{
                  //             // i.teshu=true
                  //             // i['zhanshi']='0'
                  //             // i['kehu_list']=datee
                  //             if(item.componentName=='textfield'&&item.defaultLable=='客户'){
                  //                 this.$set(item,'teshu',false)
                  //                 datee.forEach((it,ind)=>{
                  //                     if(it.file_no==xiangqing.file_no){
                  //                         this.$set(item,'zhanshi',ind)
                  //                     }
                  //                 })
                  //                 this.$set(item,'kehu_list',datee)
                  //             }

                  //         }
                  //     }
                  // })
                  if (item.componentName == 'textfield' && item.defaultLable == '客户') {
                    // console.log(xiangqing.cust_name)
                    // this.$set(item,'value',xiangqing.cust_name)
                    // this.$set(item,'value_id',xiangqing.cust_id)
                    const kehu_list = [
                      {
                        cust_id: i.cust_id,
                        cust_name: i.cust_name,
                        file_no: i.file_no,
                        dept_id: i.dept_id,
                        dept_name: i.dept_name
                      }
                    ]
                    this.$set(item, 'kehu_list', kehu_list)
                    this.$set(item, 'zhanshi', '0')
                    this.$set(item, 'value', xiangqing.cust_name)
                    this.$set(item, 'value_id', xiangqing.cust_id)
                  }
                  if (item.componentName == 'department' && item.defaultLable == '部门') {
                    this.$set(item, 'dept_id', xiangqing.dept_id)
                    this.$set(item, 'value', xiangqing.dept_name)
                  }
                  if (item.componentName == 'textfield' && item.defaultLable == '归档号') {
                    this.$set(item, 'value', xiangqing.file_no)
                  }
                })
              }

              console.log(data)

              this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan', obj, '暂时')
              this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_2', data, '暂时')
              this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_3', '看板_编辑', '暂时')
              this.$bendi_cunfang_cunchu('mlbb_shenpi_biaodan_4', xiangqing, '暂时')
              this.$router.push('/usetable')
            } else if (res.data.code == 500) {}
          })
          //     }else if(ras.data.code==10246){
          //         this.shifou=true
          //         this.danzi_name=flow_name
          //     }
          // })
        } else if (rds.data.code == 500) {}
      })
    },
    wuyong () {},
    dianji_xiazai_fujian (i) {
      this.$func.fujian_xiazai(i)
    },
    tongguo_jujue (num) {
      if (num == 4) {
        return '审批通过'
      } else if (num == 2) {
        return '审批拒绝'
      }
    },
    // 点击表单详情
    biaodan_xiangqing (i) {
      this.$refs.biaodan_xiangqing_zhanshi[0].jichu(i)
    },
    tupian_dianji (img) {
      this.$refs.bigphote.jichu(img)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import '../../style/salesman.scss';
</style>
